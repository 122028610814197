import React from 'react';
import { QueryPanel, QueryWidget, Tab } from '../components';
import { QueryVersion } from '../entities';
import { useRunQueries } from '../hooks';

function QueryReviewItem({
   version,
   diffVersion,
   extraActions,
   extraTabs,
   hideTabs,
}: {
   diffVersion?: (queryVersion: QueryVersion) => QueryVersion | undefined;
   extraActions?: (queryVersion: QueryVersion) => React.ReactNode[];
   extraTabs?: (queryVersion?: QueryVersion) => Tab[];
   hideTabs?: {
      comments?: boolean;
      logs?: boolean;
   };
   version: QueryVersion;
}) {
   const { runButton, runStatus } = useRunQueries();

   return (
      <QueryWidget
         action="none"
         diffSide="new"
         diffVersion={diffVersion?.(version)}
         extraActions={[runButton(version), ...(extraActions?.(version) ?? [])]}
         key={version.id}
         noHover
         queryVersion={version}
         showTags
         source="saved"
      >
         {version && (
            <QueryPanel
               extraTabs={extraTabs?.(version)}
               hideTabs={{
                  ...hideTabs,
                  runSource: true,
                  suggestions: true,
                  requests: true,
               }}
               isGeneratingDocumentation={false}
               queryResults={runStatus(version)?.results}
               queryVersion={version}
               readOnly
            />
         )}
      </QueryWidget>
   );
}

export default QueryReviewItem;
