import { Link } from 'react-router-dom';
import { Col, Row, Card, Stack } from 'react-bootstrap';
import _shuffle from 'lodash/shuffle';

import { Workspace } from '../../entities';
import { getShortDateString, notUndefined } from '../../utilities';
import CollaboratorIcon from '../../components/UI/CollaboratorIcon';
import { useQuery } from 'react-query';
import { QueryKey } from '../../enums';
import { QueryLogService } from '../../services';
import { useInjection } from 'inversify-react';
import { TYPES } from '../../types';

const WorkspaceCard = ({
   workspace,
   currentPersonId,
}: {
   currentPersonId: number;
   workspace: Workspace;
}): JSX.Element => {
   const logService = useInjection<QueryLogService>(TYPES.querylogService);
   const userHasRunQueriesHere = useQuery(
      [QueryKey.QueryLog, 'has run', workspace.id],
      async () => {
         return await logService.hasRun(workspace.id ?? -1);
      },
      {
         retry: false,
      }
   );

   const NUM_COLLABORATORS_TO_DISPLAY = 6;
   let numExtraCollaborators = 0;
   let collaboratorsToDisplay = workspace.workspaceCollaborators ?? [];
   if (collaboratorsToDisplay.length > NUM_COLLABORATORS_TO_DISPLAY) {
      numExtraCollaborators = collaboratorsToDisplay.length - NUM_COLLABORATORS_TO_DISPLAY;
      const result = collaboratorsToDisplay.findIndex(
         ({ personId }) => personId === currentPersonId
      );
      if (result < 0) {
         collaboratorsToDisplay = _shuffle(collaboratorsToDisplay).slice(
            0,
            NUM_COLLABORATORS_TO_DISPLAY
         );
      } else {
         collaboratorsToDisplay = [
            collaboratorsToDisplay[result],
            ..._shuffle(
               [
                  ...collaboratorsToDisplay.slice(0, result),
                  ...collaboratorsToDisplay.slice(result + 1),
               ].slice(0, NUM_COLLABORATORS_TO_DISPLAY - 1)
            ),
         ];
      }
   }
   return (
      <Link
         className="text-decoration-none"
         to={`/workspaces/${notUndefined(workspace.id).toString()}`}
      >
         <Card className="other-cards border-0 bg-secondary py-2 px-3">
            <Row className="justify-content-center">
               <Col xs={6}>
                  <span className="fs-14p fw-semibold">
                     {workspace.name}
                     {userHasRunQueriesHere.isSuccess && !userHasRunQueriesHere.data && ' [NEW]'}
                  </span>
               </Col>
               <Col xs={1}>
                  <span className="fs-10p text-body">{workspace.savedQueryCount}</span>
               </Col>
               <Col xs={3}>
                  <Stack direction="horizontal" gap={2}>
                     <Stack className="img-rounded-small flex-wrap" direction="horizontal" gap={1}>
                        {collaboratorsToDisplay.map((collaborator) => (
                           <CollaboratorIcon
                              firstName={
                                 collaborator.person?.firstName ?? collaborator.person?.email ?? ''
                              }
                              key={collaborator.id}
                              lastName={collaborator.person?.lastName ?? ''}
                           />
                        ))}
                     </Stack>
                     {numExtraCollaborators > 0 ? (
                        <span className="fs-10p">{`+ ${numExtraCollaborators} more`}</span>
                     ) : null}
                  </Stack>
               </Col>
               <Col xs={2}>
                  <span className="fs-10p text-body">{getShortDateString(workspace.created)}</span>
               </Col>
            </Row>
            <Row>
               <Col className="workspace-card-description" xs={6}>
                  <span className="fs-12p text-body">{workspace.description}</span>
               </Col>
            </Row>
         </Card>
      </Link>
   );
};

export default WorkspaceCard;
