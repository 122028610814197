import Cookies from 'js-cookie';
import { useState, useEffect } from 'react';

import { LoadingSpinner } from '../components';

export const SnowflakeOAuthCallback = () => {
   const [isLoading, setIsLoading] = useState(true);

   // Send credential data to the parent window for storage. On desktop, this window does not have
   // access to the IPC bridge, so we must delegate credential storage to the parent window.
   useEffect(() => {
      if (!window.opener?.postMessage) {
         throw new Error('No parent window found');
      }

      const cookieName = 'snowflake_oauth_token';
      const credentialDataJson = Cookies.get(cookieName);
      Cookies.remove(cookieName);

      window.opener.postMessage({
         name: 'snowflakeOAuthCallback',
         data: JSON.parse(credentialDataJson ?? '{}'),
      });
   }, []);

   // Handle success/error messages from the parent window.
   useEffect(() => {
      const handleMessage = (event: MessageEvent) => {
         if (
            event.origin !== window.location.origin ||
            event.source === null ||
            window.opener === null ||
            event.source !== window.opener ||
            event.data?.name !== 'snowflakeOAuthCallback'
         ) {
            return;
         }

         if (!event.data.success) {
            throw new Error('Error saving Snowflake OAuth credentials');
         }

         setIsLoading(false);
         window.close();
      };

      window.addEventListener('message', handleMessage);

      return () => {
         window.removeEventListener('message', handleMessage);
      };
   }, []);

   if (isLoading) {
      return (
         <div className="h-100 d-flex justify-content-center align-items-center">
            <LoadingSpinner />
         </div>
      );
   }

   // The user should never see this message since we close the tab programmatically.
   return 'Access granted successfully! You may close this window now.';
};

export default SnowflakeOAuthCallback;
