import { useState, useEffect, useCallback } from 'react';
import { Modal, Spinner, Stack } from 'react-bootstrap';

import { getErrorMessage } from '@runql/util';

import { Button } from '../../../components';
import { DataConnection, DataCredential } from '../../../entities';
import { DBMS } from '../../../enums';
import {
   useNewDataConnectionMutator,
   useTestDataConnectionMutator,
   useReTestDataConnectionMutator,
   useGetDataConnectionQuery,
   useCreateSchemaRoots,
} from '../../../hooks';
import { assertNever, handleError, isSnowflakeOAuthConnection } from '../../../utilities';
import {
   schemaSelectFormId,
   VisibleSchemasTabContent,
} from '../DataConnectionDetailsPage/DataConnectionDetailsVisibleSchemas';
import BaseConnectionForm, {
   BaseConnectionFormData,
   baseConnectionFormId,
} from './BaseConnectionForm';
import ConnectionDetailsForm, {
   connectionDetailsFormId,
} from './ConnectionDetailForm/ConnectionDetailsForm';
import { SnowflakeOAuthCredentials } from './ConnectionDetailForm/SnowflakeDetailForm';

enum ConnWizardPages {
   BASE_CONNECTION,
   CONNECTION_DETAILS,
   SCHEMA_SELECT,
   SNOWFLAKE_OAUTH_AUTHORIZATION,
}

function AddConnectionWizard({
   onClose,
   show,
}: {
   onClose: (options?: { forceRefreshMyConnections?: boolean }) => void;
   show: boolean;
}) {
   // State variables
   const [wizardPage, setWizardPage] = useState(ConnWizardPages.BASE_CONNECTION);
   const [activeFormId, setActiveFormId] = useState(baseConnectionFormId);
   const [selectedConnectionId, setSelectedConnectionId] = useState<number | undefined>();
   const [selectedDBMS, setSelectedDBMS] = useState<DBMS | undefined>();
   const [formMode, setFormMode] = useState<'create' | 'test'>('create');
   const [serverResult, setServerResult] = useState<
      { message?: string; success: Boolean } | undefined
   >(undefined);
   const [isTestConnectionButtonVisible, setIsTestConnectionButtonVisible] = useState(true);
   const [
      isSnowflakeOAuthAuthorizationNextButtonEnabled,
      setIsSnowflakeOAuthAuthorizationNextButtonEnabled,
   ] = useState(false);

   const selectedConnectionResult = useGetDataConnectionQuery({
      id: selectedConnectionId,
      queryOptions: { enabled: selectedConnectionId !== undefined },
   });

   const selectedConnection = selectedConnectionResult.data;

   //Effects
   useEffect(() => {
      if (show === true) {
         // reset wizard
         setPage(ConnWizardPages.BASE_CONNECTION);
         setSelectedDBMS(undefined);
         setSelectedConnectionId(undefined);
         setServerResult(undefined);
      }
   }, [show]);

   //Queries
   const newDataConnectionMutation = useNewDataConnectionMutator({
      onSettled(data, error, variables, context) {
         if (!error && data) {
            setSelectedConnectionId(data.id);

            const isSnowflakeOAuth = isSnowflakeOAuthConnection(data);

            setPage(
               isSnowflakeOAuth
                  ? ConnWizardPages.SNOWFLAKE_OAUTH_AUTHORIZATION
                  : ConnWizardPages.SCHEMA_SELECT
            );
         } else {
            setPage(ConnWizardPages.CONNECTION_DETAILS);
            handleError(error);
         }
      },
   });

   const testConnectionMutation = useTestDataConnectionMutator({
      onSuccessCallback(data, variables, context) {
         setServerResult(data);
      },
   });

   const reTestConnectionMutation = useReTestDataConnectionMutator();

   const createSchemaRootsMutation = useCreateSchemaRoots();

   const setIsTestConnectionButtonVisibleCallback = useCallback(
      (isVisible: boolean) => {
         setIsTestConnectionButtonVisible(isVisible);
      },
      [setIsTestConnectionButtonVisible]
   );

   // Page functions
   const setPage = (page: ConnWizardPages) => {
      setServerResult(undefined);
      setWizardPage(page);
      switch (page) {
         case ConnWizardPages.BASE_CONNECTION:
            setActiveFormId(baseConnectionFormId);
            break;
         case ConnWizardPages.CONNECTION_DETAILS:
            setActiveFormId(connectionDetailsFormId);
            break;
         case ConnWizardPages.SCHEMA_SELECT:
            setActiveFormId(schemaSelectFormId);
            break;
         case ConnWizardPages.SNOWFLAKE_OAUTH_AUTHORIZATION:
            setActiveFormId('thisPageDoesNotHaveAForm');
            break;
         default:
            assertNever(page);
      }
   };

   function handleBaseConnectionSubmit(data: BaseConnectionFormData): void {
      setSelectedConnectionId(data.selectedConnection);
      setSelectedDBMS(data.selectedDBMS);
      setPage(ConnWizardPages.CONNECTION_DETAILS);
   }

   const createConnection = async (newConnection: DataConnection | undefined) => {
      try {
         // save new data connection
         if (!newConnection) {
            handleError('Missing connection');
            return;
         }
         const connection = await newDataConnectionMutation.mutateAsync({ newConnection });
         if (connection?.schemaGenError) {
            setServerResult({
               success: false,
               message: `Connection created. Error fetching schema: ${connection.schemaGenError}`,
            });
         }
      } catch (err) {
         setServerResult({ success: false, message: getErrorMessage(err) });
      }
   };

   const testConnection = async (newConnection: DataConnection) => {
      try {
         await testConnectionMutation.mutateAsync(newConnection);
      } catch (err) {
         setServerResult({ success: false, message: getErrorMessage(err) });
      }
   };

   const testSnowflakeOAuthConnection = async (dataConnection: DataConnection) => {
      try {
         const result = await reTestConnectionMutation.mutateAsync(dataConnection);
         setServerResult(result);
      } catch (err) {
         setServerResult({ success: false, message: getErrorMessage(err) });
      }
   };

   async function handleConnDetailsSubmit(
      newConnection: DataConnection | undefined,
      newCredential: DataCredential | undefined
   ) {
      if (formMode === 'create') {
         await createConnection(newConnection);
      } else if (formMode === 'test' && newConnection) {
         await testConnection(newConnection);
      }
   }

   const handleBackClick = () => {
      switch (wizardPage) {
         case ConnWizardPages.CONNECTION_DETAILS:
            setPage(ConnWizardPages.BASE_CONNECTION);
            break;
      }
   };

   const handleChangeSnowflakeOAuthCredentialsStatus = useCallback(
      (status: 'active' | 'expired' | 'none') => {
         setIsSnowflakeOAuthAuthorizationNextButtonEnabled(status === 'active');
         setServerResult(undefined);
      },
      [setIsSnowflakeOAuthAuthorizationNextButtonEnabled]
   );

   const handleClickSnowflakeOAuthAuthorizationNext = async () => {
      if (!selectedConnection?.id) {
         return;
      }

      await createSchemaRootsMutation.mutateAsync({ dataConnectionId: selectedConnection.id });
      setPage(ConnWizardPages.SCHEMA_SELECT);
   };

   return (
      <Modal
         backdrop="static"
         centered={true}
         onHide={() => {
            onClose();
         }}
         show={show}
         size="lg"
      >
         <Modal.Body as="span" className="fs-s workspaceDescription">
            {wizardPage === ConnWizardPages.BASE_CONNECTION && (
               <BaseConnectionForm
                  defaultValues={{
                     selectedDBMS: selectedDBMS,
                     selectedConnection: selectedConnectionId,
                  }}
                  onSubmit={handleBaseConnectionSubmit}
               />
            )}
            {wizardPage === ConnWizardPages.CONNECTION_DETAILS && (
               <ConnectionDetailsForm
                  isSaving={newDataConnectionMutation.isLoading}
                  onSubmit={handleConnDetailsSubmit}
                  selectedConnectionId={selectedConnectionId}
                  selectedDBMS={selectedDBMS}
                  setIsTestConnectionButtonVisible={setIsTestConnectionButtonVisibleCallback}
               />
            )}
            {wizardPage === ConnWizardPages.SNOWFLAKE_OAUTH_AUTHORIZATION && selectedConnection ? (
               <div>
                  <span className="d-inline-block fs-18p fw-semibold mb-3">
                     Please sign in to Snowflake to authorize runQL
                  </span>
                  <SnowflakeOAuthCredentials
                     dataConnection={selectedConnection}
                     onChangeCredentialsStatus={handleChangeSnowflakeOAuthCredentialsStatus}
                  />
               </div>
            ) : null}
            {wizardPage === ConnWizardPages.SCHEMA_SELECT && selectedConnection && (
               <VisibleSchemasTabContent
                  dataConnection={selectedConnection}
                  hideButtons={true}
                  onSave={(refreshSchemas) => {
                     refreshSchemas();
                     onClose({
                        forceRefreshMyConnections:
                           selectedConnection && isSnowflakeOAuthConnection(selectedConnection),
                     });
                  }}
               />
            )}
         </Modal.Body>
         <Modal.Footer>
            <Stack className="w-100 mx-0 justify-content-between" direction="horizontal" gap={3}>
               <Stack direction="horizontal" gap={3}>
                  {((!newDataConnectionMutation.isLoading &&
                     wizardPage === ConnWizardPages.CONNECTION_DETAILS &&
                     isTestConnectionButtonVisible) ||
                     (wizardPage === ConnWizardPages.SNOWFLAKE_OAUTH_AUTHORIZATION &&
                        selectedConnection)) && (
                     <>
                        {wizardPage === ConnWizardPages.SNOWFLAKE_OAUTH_AUTHORIZATION ? (
                           <Button
                              colorScheme="secondary"
                              isLoading={reTestConnectionMutation.isLoading}
                              onClick={() => {
                                 if (selectedConnection) {
                                    testSnowflakeOAuthConnection(selectedConnection);
                                 }
                              }}
                           >
                              Test Connection
                           </Button>
                        ) : (
                           <Button
                              colorScheme="secondary"
                              form={activeFormId}
                              isLoading={testConnectionMutation.isLoading}
                              onClick={() => setFormMode('test')}
                              type="submit"
                           >
                              {testConnectionMutation.isLoading ? (
                                 <Spinner size="sm" />
                              ) : (
                                 <>Test Connection</>
                              )}
                           </Button>
                        )}
                        {serverResult !== undefined ? (
                           serverResult.success ? (
                              <span className="text-success fs-10p d-flex justify-content-end">
                                 Connection Successful
                              </span>
                           ) : (
                              <span>
                                 {serverResult?.message !== undefined ? (
                                    <div>
                                       <span className="text-danger fs-10p d-flex justify-content-end">
                                          {serverResult?.message}
                                       </span>
                                    </div>
                                 ) : (
                                    'Unknown error during test'
                                 )}
                              </span>
                           )
                        ) : (
                           <></>
                        )}
                     </>
                  )}
               </Stack>

               <Stack direction="horizontal" gap={3}>
                  {wizardPage === ConnWizardPages.BASE_CONNECTION && (
                     <Button
                        colorScheme="secondary"
                        onClick={() => {
                           onClose();
                        }}
                     >
                        Cancel
                     </Button>
                  )}

                  {!newDataConnectionMutation.isLoading &&
                     [ConnWizardPages.CONNECTION_DETAILS].includes(wizardPage) && (
                        <Button colorScheme="secondary" onClick={handleBackClick}>
                           Back
                        </Button>
                     )}
                  {!newDataConnectionMutation.isLoading &&
                     [
                        ConnWizardPages.BASE_CONNECTION,
                        ConnWizardPages.CONNECTION_DETAILS,
                        ConnWizardPages.SCHEMA_SELECT,
                     ].includes(wizardPage) && (
                        <Button
                           form={activeFormId}
                           onClick={() => setFormMode('create')}
                           type="submit"
                        >
                           {wizardPage === ConnWizardPages.CONNECTION_DETAILS
                              ? 'Add'
                              : wizardPage === ConnWizardPages.SCHEMA_SELECT
                              ? 'Finish'
                              : 'Next'}
                        </Button>
                     )}

                  {!newDataConnectionMutation.isLoading &&
                  [ConnWizardPages.SNOWFLAKE_OAUTH_AUTHORIZATION].includes(wizardPage) ? (
                     <Button
                        disabled={!isSnowflakeOAuthAuthorizationNextButtonEnabled}
                        isLoading={createSchemaRootsMutation.isLoading}
                        loadingMessage="Reading schema..."
                        onClick={handleClickSnowflakeOAuthAuthorizationNext}
                     >
                        Next
                     </Button>
                  ) : null}
               </Stack>
            </Stack>
         </Modal.Footer>
      </Modal>
   );
}

export default AddConnectionWizard;
