import { forwardRef, memo, useState, useMemo } from 'react';
import classNames from 'classnames';
import { Badge, Button, OverlayTrigger, Popover, Stack, Tooltip } from 'react-bootstrap';
import { BiCheck, BiCopy, BiExpandVertical, BiCollapseVertical } from 'react-icons/bi';
import { useParams, useSearchParams } from 'react-router-dom';
import { useInjection } from 'inversify-react';

import { extractTablesFromSQL } from '@runql/util';

import {
   PersonUtilities,
   QueryLog,
   QueryState,
   QueryVersion,
   QueryVersionLog,
   createQueryVersionFromLogs,
   useOpenQuery,
} from '../entities';
import { QUERY_SOURCE } from '../enums';
import { useExtension, useGetQueryQuery } from '../hooks';
import { StatsService } from '../services';
import { TYPES } from '../types';
import { copyToClipboard } from '../utilities/clipboard';
import { getShortDateTimeString } from '../utilities/formatters';
import CodeViewer from './UI/CodeViewer';
import DiffViewer from './UI/DiffViewer';
import { IconPrivate, IconShared, IconOpen, IconUse, IconInformation } from '../utilities/icons';
import { QueryVersionBadge } from '.';

export type QueryDisplayOptions = {
   basedOnVersion?: boolean;
   // collapses the code view and also hides the description when in the starting block.
   collapse?: boolean;
   defaultAction?: boolean;
   fullWidth?: boolean;
   showDescription?: 'inCard' | 'asTooltip' | 'none';
   showDetails?: boolean;
   showOriginalCreator?: boolean;
   showQuery?: boolean;
   showTags?: boolean;
   showTitle?: boolean;
};

export const queryTags = (queryVersion: QueryVersion) => {
   return (
      queryVersion.steps?.flatMap((step) =>
         step.queryText ? extractTablesFromSQL(step.queryText, step.schemaName ?? undefined) : []
      ) ?? []
   );
};

export const QueryHeader = ({
   queryVersion,
   diffAction,
   diffVersion,
   basedOnVersion,
   showDescription,
   showDetails,
   showOriginalCreator,
   showTags,
   showTitle,
   collapsed,
   footerExtra,
   onFooterClick,
   includeTooltips = false,
}: QueryDisplayOptions & {
   collapsed?: boolean;
   diffAction?: 'added' | 'removed';
   diffVersion?: QueryVersion;
   footerExtra?: React.ReactNode;
   includeTooltips?: boolean;
   onFooterClick?: () => void;
   queryVersion: QueryVersion;
   showTitle?: boolean;
}): JSX.Element => {
   showTitle ??= !!queryVersion.title;
   showDescription ??= 'none';
   const shared = queryVersion?.query?.state === QueryState.SHARED;
   const runtime = (queryVersion as QueryVersionLog).runtime;
   const tags = useMemo(() => queryTags(queryVersion), [queryVersion]);
   if (tags.length === 0) showTags = false;
   showTags ??= true;
   showOriginalCreator ??= true;

   const approved = queryVersion.query?.approvedVersionId === queryVersion.id;
   if (
      !showTitle &&
      !queryVersion.version &&
      showDescription === 'none' &&
      !showTags &&
      !showDetails
   ) {
      return <></>;
   }

   let queryShareContent = null;
   if (queryVersion.version || queryVersion.parent?.version) {
      const Icon = shared ? IconShared : IconPrivate;
      queryShareContent = includeTooltips ? (
         <OverlayTrigger
            overlay={
               shared ? (
                  <Tooltip>This query is visible to everyone in the workspace</Tooltip>
               ) : (
                  <Tooltip>This query is private to you</Tooltip>
               )
            }
            placement="bottom"
         >
            <span>
               <Icon className="opacity-75" size={14} />
            </span>
         </OverlayTrigger>
      ) : (
         <Icon className="opacity-75" size={14} />
      );
   }

   return (
      <>
         <Stack className="query-card-header p-2 d-flex w-100" gap={1}>
            {(showTitle || queryVersion.version) && (
               <Stack direction="horizontal" gap={2}>
                  <Stack direction="horizontal" gap={1}>
                     {showTitle && queryVersion?.title ? (
                        <span
                           className={classNames('fw-medium query-title truncate-lines', {
                              [`query-diff-${diffAction}`]:
                                 diffVersion && diffVersion.title !== queryVersion.title,
                           })}
                        >
                           {basedOnVersion && <i>Based on&nbsp;</i>}
                           {queryVersion.title}
                        </span>
                     ) : null}
                     <QueryVersionBadge approved={approved} queryVersion={queryVersion} />
                  </Stack>
                  {showDescription === 'asTooltip' && queryVersion.description && (
                     <OverlayTrigger
                        overlay={<Tooltip>{queryVersion.description}</Tooltip>}
                        placement="auto"
                     >
                        <span>
                           <IconInformation size={14} />
                        </span>
                     </OverlayTrigger>
                  )}
                  {queryShareContent ? (
                     <div className="lh-1 flex-shrink-0">{queryShareContent}</div>
                  ) : null}
               </Stack>
            )}
            {showTags && (
               <Stack className="truncate-lines" direction="horizontal" gap={1}>
                  {tags.map((tag, i) => (
                     <Badge bg="tag" key={i} pill>
                        {tag}
                     </Badge>
                  ))}
               </Stack>
            )}
            {showDescription === 'inCard' && queryVersion?.description && (
               <div
                  className={classNames('fs-11p fw-normal', {
                     'truncate-lines truncate-lines-2': collapsed,
                     [`query-diff-${diffAction}`]:
                        diffVersion && diffVersion.description !== queryVersion.description,
                  })}
               >
                  {queryVersion.description}
               </div>
            )}
            {showDescription === 'inCard' && queryVersion?.question && (
               <div
                  className={classNames('fs-11p fw-normal', {
                     'truncate-lines': collapsed,
                     [`query-diff-${diffAction}`]:
                        diffVersion && diffVersion.question !== queryVersion.question,
                  })}
               >
                  <i>Q:</i> {queryVersion.question}
               </div>
            )}
         </Stack>
         {showDetails !== false && (queryVersion?.modified || queryVersion?.createdByPerson) && (
            <Stack
               className="query-card-footer p-2 fs-9p text-muted justify-content-between align-items-center"
               direction="horizontal"
               gap={2}
               onClick={
                  onFooterClick
                     ? (e) => {
                          e.stopPropagation();
                          onFooterClick();
                       }
                     : undefined
               }
            >
               <Stack className="truncate-lines" direction="horizontal" gap={1}>
                  {queryVersion?.modified && (
                     <>
                        {getShortDateTimeString(queryVersion?.modified)}
                        {runtime && runtime > 0 && <>&nbsp;({runtime}ms)</>}
                     </>
                  )}
                  {queryVersion?.createdByPerson && (
                     <>
                        {' – '}
                        {PersonUtilities.getFullName(
                           queryVersion?.aiSuggestion
                              ? {
                                   firstName: 'runQL AI',
                                }
                              : showOriginalCreator
                              ? queryVersion?.query?.createdByPerson ?? queryVersion.createdByPerson
                              : queryVersion.createdByPerson
                        )}
                     </>
                  )}
                  {showOriginalCreator &&
                     queryVersion?.createdByPerson &&
                     queryVersion?.query?.createdByPersonId &&
                     queryVersion.createdByPersonId !== queryVersion.query.createdByPersonId && (
                        <div className="fw-500 potential-badge">
                           (modified by {PersonUtilities.getFullName(queryVersion.createdByPerson)})
                        </div>
                     )}
               </Stack>
               <Stack className="justify-content-end align-items-center" direction="horizontal">
                  {footerExtra}
               </Stack>
            </Stack>
         )}
      </>
   );
};

export const QueryWidget = memo(
   forwardRef(
      (
         {
            children,
            diffSide,
            diffVersion,
            extraActions,
            extraHeaderActions,
            highlight,
            action,
            queryVersion,
            source,
            workspaceId,
            popoverPlacement,
            noHover,
            dataChatThreadId, // open the query and associate the explore tab with this chat
            onClick,
            showDiff,
            ...displayOptions
         }: QueryDisplayOptions & {
            action?: 'open' | 'use' | 'copy' | 'none';
            children?: React.ReactNode;
            dataChatThreadId?: number;
            // Whether queryVersion should be shown as the new or old query.
            // If not provided, it will be determined by the modified date.
            diffSide?: 'new' | 'old' | 'merged';
            diffVersion?: QueryVersion;
            extraActions?: React.ReactNode[];
            extraHeaderActions?: React.ReactNode[];
            highlight?: boolean;
            noHover?: boolean;
            onClick?: () => Promise<boolean>;
            popoverPlacement?: 'right' | 'left' | 'auto-start';
            queryVersion: QueryVersion;
            showDiff?: boolean;
            source: QUERY_SOURCE;
            workspaceId?: number;
         },
         ref: React.ForwardedRef<HTMLDivElement>
      ): JSX.Element => {
         const [searchParams] = useSearchParams();
         const tabId = searchParams.has('t') ? Number(searchParams.get('t')) : undefined;
         const params = useParams();
         const extension = useExtension();
         const statsService = useInjection<StatsService>(TYPES.statsService);
         const maxLines = 3;
         const collapse =
            (displayOptions.collapse ?? false) &&
            queryVersion.steps?.[0].queryText &&
            (queryVersion.steps.length > 1 ||
               queryVersion.steps[0].queryText.trim().split('\n').length > maxLines);
         const [collapsed, setCollapsed] = useState(collapse);
         const defaultAction = displayOptions.defaultAction ?? action !== 'none';

         noHover = displayOptions.showDescription === 'inCard' || noHover;
         action ??= extension ? 'copy' : 'open';
         popoverPlacement ??= extension ? 'left' : 'auto-start';
         if (action === 'use' && !tabId) action = 'open';
         if (diffVersion && !diffSide) {
            diffSide =
               diffVersion.modified &&
               queryVersion.modified &&
               diffVersion.modified < queryVersion.modified
                  ? 'new'
                  : 'old';
         }
         workspaceId ??= params.workspaceId ? Number(params.workspaceId) : undefined;

         const openQuery = useOpenQuery(extension ? { handler: extension.openTab } : {});
         const [showCopySuccess, setShowCopySuccess] = useState(false);
         const onOpen = () => {
            openQuery({
               newTab: true,
               queryVersion,
               source,
               workspaceId,
               dataChatThreadId,
               isLog: source === 'log',
            });
         };

         const onCopy = () => {
            const query = queryVersion?.steps?.flatMap((s) => s.queryText ?? null).join(';\n');
            copyToClipboard(query);
            setShowCopySuccess(true);
            setTimeout(() => {
               setShowCopySuccess(false);
            }, 2000);

            statsService.addUseQueryClick({
               destination: extension ? 'extension' : 'app',
               query: queryVersion.steps?.map((s) => s.queryText)?.join(';\n') ?? '',
               workspaceId,
               queryVersionId: queryVersion.id,
               source,
            });
         };

         const onUse = () => {
            openQuery({
               queryVersion,
               source,
               workspaceId,
            });
         };

         const showCopy = action === 'copy' || !collapse;

         let actionButton: React.ReactNode = null;
         const makeActionButton = ({
            label,
            icon,
            onClick,
         }: {
            icon: React.ReactNode;
            label: string;
            onClick: () => void;
         }) => {
            return (
               <Button
                  className="hover-button"
                  onClick={(e) => {
                     e.preventDefault();
                     e.stopPropagation();
                     onClick();
                  }}
                  size="sm"
                  title={collapsed ? label : undefined}
                  variant="secondary"
               >
                  {collapsed ? '' : <>{label}&nbsp;</>}
                  {icon}
               </Button>
            );
         };
         switch (action) {
            case 'copy':
               break;
            case 'open':
               actionButton = makeActionButton({
                  label: 'Open',
                  icon: <IconOpen size={10} />,
                  onClick: onOpen,
               });
               break;
            case 'use':
               actionButton = makeActionButton({
                  label: 'Use',
                  icon: <IconUse size={10} />,
                  onClick: onUse,
               });
               break;
         }

         // This is the main action (if any) when the whole card is clicked.
         let onAction: () => void = () => {};
         switch (action) {
            case 'copy':
               onAction = onCopy;
               break;
            case 'open':
               onAction = onOpen;
               break;
            case 'use':
               onAction = onUse;
               break;
         }

         return (
            <div
               className={classNames('query-card-container', {
                  'query-card-2col':
                     !displayOptions.fullWidth &&
                     !diffVersion &&
                     displayOptions.showQuery !== false,
               })}
            >
               <OverlayTrigger
                  overlay={
                     queryVersion.description && !noHover ? (
                        <Popover>
                           <div className="fs-11p fw-normal p-2">{queryVersion.description}</div>
                        </Popover>
                     ) : (
                        <></>
                     )
                  }
                  placement={popoverPlacement}
               >
                  <div
                     className={classNames('border-0 query-card card', {
                        'query-card-highlight': highlight,
                        'query-card-hover': defaultAction,
                        'query-diff-card': !!diffVersion,
                     })}
                     onClick={async () => {
                        if ((await onClick?.()) === false) return;
                        if (!defaultAction) return;
                        onAction();
                     }}
                     ref={ref}
                  >
                     <Stack className="query-card-inner">
                        <div className="query-card-content">
                           <QueryHeader
                              collapsed={!!collapsed}
                              diffAction={diffSide === 'old' ? 'removed' : 'added'}
                              diffVersion={
                                 diffVersion && diffSide === 'old' ? diffVersion : queryVersion
                              }
                              footerExtra={
                                 collapse ? (
                                    <Stack
                                       className="align-items-center flex-shrink-0"
                                       direction="horizontal"
                                       gap={1}
                                    >
                                       {collapsed &&
                                          queryVersion.steps &&
                                          queryVersion.steps.length > 1 && (
                                             <Badge bg="tag" className="fs-9p" pill>
                                                +{queryVersion.steps.length - 1} step
                                                {queryVersion.steps.length > 2 ? 's' : ''}
                                             </Badge>
                                          )}
                                       {collapsed ? (
                                          <BiExpandVertical
                                             className="code-view-icon-overlay flex-shrink-0"
                                             size={14}
                                          />
                                       ) : (
                                          <BiCollapseVertical
                                             className="code-view-icon-overlay flex-shrink-0"
                                             size={14}
                                          />
                                       )}
                                    </Stack>
                                 ) : undefined
                              }
                              onFooterClick={() => {
                                 setCollapsed(!collapsed);
                              }}
                              queryVersion={
                                 diffVersion && diffSide === 'new' ? diffVersion : queryVersion
                              }
                              {...displayOptions}
                           />
                           {diffVersion && diffSide !== 'merged' && (
                              <QueryHeader
                                 collapsed={!!collapsed}
                                 diffAction={diffSide === 'new' ? 'removed' : 'added'}
                                 diffVersion={diffSide === 'new' ? diffVersion : queryVersion}
                                 queryVersion={diffSide === 'new' ? queryVersion : diffVersion}
                                 {...displayOptions}
                              />
                           )}
                           {displayOptions.showQuery !== false && (
                              <Stack className="query-card-query p-2">
                                 {queryVersion.steps
                                    ?.slice(0, collapsed ? 1 : undefined)
                                    .map((step, index) => (
                                       <div
                                          className="queryFontSmall cm-editor"
                                          key={step.id ?? index}
                                       >
                                          <div
                                             className="card border-0 queryCardCode"
                                             style={{ position: 'relative' }}
                                          >
                                             {diffVersion &&
                                                showDiff !== false &&
                                                (diffSide === 'new' ? (
                                                   <DiffViewer
                                                      newCode={step.queryText ?? ''}
                                                      oldCode={
                                                         diffVersion.steps[index]?.queryText ?? ''
                                                      }
                                                   />
                                                ) : diffSide === 'old' ? (
                                                   <DiffViewer
                                                      newCode={
                                                         diffVersion.steps[index]?.queryText ?? ''
                                                      }
                                                      oldCode={step.queryText ?? ''}
                                                   />
                                                ) : diffSide === 'merged' ? (
                                                   <DiffViewer
                                                      newCode={step.queryText ?? ''}
                                                      oldCode={
                                                         diffVersion.steps[index]?.queryText ?? ''
                                                      }
                                                      splitView={false}
                                                   />
                                                ) : undefined)}
                                             {(!diffVersion || showDiff === false) && (
                                                <CodeViewer
                                                   comments={step.comments}
                                                   dialect={step.dataConnection?.dbms}
                                                   maxLines={collapsed ? maxLines : undefined}
                                                   query={step.queryText}
                                                />
                                             )}
                                          </div>
                                       </div>
                                    ))}
                              </Stack>
                           )}
                        </div>

                        {extraActions && (
                           <Stack
                              className="justify-content-end pe-2 pb-2"
                              direction="horizontal"
                              gap={2}
                              onClick={(e) => e.stopPropagation()}
                           >
                              {extraActions}
                           </Stack>
                        )}
                     </Stack>

                     {displayOptions.showQuery !== false && (
                        <div className={'query-card-top-right d-flex gap-2 p-2'}>
                           {actionButton}
                           {extraHeaderActions}
                           {showCopy && (
                              <Button
                                 className={`hover-button ${showCopySuccess ? 'btn-success' : ''}`}
                                 onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onCopy();
                                 }}
                                 size="sm"
                                 title={
                                    source === 'askRunaOptimized' ? 'Copy Optimized Query' : 'Copy'
                                 }
                                 variant="secondary"
                              >
                                 {showCopySuccess ? (
                                    <div style={{ display: 'flex', gap: '0.25rem' }}>
                                       <BiCheck />
                                    </div>
                                 ) : (
                                    <div style={{ display: 'flex', gap: '0.25rem' }}>
                                       <BiCopy />
                                    </div>
                                 )}
                              </Button>
                           )}
                        </div>
                     )}

                     {children}
                  </div>
               </OverlayTrigger>
            </div>
         );
      }
   )
);

export const QueryLogWidget = memo(
   ({
      queryLogs,
      extraActions,
      diffLogs,
      ...displayOptions
   }: QueryDisplayOptions & {
      currentVersion?: QueryVersion;
      diffLogs?: QueryLog[];
      extraActions?: React.ReactNode[];
      previousLogs?: QueryLog[];
      queryLogs?: QueryLog[];
   }): JSX.Element => {
      const queryVersion = queryLogs ? createQueryVersionFromLogs(queryLogs) : undefined;
      const diffVersion = diffLogs ? createQueryVersionFromLogs(diffLogs) : undefined;
      if (!queryVersion) return <></>;
      return (
         <QueryWidget
            diffVersion={diffVersion}
            extraActions={extraActions}
            fullWidth
            queryVersion={queryVersion}
            source="log"
            workspaceId={queryLogs?.[0]?.workspaceId}
            {...displayOptions}
         />
      );
   }
);

export const QueryGetter = ({
   queryVersion: partialQueryVersion,
   children,
}: {
   children: (queryVersion: QueryVersion) => React.ReactNode;
   queryVersion: Partial<QueryVersion>;
}) => {
   if ((partialQueryVersion as QueryVersionLog).logId) throw new Error('Cannot get log');
   const queryVersionQuery = useGetQueryQuery({ id: partialQueryVersion.id });
   const queryVersion = queryVersionQuery.data;

   if (!queryVersion) return null;
   return children(queryVersion);
};

export const ChatDeletedQueryWidget = () => {
   return (
      <div className="query-card-container">
         <div className="py-4 px-2 query-card query-card-hover" style={{ cursor: 'auto' }}>
            <Stack direction="horizontal" gap={1}>
               <IconInformation className="flex-shrink-0" size={14} />
               <span className="lh-1 query-title">This query is no longer available</span>
            </Stack>
         </div>
      </div>
   );
};

export default QueryWidget;
