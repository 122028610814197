import { memo, useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { Button, Collapse, Dropdown, Form, Modal, Spinner, Stack } from 'react-bootstrap';
import { BiGitCompare, BiChevronDown } from 'react-icons/bi';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { SERVICE_PLANS } from '@runql/util';

import { useListQueryVersions, useOrg, useQueryApproval } from '../hooks';
import { getErrorMessage } from '../utilities';
import LoadingError from './UI/LoadingError';
import LoadingSpinner from './UI/LoadingSpinner';
import Paginator from './UI/Paginator';
import { QueryWidget, QueryDisplayOptions } from './QueryWidget';
import { QueryVersion, QueryVersionLog } from '../entities';
import { QueryComments } from './QueryComments';
import { QUERY_SOURCE } from '../enums';

const PAGE_SIZE = 10;

const requestSchema = z.object({
   changeDescription: z.string().optional(),
});

export type RequestReviewFormData = z.infer<typeof requestSchema>;

const RequestReviewModal = ({
   defaultValues,
   firstVersion,
   show,
   onHide,
   onSubmit,
   formKey,
}: {
   defaultValues?: RequestReviewFormData;
   firstVersion?: boolean;
   formKey: string;
   onHide?: () => void;
   onSubmit?: (data: RequestReviewFormData) => void;
   show: boolean;
}) => {
   // register form
   const {
      register,
      handleSubmit,
      formState: { errors, touchedFields },
      reset,
   } = useForm<RequestReviewFormData>({
      resolver: zodResolver(requestSchema),
      mode: 'onTouched',
      defaultValues: defaultValues ? defaultValues : { changeDescription: '' },
   });

   // Event handlers
   const onSubmitHandler = (data: RequestReviewFormData) => {
      onSubmit?.(data);
   };

   return (
      <Modal centered={true} onHide={onHide} show={show}>
         <Form id={`requestReviewForm_${formKey}`} onSubmit={handleSubmit(onSubmitHandler)}>
            <Modal.Header className="border-0" closeButton={true}>
               <Modal.Title>Request Review</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <Form.Group>
                  <Form.Label>{firstVersion ? `Review Notes` : `Change Description`}</Form.Label>
                  <Form.Control
                     as="textarea"
                     {...register('changeDescription')}
                     isInvalid={touchedFields.changeDescription && !!errors.changeDescription}
                     isValid={touchedFields.changeDescription && !errors.changeDescription}
                     placeholder={firstVersion ? 'Review Notes' : 'Change Description'}
                  />
                  <Form.Control.Feedback type="invalid">
                     {errors.changeDescription?.message}
                  </Form.Control.Feedback>
               </Form.Group>
            </Modal.Body>
            <Modal.Footer>
               <Button
                  className="btn btn-sm btn-secondary"
                  onClick={() => {
                     onHide?.();
                     reset();
                  }}
                  variant="secondary"
               >
                  Cancel
               </Button>
               <Button
                  className="btn btn-sm btn-primary"
                  form={`requestReviewForm_${formKey}`}
                  type="submit"
                  variant="primary"
               >
                  Request Review
               </Button>
            </Modal.Footer>
         </Form>
      </Modal>
   );
};

const ReviewButton = ({ queryVersion }: { queryVersion: QueryVersion }) => {
   const { reviewRequested, requestReview, saving } = useQueryApproval(queryVersion);
   const [showRequestReviewModal, setShowRequestReviewModal] = useState(false);
   if (reviewRequested)
      return <span className="badge rounded-pill bg-info text-primary">Review Requested</span>;
   return (
      <>
         <RequestReviewModal
            firstVersion={!queryVersion.version}
            formKey={queryVersion.id?.toString() ?? ''}
            onHide={() => setShowRequestReviewModal(false)}
            onSubmit={(data) => {
               requestReview(data.changeDescription);
               setShowRequestReviewModal(false);
            }}
            show={showRequestReviewModal}
         />
         <Button onClick={() => setShowRequestReviewModal(true)} size="sm" variant="link">
            {saving ? (
               <Spinner animation="border" aria-hidden="true" as="span" role="status" size="sm" />
            ) : (
               <>Request Certification</>
            )}
         </Button>
      </>
   );
};

export const QueryList = ({
   currentVersion,
   diffCurrentVersion,
   forkedFromVersion,
   pageSize,
   queries,
   setSkip,
   skip,
   source,
   totalRows,
   noHover,
   ...displayOptions
}: QueryDisplayOptions & {
   currentVersion?: QueryVersion;
   diffCurrentVersion?: boolean;
   forkedFromVersion?: QueryVersion;
   noHover?: boolean;
   pageSize?: number;
   queries: QueryVersion[];
   setSkip: (skip: number) => void;
   skip: number;
   source?: QUERY_SOURCE;
   totalRows: number;
}) => {
   pageSize ??= PAGE_SIZE;
   const defaultDiffVersions = useCallback(
      () =>
         Object.fromEntries(
            queries.filter((v) => v.id !== currentVersion?.id).map((v) => [v.id, currentVersion])
         ),
      [queries, currentVersion]
   );
   const [diffVersions, setDiffVersions] = useState<Record<number, QueryVersion>>(
      diffCurrentVersion ? defaultDiffVersions() : {}
   );
   const [showComments, setShowComments] = useState<Record<number, boolean>>({});
   const lastCurrentVersion = useRef<QueryVersion | undefined>(currentVersion);

   const org = useOrg();
   const reviewAllowed = useMemo(() => {
      return org?.plan !== undefined && SERVICE_PLANS[org.plan].certification;
   }, [org]);

   // Update diffs against the current version when it changes
   useEffect(() => {
      setDiffVersions((v) =>
         Object.fromEntries(
            Object.entries(v).map(([k, v]) =>
               v.id === lastCurrentVersion.current?.id ? [k, currentVersion!] : [k, v]
            )
         )
      );
      lastCurrentVersion.current = currentVersion;
   }, [currentVersion]);

   // If a new query is added (e.g. a new version is saved), add it
   useEffect(() => {
      if (!diffCurrentVersion) return;
      setDiffVersions((v) => ({
         ...defaultDiffVersions(),
         ...v,
      }));
   }, [queries, diffCurrentVersion, currentVersion, defaultDiffVersions]);

   const diffWithVersion = (version: QueryVersion, previous?: QueryVersion) => (
      <Dropdown key="compare">
         <Dropdown.Toggle
            className="btn-sm"
            variant={version.id && diffVersions[version.id] ? 'secondary' : 'secondary'}
         >
            <Stack direction="horizontal" gap={1}>
               <BiGitCompare size={13} />
               <div>Compare</div>
               <BiChevronDown size={16} />
            </Stack>
         </Dropdown.Toggle>
         <Dropdown.Menu>
            <Dropdown.Item
               active={!!version.id && diffVersions[version.id]?.id === currentVersion?.id}
               onClick={() => {
                  setDiffVersions((versions) => {
                     if (!version.id || !currentVersion) return versions;
                     return {
                        ...versions,
                        [version.id]: {
                           ...currentVersion,
                           // If comparing with an unversioned query (e.g. a
                           // log), don't show the version of the current query
                           version: version.version ? currentVersion.version : undefined,
                        },
                     };
                  });
               }}
            >
               Version in Editor
            </Dropdown.Item>
            {previous && (
               <Dropdown.Item
                  active={!!version.id && diffVersions[version.id]?.id === previous?.id}
                  onClick={() => {
                     setDiffVersions((versions) => {
                        if (!version.id || !currentVersion) return versions;
                        return {
                           ...versions,
                           [version.id]: previous,
                        };
                     });
                  }}
               >
                  Previous Version
               </Dropdown.Item>
            )}
            <Dropdown.Item
               active={!version.id || !diffVersions[version.id]}
               onClick={() => {
                  setDiffVersions((versions) => {
                     if (!version.id || !currentVersion) return versions;
                     const { [version.id]: oldValue, ...rest } = versions;
                     return rest;
                  });
               }}
            >
               None
            </Dropdown.Item>
         </Dropdown.Menu>
      </Dropdown>
   );

   return (
      <Stack className="query-metadata-panel dimmed-actions" gap={3}>
         {queries.map((version, index) => (
            <div key={(version as QueryVersionLog).logId ?? version.id}>
               <QueryWidget
                  {...displayOptions}
                  action="open"
                  diffVersion={diffVersions[version.id!]}
                  extraActions={[
                     ...(!displayOptions.collapse &&
                     !!version.version &&
                     reviewAllowed &&
                     version.query?.approvedVersionId !== version.id
                        ? [<ReviewButton key="review" queryVersion={version} />]
                        : []),
                     ...((version.queryCommentCount ?? 0) > 0
                        ? [
                             <Button
                                key="comments"
                                onClick={() =>
                                   setShowComments((comments) => ({
                                      ...comments,
                                      [index]: !comments[index],
                                   }))
                                }
                                size="sm"
                                variant="link"
                             >
                                Comments
                             </Button>,
                          ]
                        : []),
                     ...(currentVersion
                        ? [
                             diffWithVersion(
                                version,
                                index < queries.length - 1 ? queries[index + 1] : undefined
                             ),
                          ]
                        : []),
                  ]}
                  key={version.version}
                  noHover={noHover}
                  queryVersion={version}
                  showOriginalCreator={false}
                  showQuery={
                     version.id !== currentVersion?.id || !!(version as QueryVersionLog).logId
                  }
                  source={source ?? 'versions'}
               >
                  <Collapse in={showComments[index]}>
                     <div>
                        {showComments[index] && (
                           <QueryComments canPost={false} queryVersion={version} />
                        )}
                     </div>
                  </Collapse>
               </QueryWidget>
            </div>
         ))}
         {skip + pageSize >= totalRows && forkedFromVersion && (
            <>
               <hr />
               <QueryWidget
                  {...displayOptions}
                  basedOnVersion
                  diffVersion={
                     forkedFromVersion.id ? diffVersions[forkedFromVersion.id] : undefined
                  }
                  extraActions={[...(currentVersion ? [diffWithVersion(forkedFromVersion)] : [])]}
                  queryVersion={forkedFromVersion}
                  source="versions"
               />
            </>
         )}
         {pageSize < (totalRows ?? 0) && (
            <div className="mt-2 d-flex justify-content-center">
               <Paginator
                  onChange={(page) => setSkip(page * pageSize!)}
                  page={Math.floor(skip / pageSize)}
                  pageSize={pageSize}
                  totalItems={totalRows ?? 0}
               />
            </div>
         )}
      </Stack>
   );
};

export const QueryVersionList = memo(
   ({
      currentVersion,
      forkedFromVersion,
      enabled,
      pageSize,
      queryId,
      source,
      ...displayOptions
   }: QueryDisplayOptions & {
      currentVersion?: QueryVersion;
      enabled?: boolean;
      forkedFromVersion?: QueryVersion;
      pageSize?: number;
      queryId: number;
      source?: QUERY_SOURCE;
   }) => {
      enabled ??= true;
      const [skip, setSkip] = useState(0);

      const stepListQuery = useListQueryVersions({
         options: {
            queryId,
            skip,
            take: pageSize,
            version: 'all',
            expandedPersonData: true,
            includeCommentCount: true,
         },
         queryOptions: { enabled: enabled },
      });
      const versionList = stepListQuery.data?.items;
      let totalRows = stepListQuery.data?.totalItems ?? 0;
      if (totalRows && currentVersion?.version) {
         totalRows--;
      }

      if (!versionList || stepListQuery.isLoading) {
         return <LoadingSpinner />;
      }

      if (stepListQuery.isError) {
         return <LoadingError message={getErrorMessage(stepListQuery.error)} />;
      }

      if (versionList.length === 0 && !forkedFromVersion) {
         return <i>Save this query to add a version.</i>;
      }

      return (
         <QueryList
            currentVersion={currentVersion}
            diffCurrentVersion
            forkedFromVersion={forkedFromVersion}
            noHover
            pageSize={pageSize}
            queries={versionList}
            setSkip={setSkip}
            skip={skip}
            totalRows={totalRows}
            {...displayOptions}
            source={source}
         />
      );
   }
);

export default QueryVersionList;
