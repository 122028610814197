import { useInjection } from 'inversify-react';
import { useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useAddCredentials } from '../../components';
import {
   ConnectionAccessType,
   DataConnection,
   DataConnectionSchema,
   PaginatedResult,
   SchemaCache,
} from '../../entities';
import { QueryKey, QueryKeyType, DBMS } from '../../enums';
import {
   DataConnectionGetOptions,
   DataConnectionListOptions,
   DataConnectionSchemaGetOptions,
   DataConnectionService,
   DesktopQueryService,
   UpdateSchemaVisibilityPayload,
   isDesktop,
} from '../../services';
import { TYPES } from '../../types';
import { handleError, notUndefined, isSnowflakeOAuthConnection } from '../../utilities';
import { checkSnowflakeOAuthTokenFreshnessFactory } from '../run';

import type { LocalCredentialService } from '../../services/LocalCredentialService';

const useDataConnectionService = () => {
   return useInjection<DataConnectionService>(TYPES.dataConnectionService);
};

const useDesktopQueryService = () => {
   return useInjection<DesktopQueryService>(TYPES.desktopQueryService);
};

export const useListDataConnectionsQuery = (
   filters?: DataConnectionListOptions,
   callbacks?: {
      errorCallback?: (error: unknown) => void;
      successCallback?: (data: PaginatedResult<DataConnection>) => void;
   },
   enabled?: boolean
) => {
   const dataConnectionService = useDataConnectionService();
   return useQuery<PaginatedResult<DataConnection>>(
      getDataConnectionQueryKey({ type: QueryKeyType.LIST, filters: filters }),
      () => dataConnectionService.listOptions(filters),
      {
         enabled: enabled,
         keepPreviousData: true,
         refetchOnWindowFocus: false,
         refetchOnMount: true,
         retry: false,
         onSuccess: callbacks?.successCallback,
         onError: callbacks?.errorCallback,
      }
   );
};

export const useListDataConnectionSchemasQuery = () => {
   const dataConnectionService = useDataConnectionService();
   return useQuery<DataConnectionSchema[]>(
      QueryKey.DataConnectionSchemas,
      () => dataConnectionService.listSchemas(),
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false,
         refetchOnMount: true,
         retry: false,
      }
   );
};

export const useGetDataConnectionQuery = ({
   getOptions,
   id,
   queryOptions = {},
   callbacks,
}: {
   callbacks?: { onSuccess?: (data: DataConnection | undefined) => void };
   getOptions?: DataConnectionGetOptions;
   id: number | undefined;
   queryOptions?: {
      enabled?: boolean;
      initialData?: DataConnection;
   };
}) => {
   const dataConnectionService = useInjection<DataConnectionService>(TYPES.dataConnectionService);
   const localCredentialService = useInjection<LocalCredentialService>(
      TYPES.localCredentialService
   );
   return useQuery<DataConnection | undefined>(
      getDataConnectionQueryKey({ type: QueryKeyType.GET, id: id, filters: getOptions }),
      async () => {
         const [response, localCredentials] = await Promise.all([
            dataConnectionService.getOptions(id, getOptions),
            localCredentialService.get(id!),
         ]);
         return {
            ...response,
            dataCredentials: [
               {
                  ...response?.dataCredentials?.[0],
                  ...localCredentials,
               },
            ],
         } as DataConnection;
      },
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false,
         refetchOnMount: true,
         retry: false,
         staleTime: 10,
         enabled: !!id && (queryOptions.enabled ?? true),
         initialData: queryOptions.initialData,
         onError(err) {
            handleError(err);
         },
         onSuccess: callbacks?.onSuccess,
      }
   );
};

export const getDataConnectionSchemaQueryKey = ({
   dataConnectionId,
}: {
   dataConnectionId: number;
}) => [QueryKey.SchemaForDataSource, dataConnectionId];
// TODO: Do not allow undefined dataConnectionId
export const useGetDataConnectionSchema = (
   dataConnectionId: number | undefined,
   getOptions?: DataConnectionSchemaGetOptions
) => {
   const service = useDataConnectionService();
   return useQuery<SchemaCache[]>(
      dataConnectionId !== undefined
         ? getDataConnectionSchemaQueryKey({ dataConnectionId })
         : '__INVALID__',
      () => service.getSchemaCache(dataConnectionId, getOptions),
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false,
         refetchOnMount: true,
         retry: false,
      }
   );
};

export const useDataConnection = (id: number | undefined) => {
   const query = useGetDataConnectionQuery({ id: id });
   return query.data;
};

export const useDbms = (dataConnectionId: number | undefined) => {
   const dataConnection = useDataConnection(dataConnectionId);
   return dataConnection?.dbms;
};

interface NewDataConnectionParameters {
   newConnection: DataConnection;
}

export const useNewDataConnectionMutator = (callbacks?: {
   onSettled?: (
      data: DataConnection | undefined,
      error: unknown,
      variables: NewDataConnectionParameters,
      context: unknown
   ) => void;
}) => {
   const queryClient = useQueryClient();
   const dataConnectionService = useDataConnectionService();
   return useMutation({
      mutationFn: ({ newConnection }: NewDataConnectionParameters) => {
         return dataConnectionService.post(newConnection);
      },
      onSuccess: async (newConnection) => {
         await queryClient.invalidateQueries(
            getDataConnectionQueryKey({ type: QueryKeyType.LIST })
         );
      },
      onSettled(data, error, variables, context) {
         if (callbacks?.onSettled) {
            callbacks.onSettled(data, error, variables, context);
         }
      },
   });
};

interface UpdateDataConnectionMutationVariables {
   dataConnection: DataConnection;
}

export const useUpdateDataConnectionMutator = (callbacks?: {
   onSuccessCallback?: (
      data: DataConnection | undefined,
      variables: UpdateDataConnectionMutationVariables,
      context: unknown
   ) => void;
}) => {
   const queryClient = useQueryClient();
   const dataConnectionService = useDataConnectionService();
   return useMutation({
      mutationFn: async ({ dataConnection }: UpdateDataConnectionMutationVariables) => {
         return dataConnectionService.patch(notUndefined(dataConnection.id), dataConnection);
      },
      async onSuccess(data, variables, context) {
         if (callbacks?.onSuccessCallback) callbacks.onSuccessCallback(data, variables, context);
         if (data) {
            const promises = [
               queryClient.invalidateQueries(
                  getDataConnectionQueryKey({ type: QueryKeyType.GET, id: notUndefined(data.id) })
               ),
               queryClient.invalidateQueries(
                  getDataConnectionQueryKey({ type: QueryKeyType.LIST })
               ),
            ];

            await Promise.all(promises);
         }
      },
      onError: handleError,
   });
};

type UpdateSchemaVisibilityMutationVariables = {
   body: UpdateSchemaVisibilityPayload;
   dataConnectionId: number;
};
export const useUpdateSchemaVisibilityMutator = ({
   onSuccess,
}: {
   onSuccess?: (
      data: void,
      variables: UpdateSchemaVisibilityMutationVariables,
      context: unknown
   ) => Promise<void> | void;
} = {}) => {
   const dataConnectionService = useDataConnectionService();
   return useMutation({
      mutationFn: async (vars: UpdateSchemaVisibilityMutationVariables) => {
         return dataConnectionService.updateSchemaVisibility(vars.dataConnectionId, vars.body);
      },
      async onSuccess(data, variables, context) {
         if (onSuccess) {
            await onSuccess(data, variables, context);
         }
      },
      onError: handleError,
   });
};

export const useDeleteDataConnectionMutator = () => {
   const queryClient = useQueryClient();
   const dataConnectionService = useDataConnectionService();
   return useMutation({
      mutationFn: async (id: number) => {
         return dataConnectionService.delete(id);
      },
      async onSuccess(data, id, context) {
         const promises = [
            queryClient.invalidateQueries(
               getDataConnectionQueryKey({ type: QueryKeyType.GET, id: id })
            ),
            queryClient.invalidateQueries(getDataConnectionQueryKey({ type: QueryKeyType.LIST })),
         ];
         await Promise.all(promises);
      },
      onError: handleError,
   });
};

// Note - this method does not work with Snowflake OAuth connections. Use
// useReTestDataConnectionMutator instead.
export const useTestDataConnectionMutator = (callbacks?: {
   onErrorCallback?: (error: unknown, variables: DataConnection, context: unknown) => void;
   onSuccessCallback?: (
      data: { message?: string; success: Boolean },
      variables: DataConnection,
      context: unknown
   ) => void;
}) => {
   const dataConnectionService = useDataConnectionService();
   const desktopQueryService = useDesktopQueryService();
   return useMutation({
      mutationFn: async (connection: DataConnection) => {
         if (!isDesktop() || connection.connectionAccessType !== ConnectionAccessType.INDIVIDUAL) {
            return dataConnectionService.testConnection(connection);
         }

         return desktopQueryService.testConnection({
            dataConnection: connection,
            credentialData: connection.dataCredentials?.[0] ?? {},
         });
      },
      onSuccess(data, variables, context) {
         if (callbacks?.onSuccessCallback) callbacks.onSuccessCallback(data, variables, context);
      },
      onError(error, variables, context) {
         if (callbacks?.onErrorCallback) callbacks.onErrorCallback(error, variables, context);
      },
   });
};

export const useReTestDataConnectionMutator = ({
   callbacks = {},
}: {
   callbacks?: {
      onSuccess?: (
         data: {
            message?: string | undefined;
            success: Boolean;
         },
         variables: number | DataConnection,
         context: unknown
      ) => void;
   };
} = {}) => {
   const dataConnectionService = useDataConnectionService();
   const desktopQueryService = useDesktopQueryService();
   return useMutation({
      mutationFn: async (connection: DataConnection) => {
         const isSnowflakeOAuth = isSnowflakeOAuthConnection(connection);

         if (
            isSnowflakeOAuth ||
            !isDesktop() ||
            connection.connectionAccessType !== ConnectionAccessType.INDIVIDUAL
         ) {
            // If it is a Snowflake OAuth connection, then don't include any credential overrides.
            // The test will use the credentials included in the request headers.
            return dataConnectionService.retestConnection({
               ...connection,
               dataCredentials: isSnowflakeOAuth ? undefined : connection.dataCredentials,
            });
         }

         return desktopQueryService.testConnection({
            dataConnection: connection,
            credentialData: connection.dataCredentials?.[0] ?? {},
         });
      },
      onSuccess: callbacks?.onSuccess,
   });
};

type Target = { schemaName: string; tableName: string; type: 'table' };
type UpdateSchemaMutationVariables = {
   dataConnection: Omit<DataConnection, 'id'> & { id: number };
   target?: Target;
};
type UseUpdateSchemaMutationCallbacks = {
   onErrorCallback?: (
      error: unknown,
      variables: UpdateSchemaMutationVariables,
      context: unknown
   ) => void;
   onSuccessCallback?: (
      data: void,
      variables: UpdateSchemaMutationVariables,
      context: unknown
   ) => void;
};
const useUpdateSchemaMutation = (callbacks?: UseUpdateSchemaMutationCallbacks) => {
   const dataConnectionService = useInjection<DataConnectionService>(TYPES.dataConnectionService);

   return useMutation<
      void, // Return type of mutation function
      unknown, // Error type
      UpdateSchemaMutationVariables, // Variables type
      unknown
   >({
      mutationFn: async ({ dataConnection, target }) => {
         const result = await dataConnectionService.updateSchema(dataConnection, target);
         if (!result) {
            throw new Error('Failed to update schema');
         }
         return;
      },
      onSuccess: async (data, variables, context) => {
         if (callbacks?.onSuccessCallback) {
            await callbacks.onSuccessCallback(data, variables, context);
         }
      },
      onError: (error, variables, context) => {
         if (callbacks?.onErrorCallback) {
            callbacks.onErrorCallback(error, variables, context);
         }
      },
   });
};

export const useUpdateSchema = (callbacks?: UseUpdateSchemaMutationCallbacks) => {
   const dataConnectionService = useInjection<DataConnectionService>(TYPES.dataConnectionService);
   const localCredentialService = useInjection<LocalCredentialService>(
      TYPES.localCredentialService
   );
   const addCredentials = useAddCredentials();
   const mutator = useUpdateSchemaMutation(callbacks);

   const mutateAsync = mutator.mutateAsync;

   const checkSnowflakeOAuthTokenFreshness = checkSnowflakeOAuthTokenFreshnessFactory({
      localCredentialService,
   });

   const updateSchema = useCallback(
      async (dataConnection: DataConnection | number, target?: Target) => {
         if (typeof dataConnection === 'number') {
            const result = await dataConnectionService.get(dataConnection);

            if (!result || !result.id) {
               throw new Error('Data connection not found');
            }

            dataConnection = result;
         }

         const dataConnectionId = dataConnection.id;

         if (!dataConnectionId) {
            throw new Error('Invalid Data connection');
         }

         if (dataConnection.connectionAccessType === ConnectionAccessType.INDIVIDUAL) {
            let hasCredentials = false;
            if (
               dataConnection.dbms !== DBMS.Snowflake ||
               dataConnection.metadataPublic?.oauth === undefined
            ) {
               hasCredentials = await localCredentialService.has(dataConnectionId);
            } else {
               // For Snowflake connections that are using OAuth, treat expired refresh tokens
               // as missing credentials
               hasCredentials = await checkSnowflakeOAuthTokenFreshness(dataConnectionId);
            }

            if (!hasCredentials) {
               return new Promise<void>((resolve, reject) => {
                  addCredentials({
                     connectionId: dataConnectionId,
                     handleClose: async (success: boolean) => {
                        if (!success) {
                           return reject(new Error('Please add credentials to update the schema'));
                        }

                        try {
                           await updateSchema(dataConnection, target);
                           resolve();
                        } catch (err) {
                           reject(err);
                        }
                     },
                  });
               });
            }
         }

         // Shared connection or has credentials

         return mutateAsync({
            dataConnection: { ...dataConnection, id: dataConnectionId },
            target,
         });
      },
      [
         addCredentials,
         dataConnectionService,
         localCredentialService,
         mutateAsync,
         checkSnowflakeOAuthTokenFreshness,
      ]
   );

   const { isLoading, isError, error } = mutator;

   return { updateSchema, state: { isLoading, isError, error } };
};

export const useCreateSchemaRoots = () => {
   const dataConnectionService = useInjection<DataConnectionService>(TYPES.dataConnectionService);

   return useMutation<void, unknown, { dataConnectionId: number }, unknown>({
      mutationFn: async ({ dataConnectionId }) => {
         const result = await dataConnectionService.createSchemaRootsForDataConnection(
            dataConnectionId
         );
         if (!result) {
            throw new Error('Failed to read schema');
         }
         return;
      },
   });
};

export const useSetMetaUpdateViewedMutator = () => {
   const dataConnectionService = useDataConnectionService();
   return useMutation<void, unknown, { dataConnectionId: number }, unknown>({
      mutationFn: async ({ dataConnectionId }) => {
         const result = await dataConnectionService.setMetaUpdateViewed(dataConnectionId);
         if (!result) {
            throw new Error('Failed to set metadata as viewed');
         }
         return;
      },
   });
};

export interface RunDataConnectionQueryBody {
   exploreTabId?: number | undefined;
   queries: {
      query: string;
      querySavedId?: number | undefined;
   }[];
   workspaceId?: number | undefined;
}

export function getDataConnectionQueryKey(keyParams: {
   filters?: DataConnectionListOptions | DataConnectionGetOptions;
   id?: number;
   type: QueryKeyType;
}): any[] {
   const queryKey: any[] = [QueryKey.DataConnection, keyParams.type];
   if (keyParams.id !== undefined) {
      queryKey.push(keyParams.id);
   }

   if (keyParams.filters !== undefined) {
      queryKey.push(keyParams.filters);
   }

   return queryKey;
}
