import { useHotkeys } from 'react-hotkeys-hook';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { copyToClipboard } from '.././utilities/clipboard';
import { IconLink } from '.././utilities';
import { Button } from './Button';

export const copyCurrentUrl = () => {
   copyToClipboard(window.location.href);
   toast('Link copied to the clipboard', {
      type: 'success',
      autoClose: 2000,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: false,
   });
};

export const LinkCopierHotkey = () => {
   const navigate = useNavigate();
   useHotkeys(['mod+shift+c'], copyCurrentUrl, []);
   useHotkeys(
      ['alt+l'],
      async () => {
         if (!navigator.clipboard) return;
         try {
            const url = new URL(await navigator.clipboard.readText());
            if (url.host !== window.location.host) return;
            navigate(url.pathname + url.search);
         } catch {
            // Ignore invalid URLs
         }
      },
      { enabled: !!navigator.clipboard },
      []
   );
   return null;
};

export const LinkCopierButton = () => {
   return (
      <Button
         className="text-reset"
         colorScheme="secondary"
         onClick={copyCurrentUrl}
         size="sm"
         variant="ghost"
      >
         <span className="visually-hidden">Copy Link</span>
         <IconLink />
      </Button>
   );
};
