import { BetterBase, Query, DataChatMessageQuery } from './';

export enum QueryFeedbackType {
   CLOSE_MATCH = 'close-match',
   EXACT_MATCH = 'exact-match',
   NON_MATCH = 'non-match',
}

export interface QueryFeedback extends BetterBase {
   dataChatMessageQuery: DataChatMessageQuery;
   dataChatMessageQueryId: number;

   query: Query;
   queryId: number;

   question: string;

   type: QueryFeedbackType;
}

export interface QueryFeedbackUpdate {
   dataChatMessageQueryId: number;
   type: QueryFeedbackType | null;
}
