import { Col, Form } from 'react-bootstrap';
import LoadingError from '../../../components/UI/LoadingError';
import LoadingSpinner from '../../../components/UI/LoadingSpinner';
import { DBMS, PersonRole } from '../../../enums';
import { useGetAuthorizedExplorerQuery, useGetDataConnectionQuery } from '../../../hooks';
import { getErrorMessage } from '../../../utilities';
import BigQueryCard from './BigQueryCard';
import DatabricksCard from './DatabricksCard';
import MongoDBCard from './MongoDBCard';
import MsSQLCard from './MsSQLCard';
import MySqlCard from './MySqlCard';
import Neo4jCard from './Neo4jCard';
import OracleCard from './OracleCard';
import PostgreCard from './PostgreCard';
import RedshiftCard from './RedshiftCard';
import SnowflakeCard from './SnowflakeCard';
import TrinoCard from './TrinoCard';
import { useMemo } from 'react';
import { ConnectionAccessType, DataConnection } from '../../../entities';

export function ConnectionCardField({
   label,
   value,
   xs,
   bold,
}: {
   bold?: boolean;
   label: string;
   value?: string | boolean;
   xs: number;
}) {
   return (
      <Col xs={xs}>
         <div className="fs-12p">
            <div className="fs-10p fw-normal text-muted div-form-label">{label}</div>
            {value === undefined || typeof value === 'string' ? (
               <div className={`fs-10p ${bold ? 'fw-600' : ''} div-form-control`}>
                  {value ?? ''}
               </div>
            ) : (
               <Form.Check
                  checked={value}
                  className="div-form-control-switch"
                  disabled
                  type="switch"
               />
            )}
         </div>
      </Col>
   );
}

export function DataConnectionCard({
   dataConnectionId,
   initialData,
}: {
   dataConnectionId: number;
   initialData?: DataConnection;
}) {
   // Queries
   const dataConnectionQuery = useGetDataConnectionQuery({
      id: dataConnectionId,
      getOptions: { flagNewMetadata: true },
      queryOptions: { initialData },
   });
   const explorerQuery = useGetAuthorizedExplorerQuery();
   const disabled = useMemo(() => {
      if (explorerQuery.data === undefined) return true;
      if (dataConnectionQuery.data === undefined) return true;
      // demos are always disabled
      if (dataConnectionQuery.data.connectionAccessType === ConnectionAccessType.DEMO) return true;
      // connections with hidden details are disabled for everyone except org admins and the creator
      if (
         dataConnectionQuery.data.hideDetails &&
         explorerQuery.data.person.role !== PersonRole.ORG_ADMIN &&
         dataConnectionQuery.data.createdByPersonId !== explorerQuery.data.person.id
      )
         return true;
      return false;
   }, [dataConnectionQuery.data, explorerQuery.data]);

   // Render
   if (dataConnectionQuery.isLoading || explorerQuery.isLoading) return <LoadingSpinner />;

   if (dataConnectionQuery.isError)
      return <LoadingError message={getErrorMessage(dataConnectionQuery.error)} />;

   if (explorerQuery.isError)
      return <LoadingError message={getErrorMessage(explorerQuery.error)} />;

   if (dataConnectionQuery.data === undefined || explorerQuery.data === undefined) return <></>;

   let content: React.ReactNode = null;
   switch (dataConnectionQuery.data?.dbms) {
      case DBMS.MySQL:
         content = <MySqlCard dataConnection={dataConnectionQuery.data} disabled={disabled} />;
         break;
      case DBMS.Big_Query:
         content = <BigQueryCard dataConnection={dataConnectionQuery.data} disabled={disabled} />;
         break;
      case DBMS.Databricks:
         content = <DatabricksCard dataConnection={dataConnectionQuery.data} disabled={disabled} />;
         break;
      case DBMS.MSSQL:
         content = <MsSQLCard dataConnection={dataConnectionQuery.data} disabled={disabled} />;
         break;
      case DBMS.Neo4j:
         content = <Neo4jCard dataConnection={dataConnectionQuery.data} disabled={disabled} />;
         break;
      case DBMS.Postgres:
         content = <PostgreCard dataConnection={dataConnectionQuery.data} disabled={disabled} />;
         break;
      case DBMS.Redshift:
         content = <RedshiftCard dataConnection={dataConnectionQuery.data} disabled={disabled} />;
         break;
      case DBMS.Snowflake:
         content = <SnowflakeCard dataConnection={dataConnectionQuery.data} disabled={disabled} />;
         break;
      case DBMS.Oracle:
         content = <OracleCard dataConnection={dataConnectionQuery.data} disabled={disabled} />;
         break;
      case DBMS.Trino:
         content = <TrinoCard dataConnection={dataConnectionQuery.data} disabled={disabled} />;
         break;
      case DBMS.MongoDB:
         content = <MongoDBCard dataConnection={dataConnectionQuery.data} disabled={disabled} />;
         break;
   }

   return (
      <div className="position-relative">
         {dataConnectionQuery.data.hasNewMetadata ? (
            <span
               className="position-absolute top-50 translate-middle-y badge bg-primary"
               style={{ right: '16px' }}
            >
               New Metadata
            </span>
         ) : null}
         {content}
      </div>
   );
}
