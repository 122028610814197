import { useState } from 'react';
import classNames from 'classnames';
import { Nav, NavItem, NavLink, Stack, TabContainer, TabContent, TabPane } from 'react-bootstrap';

export type PageTab = {
   badge?: string;
   content: React.ReactNode;
   title: string;
};

export const PageTabs = ({
   action,
   className,
   noScroll,
   onTabChange,
   tabs,
   toolbar,
}: {
   action?: React.ReactNode;
   className?: string;
   noScroll?: boolean;
   onTabChange?: (tabKey: string | null) => void;
   tabs: PageTab[];
   toolbar?: React.ReactNode;
}): JSX.Element => {
   const [tab, setTab] = useState('0');
   return (
      <TabContainer activeKey={tab} onSelect={(t) => t && setTab(t)} transition={false}>
         <div className="h-100 d-flex flex-column overflow-hidden">
            <Stack
               className={classNames('align-items-end w-100', className)}
               direction="horizontal"
               gap={2}
            >
               <Stack className="flex-nowrap align-items-center" gap={2}>
                  {toolbar && <div>{toolbar}</div>}

                  <Nav
                     className="d-flex flex-row flex-nowrap align-items-center"
                     onSelect={onTabChange}
                     role="tablist"
                  >
                     <Stack className="nav-tabs-alt px-2 py-3" direction="horizontal" gap={3}>
                        {tabs.map((t, i) => (
                           <NavItem className={tab === i.toString() ? 'active' : ''} key={i}>
                              <NavLink
                                 className="position-relative override-active-pointer m-0 p-1"
                                 eventKey={i.toString()}
                                 role={'tab'}
                              >
                                 <Stack direction="horizontal" gap={2}>
                                    <span className="lh-1">{t.title}</span>
                                    {t.badge ? (
                                       <span className="badge bg-primary">{t.badge}</span>
                                    ) : null}
                                 </Stack>
                              </NavLink>
                           </NavItem>
                        ))}
                     </Stack>
                  </Nav>
               </Stack>
               {action}
            </Stack>

            <TabContent className="flex-grow-1 overflow-hidden">
               {tabs.map((t, i) => (
                  <TabPane
                     className={classNames('h-100 p-3 pb-0', { 'overflow-auto': !noScroll })}
                     eventKey={i}
                     key={i}
                  >
                     {t.content}
                  </TabPane>
               ))}
            </TabContent>
         </div>
      </TabContainer>
   );
};

export default PageTabs;
