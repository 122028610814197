import { Outlet } from 'react-router-dom';

import { LinkCopierHotkey } from './components';

const Layout = (): JSX.Element => {
   return (
      <main className="page-wrapper">
         <LinkCopierHotkey />
         <Outlet />
      </main>
   );
};

export default Layout;
