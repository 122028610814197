import { useInjection } from 'inversify-react';
import { useEffect, useState } from 'react';
import { Stack } from 'react-bootstrap';

import { Button, Page } from '../../components';
import LoadingError from '../../components/UI/LoadingError';
import LoadingSpinner from '../../components/UI/LoadingSpinner';
import SearchInput from '../../components/UI/SearchInput';
import { ConnectionAccessType, DataConnection } from '../../entities';
import { LocalCredentialService } from '../../services';
import { TYPES } from '../../types';
import { useTitle } from '../../utilities';
import AddConnectionWizard from './AddConnectionWizard/AddConnectionWizard';
import { DataConnectionCard } from './DataConnectionCard/DataConnectionCard';
import { usePerson, useListDataConnectionsQuery } from '../../hooks';
import { PersonRole } from '../../enums';

export const DataConnectionsPage = (): JSX.Element => {
   const person = usePerson();
   useTitle('Data Sources');

   //State variables
   const [searchText, setSearchText] = useState('');
   const [showAddModal, setShowAddModal] = useState(false);
   const [myConnections, setMyConnections] = useState<DataConnection[]>([]);

   // In some cases, we need to force a refresh of My Connections e.g., In Snowflake + OAuth, we add
   // the credentials after fetching the list with the new connection in it.
   const [forceRefreshMyConnections, setForceRefreshMyConnections] = useState(0);

   const localCredentialService = useInjection<LocalCredentialService>(
      TYPES.localCredentialService
   );

   //Queries
   const dataConnectionsResult = useListDataConnectionsQuery();

   useEffect(() => {
      (async () => {
         const filteredConnections = (
            await Promise.all(
               (dataConnectionsResult.data?.items ?? [])
                  .filter(
                     (connection) =>
                        connection.connectionAccessType === ConnectionAccessType.INDIVIDUAL
                  )
                  .map(async (connection) => {
                     const hasCredential = await localCredentialService.has(connection.id!);
                     return hasCredential ? [connection] : [];
                  })
            )
         ).flat();
         setMyConnections(filteredConnections);
      })();
   }, [dataConnectionsResult.data, localCredentialService, forceRefreshMyConnections]);

   //Page Functions
   const filterConnectionsByText = (
      value: DataConnection,
      index: number,
      array: DataConnection[]
   ) => {
      //check the name
      if (value.name && value.name.toLowerCase().includes(searchText)) {
         return true;
      }

      //Check the description
      if (value.description?.toLowerCase().includes(searchText)) {
         return true;
      }
   };

   if (dataConnectionsResult.isLoading) {
      return <LoadingSpinner />;
   } else if (dataConnectionsResult.isError) {
      return LoadingError({});
   }

   return (
      <Page
         action={
            person.role !== PersonRole.ORG_BUSINESS_USER ? (
               <Button onClick={() => setShowAddModal(true)} size="sm">
                  Add Data Source
               </Button>
            ) : undefined
         }
         tabs={[
            {
               title: 'My Sources',
               content: (
                  <Stack gap={2}>
                     <SearchInput
                        entityName="data sources"
                        onTextChanged={(newText) => setSearchText(newText.toLowerCase())}
                        value={searchText}
                     />
                     {myConnections.filter(filterConnectionsByText).map((connection) => (
                        <DataConnectionCard
                           dataConnectionId={connection.id!}
                           initialData={connection}
                        />
                     ))}
                  </Stack>
               ),
            },
            {
               title: 'Shared Sources',
               content: (
                  <Stack gap={2}>
                     <SearchInput
                        entityName="data sources"
                        onTextChanged={(newText) => setSearchText(newText.toLowerCase())}
                        value={searchText}
                     />
                     {dataConnectionsResult.data?.items
                        ?.filter(
                           (connection) =>
                              connection.connectionAccessType === ConnectionAccessType.SHARED ||
                              connection.connectionAccessType === ConnectionAccessType.DEMO
                        )
                        .filter(filterConnectionsByText)
                        .map((connection) =>
                           connection.id ? (
                              <DataConnectionCard
                                 dataConnectionId={connection.id}
                                 initialData={connection}
                              />
                           ) : null
                        )}
                  </Stack>
               ),
            },
            {
               title: 'Available Sources',
               content: (
                  <Stack gap={2}>
                     <SearchInput
                        entityName="data sources"
                        onTextChanged={(newText) => setSearchText(newText.toLowerCase())}
                        value={searchText}
                     />
                     {dataConnectionsResult.data?.items
                        ?.filter(
                           (connection) =>
                              connection.connectionAccessType === ConnectionAccessType.INDIVIDUAL &&
                              !myConnections.some(
                                 (myConnection) => myConnection.id === connection.id
                              )
                        )
                        .filter(filterConnectionsByText)
                        .map((connection) => (
                           <DataConnectionCard
                              dataConnectionId={connection.id!}
                              initialData={connection}
                           />
                        ))}
                  </Stack>
               ),
            },
         ]}
      >
         <AddConnectionWizard
            onClose={({ forceRefreshMyConnections = false } = {}) => {
               if (forceRefreshMyConnections) {
                  setForceRefreshMyConnections((prev) => prev + 1);
               }
               setShowAddModal(false);
            }}
            show={showAddModal}
         />
      </Page>
   );
};

export default DataConnectionsPage;
