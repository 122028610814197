import { Badge, Stack } from 'react-bootstrap';
import classNames from 'classnames';

import { Person, PersonUtilities, QueryVersion } from '../entities';
import { getShortDateTimeString } from '../utilities/formatters';
import { IconThumbsUp, IconThumbsDown } from '../utilities';
import { QueryFeedbackType, DataChatMessageQuery } from '../entities';
import { useUpdateQueryFeedback } from '../hooks/entities';
import { Button, LoadingSpinner } from './';

export const AnswerCard = ({
   dataChatMessageQuery,
   dataChatThreadId,
   onClick,
   personId,
   queryVersion,
   selected,
}: {
   dataChatMessageQuery?: DataChatMessageQuery;
   dataChatThreadId?: number;
   onClick?: (q: QueryVersion, force?: boolean) => void;
   personId?: number;
   queryVersion: QueryVersion;
   selected?: boolean;
}) => {
   return (
      <div
         className={classNames(
            'card py-2 px-3 bg-secondary',
            { clickable: !!onClick },
            { 'border-selected': selected }
         )}
         onClick={() => onClick?.(queryVersion, selected ?? false)}
      >
         <Stack className="justify-content-between" gap={2}>
            <Stack gap={2}>
               <Stack className="justify-content-between" direction="horizontal" gap={2}>
                  <div className="fw-bold fs-16">{queryVersion.title}</div>
                  <Button>{selected ? 'Refresh Data' : 'View Data'}</Button>
               </Stack>
               <div>{queryVersion.question}</div>
               <div>{queryVersion.description}</div>
            </Stack>
            <Stack
               className="query-card-footer justify-content-between w-100"
               direction="horizontal"
               gap={2}
            >
               <Stack className="fs-9p text-muted" direction="horizontal" gap={1}>
                  <div>{getShortDateTimeString(queryVersion.modified)}</div>
                  <div className="fw-500 potential-badge">
                     –{' '}
                     {PersonUtilities.getFullName(
                        queryVersion.createdByPerson ?? {
                           firstName: 'runQL AI',
                        }
                     )}
                  </div>
               </Stack>
               {personId && (
                  <QueryFeedbackWidget
                     dataChatMessageQuery={dataChatMessageQuery}
                     dataChatThreadId={dataChatThreadId}
                     personId={personId}
                  />
               )}
            </Stack>
         </Stack>
      </div>
   );
};

export const QueryFeedbackWidget = ({
   creator,
   dataChatThreadId,
   dataChatMessageQuery,
   personId,
}: {
   creator?: Person;
   dataChatMessageQuery?: DataChatMessageQuery;
   dataChatThreadId?: number;
   personId: number;
}) => {
   const updateQueryFeedback = useUpdateQueryFeedback(dataChatThreadId);
   const handleFeedbackClick = (type: QueryFeedbackType) => (e: React.MouseEvent) => {
      e.stopPropagation();
      updateQueryFeedback?.mutate({
         dataChatMessageQueryId: dataChatMessageQuery?.id!,
         type: isMatch(type) ? null : type,
      });
   };

   const isMatch = (type: QueryFeedbackType) =>
      personId &&
      dataChatMessageQuery?.feedback.some(
         (feedback) => feedback.personId === personId && feedback.type === type
      );

   // We currently only get the creator and assignee on the thread. If we want
   // to display other analysts in the future, we'll need to send them with the
   // thread.
   const otherUserIds = (type: QueryFeedbackType) =>
      dataChatMessageQuery?.feedback
         .filter(
            (feedback) =>
               feedback.type === type &&
               feedback.personId !== personId &&
               feedback.personId === creator?.id
         )
         .map((feedback) => (
            <Badge title={`${creator!.firstName} ${creator!.lastName}`}>
               {creator!.firstName?.substring(0, 1)}
               {creator!.lastName?.substring(0, 1)}
            </Badge>
         ));

   return (
      <Stack direction="horizontal">
         {updateQueryFeedback?.isLoading && <LoadingSpinner size={18} />}
         {updateQueryFeedback?.isLoading === false && (
            <>
               <Button
                  colorScheme={isMatch(QueryFeedbackType.EXACT_MATCH) ? 'primary' : 'secondary'}
                  onClick={handleFeedbackClick(QueryFeedbackType.EXACT_MATCH)}
                  variant="link"
               >
                  <IconThumbsUp size={14} />
               </Button>
               {otherUserIds(QueryFeedbackType.EXACT_MATCH)}
               <Button
                  colorScheme={isMatch(QueryFeedbackType.NON_MATCH) ? 'primary' : 'secondary'}
                  onClick={handleFeedbackClick(QueryFeedbackType.NON_MATCH)}
                  variant="link"
               >
                  <IconThumbsDown size={14} />
               </Button>
               {otherUserIds(QueryFeedbackType.NON_MATCH)}
            </>
         )}
      </Stack>
   );
};

export default AnswerCard;
