import { useEffect, useMemo, useState } from 'react';
import {
   createBrowserRouter,
   createRoutesFromElements,
   Navigate,
   Outlet,
   Route,
   RouterProvider,
   useLocation,
   useNavigate,
   useParams,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Authed from './Authed';
import { AddCredentialsProvider, LoadingSpinner, Page, useTheme } from './components';
import ErrorBoundary from './components/ErrorBoundary';
import { PersonRole } from './enums';
import { usePerson, useVersionCheck } from './hooks';
import { ExtensionContextProvider } from './hooks/extension';
import { FeatureProvider } from './hooks/feature';
import Layout from './Layout';
import {
   AccountsListPage,
   AnswersPage,
   AuthRedirect,
   ChangePasswordPage,
   CompliancePage,
   DataConnectionsPage,
   DocumentationPage,
   Error404,
   Error5xx,
   Extension,
   OrgSettingsPage,
   PasswordResetPage,
   QueryListPage,
   QueryReview,
   QueryUpdates,
   ResetPasswordPage,
   SignInPage,
   SignUpPage,
   SnowflakeOAuthCallback,
   SourceTokenRedirect,
   WorkspaceListPage,
   WorkspacePage,
} from './pages';
import AppSelect from './pages/AppSelect';
import DataConnectionDetailsPageHandler from './pages/DataConnections/DataConnectionDetailsPage';
import { isDesktop } from './services';

const redirects = {
   '/home': '/',
   '/home/queries': '/',
   '/home/snippets': '/',
   '/home/logs': '/',
   '/queries': '/',
   '/snippets': '/home/snippets',
   '/logs': '/home/logs',
   '/workspace/:workspaceId/query/:queryId': '/workspaces/:workspaceId/query/:queryId',
   '/workspace/:workspaceId/:tabId': '/workspaces/:workspaceId',
   '/accounts': '/admin/accounts',
   '/settings': '/admin/settings',
   '/compliance': '/admin/compliance',
};

const Redirect = ({ to }: { to: string }) => {
   const params = useParams();
   return (
      <Navigate
         replace
         to={to.replace(/:([^/]+)/g, (match, param) => {
            return params[param] || match;
         })}
      />
   );
};

const HomeRoute = () => {
   const person = usePerson();
   let redirect = window.localStorage.getItem('redirect');
   window.localStorage.removeItem('redirect');
   if (person.role === PersonRole.ORG_BUSINESS_USER) {
      redirect ??= '/answers';
   }
   return redirect ? <Redirect to={redirect} /> : <QueryListPage />;
};

const STORAGE_KEYS = {
   PREFERRED_CLIENT: 'runql-preferred-client',
   IS_EXTERNAL: 'isExternal',
} as const;

const appSelectBypassList = ['/oauth/snowflake/callback'].map((path) => path.toLowerCase());
const AppSelectHandler = () => {
   const location = useLocation();
   const navigate = useNavigate();
   const [isLoading, setIsLoading] = useState(true);

   useEffect(() => {
      const handleRouting = () => {
         const currentPathnameLower = location.pathname.toLowerCase();

         if (
            isDesktop() ||
            currentPathnameLower === '/appselect' ||
            appSelectBypassList.includes(currentPathnameLower)
         ) {
            return;
         }

         const isExternal =
            document.referrer === '' ||
            !document.referrer.startsWith(window.location.origin) ||
            sessionStorage.getItem(STORAGE_KEYS.IS_EXTERNAL) === 'true';
         const is404 = currentPathnameLower === '/404' || currentPathnameLower === '/not-found';

         if (!isExternal || is404) {
            return;
         }

         sessionStorage.removeItem(STORAGE_KEYS.IS_EXTERNAL);

         const savedClient =
            sessionStorage.getItem(STORAGE_KEYS.PREFERRED_CLIENT) ||
            localStorage.getItem(STORAGE_KEYS.PREFERRED_CLIENT);

         if (savedClient === 'web') {
            return;
         }

         navigate('/appSelect', {
            replace: true,
            state: {
               targetPath: location.pathname,
               targetQueryString: location.search,
               ...(savedClient === 'desktop' && { client: 'desktop' }),
            },
         });
      };

      handleRouting();
      setIsLoading(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   if (isLoading) return null;
   return <Outlet />;
};

const App = (): JSX.Element => {
   const ready = useVersionCheck();
   const theme = useTheme();

   const extension = useMemo(
      () => ({
         extension: window !== window.parent || window.location.pathname === '/x',
         openTab: (url: string) => {
            window.open(url, '_blank');
         },
      }),
      []
   );

   const router = createBrowserRouter(
      createRoutesFromElements(
         <>
            <Route
               ErrorBoundary={() => (
                  <Page>
                     <Error5xx />
                  </Page>
               )}
               element={<Authed />}
            >
               <Route element={<Layout />}>
                  <Route element={<AppSelectHandler />}>
                     <Route element={<AppSelect />} path="/appSelect" />

                     {/* Home */}
                     <Route element={<HomeRoute />} path="/" />

                     {/* Workspaces */}
                     <Route element={<WorkspaceListPage />} path="/workspaces" />
                     <Route element={<WorkspacePage />} path="/workspaces/:workspaceId" />
                     <Route
                        element={<WorkspacePage />}
                        path="/workspaces/:workspaceId/query/:queryId"
                     />
                     <Route
                        element={<WorkspacePage />}
                        path="/workspaces/:workspaceId/table/:tableSchemaId"
                     />

                     <Route element={<QueryReview />} path="/query-review" />

                     <Route element={<QueryUpdates />} path="/query-updates" />

                     {/* Data Sources */}
                     <Route element={<DataConnectionsPage />} path="/sources" />
                     <Route
                        element={<DataConnectionDetailsPageHandler />}
                        path="/sources/:dataConnectionId"
                     />
                     <Route element={<SnowflakeOAuthCallback />} path="/oauth/snowflake/callback" />

                     <Route element={<DocumentationPage />} path="/documentation" />

                     {/* Admin */}
                     <Route element={<AccountsListPage />} path="/admin/accounts" />
                     <Route element={<OrgSettingsPage />} path="/admin/settings" />
                     <Route element={<CompliancePage />} path="/admin/compliance" />

                     {/* Other */}
                     <Route element={<ChangePasswordPage />} path="/change-password" />
                     <Route element={<AnswersPage />} path="/answers" />
                     <Route element={<AnswersPage />} path="/answers/:threadId" />

                     {/* Redirects */}
                     <Route element={<SourceTokenRedirect />} path="/source/:token" />
                     {Object.entries(redirects).map(([from, to]) => {
                        return <Route element={<Redirect to={to} />} key={from} path={from} />;
                     })}
                  </Route>
               </Route>
               <Route
                  ErrorBoundary={() => (
                     <div className="vh-100">
                        <Error5xx homeTo="/x" />
                     </div>
                  )}
                  element={<Extension />}
                  path="/x"
               />
            </Route>
            <Route
               ErrorBoundary={() => (
                  <div className="vh-100">
                     <Error5xx />
                  </div>
               )}
            >
               <Route element={<SignInPage />} path="/signin" />
               <Route element={<SignUpPage />} path="/signup" />
               <Route element={<PasswordResetPage />} path="/reset" />
               <Route element={<ResetPasswordPage />} path="/p/:link" />
               <Route element={<SignUpPage />} path="/invite/:link" />
               <Route element={<AuthRedirect provider="google" />} path="/x/google" />
               <Route element={<AuthRedirect provider="ms" />} path="/x/ms" />
               <Route element={<Error404 />} path="*" />
            </Route>
         </>
      )
   );

   if (!ready) return <LoadingSpinner />;

   return (
      <ErrorBoundary>
         <ToastContainer
            pauseOnFocusLoss
            pauseOnHover
            position="bottom-right"
            stacked
            theme={theme}
         />
         <FeatureProvider>
            <ExtensionContextProvider value={extension}>
               <AddCredentialsProvider>
                  <RouterProvider router={router} />
               </AddCredentialsProvider>
            </ExtensionContextProvider>
         </FeatureProvider>
      </ErrorBoundary>
   );
};

export default App;
