import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState, useRef } from 'react';
import { Form, Stack, Collapse } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { useQueryClient } from 'react-query';
import { useInjection } from 'inversify-react';
import { formatRelative } from 'date-fns';

import { CredentialPersistence } from '@runql/util';

import { DBMS, QueryKeyType } from '../../../../enums';
import { useGetDataConnectionQuery, getDataConnectionQueryKey } from '../../../../hooks';
import { connectionName, description } from '../validators';
import { ConnectionAccessType, DataConnection } from '../../../../entities';
import {
   AccessTypeField,
   ConnectionCredentialsFields,
   ConnectionParamSelectField,
   CredentialPersistenceField,
   PasswordField,
} from './common';
import { ConnectionFields, CredentialFields } from './ConnectionDetailsForm';
import { LoadingSpinner, Button } from '../../../../components';
import { TYPES } from '../../../../types';
import { IconOpen } from '../../../../utilities/icons';

import type { LocalCredentialService } from '../../../../services/LocalCredentialService';

const snowflakeOAuthCallbackDataSchema = z.object({
   dataConnectionId: z.number(),
   snowflakeOAuth: z.object({
      refreshToken: z.string(),
      expiresAt: z.string().datetime({ offset: true }),
   }),
   rqlEncryptionVersion: z.number(),
   credentialPersistence: z.nativeEnum(CredentialPersistence).optional(),
});

const credentialsSchema = z.object({
   accountName: z.string().max(255),
   password: z.string(),
   rememberCredential: z.boolean().optional(),
});

const metadataSchema = z
   .object({
      oauth: z.object({
         clientId: z.string(),
         clientSecret: z.string(),
         authorizationEndpoint: z.string(),
         tokenEndpoint: z.string(),
      }),
   })
   .nullable();

const snowflakeSchema = z
   .object({
      dbms: z.literal(DBMS.Snowflake),
      connectionAccessType: z.nativeEnum(ConnectionAccessType),
      connectionName: connectionName,
      description: description,
      snowflakeAccount: z.string().min(1, 'Required'),
      warehouse: z.string(),
      database: z
         .string()
         .min(1, 'Required')
         .regex(/^[^.]*$/, 'Database cannot specify a schema'),
      metadata: metadataSchema,
   })
   .merge(credentialsSchema);

type SnowflakeDetailFormData = z.infer<typeof snowflakeSchema>;

type SnowflakeOAuthCredentialsType =
   | {
        data: {
           expiresAt: string;
           refreshToken: string;
        };
        status: 'active';
     }
   | {
        data: {
           expiresAt: string;
           refreshToken: string;
        };
        status: 'expired';
     }
   | {
        data: null;
        status: 'none';
     };

export const SnowflakeOAuthCredentials = ({
   dataConnection,
   isAuthorizationFlowDisabled = false,
   onChangeCredentialsStatus = () => {},
}: {
   dataConnection: DataConnection;
   isAuthorizationFlowDisabled?: boolean;
   onChangeCredentialsStatus?: (status: 'active' | 'expired' | 'none') => void;
}) => {
   let snowflakeOAuthCredentials: SnowflakeOAuthCredentialsType = {
      data: null,
      status: 'none',
   };
   if (dataConnection.dataCredentials?.[0]?.snowflakeOAuth) {
      const { expiresAt, refreshToken } = dataConnection.dataCredentials[0].snowflakeOAuth;
      snowflakeOAuthCredentials = {
         data: { expiresAt: formatRelative(new Date(expiresAt), new Date()), refreshToken },
         status: Date.now() > new Date(expiresAt).getTime() ? 'expired' : 'active',
      };
   }

   useEffect(() => {
      onChangeCredentialsStatus(snowflakeOAuthCredentials.status);
   }, [onChangeCredentialsStatus, snowflakeOAuthCredentials.status]);

   const queryClient = useQueryClient();
   const localCredentialService = useInjection<LocalCredentialService>(
      TYPES.localCredentialService
   );
   const [oauthRole, setOauthRole] = useState('');
   const [isSessionStorage, setIsSessionStorage] = useState(false);
   const oauthAuthorizationFlowWindowRef = useRef<WindowProxy | null>(null);

   // Handle messages from the new tab used for the OAuth authorization flow.
   useEffect(() => {
      const handleMessage = async (event: MessageEvent) => {
         if (
            event.origin !== window.location.origin ||
            event.source === null ||
            oauthAuthorizationFlowWindowRef.current === null ||
            event.source !== oauthAuthorizationFlowWindowRef.current ||
            event.data?.name !== 'snowflakeOAuthCallback'
         ) {
            return;
         }

         const credentialDataResult = snowflakeOAuthCallbackDataSchema.safeParse(event.data.data);

         if (credentialDataResult.success) {
            await localCredentialService.set(
               credentialDataResult.data.dataConnectionId,
               credentialDataResult.data
            );
            queryClient.invalidateQueries(
               getDataConnectionQueryKey({ type: QueryKeyType.GET, id: dataConnection.id })
            );
         }

         oauthAuthorizationFlowWindowRef.current.postMessage({
            name: 'snowflakeOAuthCallback',
            success: credentialDataResult.success,
         });

         oauthAuthorizationFlowWindowRef.current = null;
         setOauthRole('');
      };

      window.addEventListener('message', handleMessage);

      return () => {
         window.removeEventListener('message', handleMessage);
      };
   }, [queryClient, dataConnection.id, localCredentialService]);

   if (!dataConnection.id) {
      throw new Error('Bad implementation');
   }

   const dataConnectionId = dataConnection.id;

   const startOAuthAuthorizationFlow = async () => {
      await localCredentialService.delete(dataConnection.id!);

      const baseUrl = process.env.REACT_APP_API_URI ?? 'http://localhost:3000';

      oauthAuthorizationFlowWindowRef.current = window.open(
         `${baseUrl}/v1/oauth/snowflake/authorize?${new URLSearchParams({
            dataConnectionId: dataConnectionId.toString(),
            ...(oauthRole !== '' ? { role: oauthRole } : {}),
            ...(isSessionStorage ? { useSessionStorage: 'true' } : {}),
         }).toString()}`,
         '_blank'
      );
   };

   const deleteOAuthCredentials = async () => {
      await localCredentialService.delete(dataConnectionId);

      queryClient.invalidateQueries(
         getDataConnectionQueryKey({ type: QueryKeyType.GET, id: dataConnectionId })
      );
   };

   if (snowflakeOAuthCredentials.status === 'none') {
      return (
         <div>
            <Form.Group>
               <Form.Label>Snowflake Primary Role</Form.Label>
               <div
                  style={{
                     display: 'grid',
                     gridTemplateColumns: 'auto auto',
                     alignItems: 'center',
                     gap: '0 1rem',
                  }}
               >
                  <Form.Control
                     onChange={(e) => {
                        setOauthRole(e.target.value);
                     }}
                     placeholder="e.g., PUBLIC"
                     value={oauthRole}
                  />

                  <Button
                     className="flex-shrink-0 align-self-center"
                     disabled={isAuthorizationFlowDisabled}
                     onClick={startOAuthAuthorizationFlow}
                     size="md"
                  >
                     <IconOpen className="me-1" />
                     <span>Sign in to Snowflake to Grant Access</span>
                  </Button>
                  <Form.Text className="d-block">
                     Optional. If not provided, your default Snowflake role will be used. If,
                     however, your default role is one of ACCOUNTADMIN, ORGADMIN, or SECURITYADMIN,
                     then you must provide a different role here since these roles are blocked.
                  </Form.Text>
                  <span></span>
                  <Form.Check
                     className="d-flex align-items-center gap-2 mb-0"
                     id="useSessionStorageToggle"
                     type="checkbox"
                  >
                     <Form.Check.Input
                        checked={isSessionStorage}
                        className="mt-0"
                        onChange={(e) => {
                           setIsSessionStorage(e.target.checked);
                        }}
                     />
                     <Form.Check.Label>Clear credentials on log out</Form.Check.Label>
                  </Form.Check>
               </div>

               {isAuthorizationFlowDisabled ? (
                  <Form.Text className="d-block text-info">
                     Please save or discard unsaved changes before granting access
                  </Form.Text>
               ) : null}
            </Form.Group>
         </div>
      );
   }

   if (snowflakeOAuthCredentials.status === 'active') {
      return (
         <Stack direction="horizontal" gap={2}>
            <Stack direction="horizontal" gap={1}>
               <div
                  className="rounded-circle bg-success bg-gradient"
                  style={{ width: '1em', height: '1em' }}
               ></div>
               <span className="lh-1">
                  Access Granted (expires {snowflakeOAuthCredentials.data.expiresAt})
               </span>
            </Stack>
            <div>
               <Button colorScheme="secondary" onClick={deleteOAuthCredentials} variant="outline">
                  Revoke Access
               </Button>
            </div>
         </Stack>
      );
   }

   return (
      <Stack direction="horizontal" gap={2}>
         <Stack direction="horizontal" gap={1}>
            <div
               className="rounded-circle bg-danger bg-gradient"
               style={{ width: '1em', height: '1em' }}
            ></div>
            <span className="lh-1">Access Expired</span>
         </Stack>
         <div>
            <Button colorScheme="secondary" onClick={deleteOAuthCredentials} variant="outline">
               Clear Credentials
            </Button>
         </div>
      </Stack>
   );
};

const defaultMetadata = {
   oauth: {
      clientId: '',
      clientSecret: '',
      authorizationEndpoint: '',
      tokenEndpoint: '',
   },
};

function SnowflakeDetailForm({
   editType,
   formId,
   isSaving,
   onSaveStateChange,
   onSubmit,
   onlyCreds,
   selectedConnectionId,
   onToggleOAuth = () => {},
}: {
   editType: 'connection' | 'credential' | 'read-only';
   formId: string;
   isSaving?: boolean;
   onSaveStateChange?: (state: 'clean' | 'dirty') => void;
   onSubmit?: (data: ConnectionFields & CredentialFields) => void;
   onToggleOAuth?: (isEnabled: boolean) => void;
   onlyCreds?: boolean;
   selectedConnectionId?: number;
}) {
   const [useOAuth, setUseOAuth] = useState(false);
   useEffect(() => {
      onToggleOAuth(useOAuth);
   }, [useOAuth, onToggleOAuth]);

   const resolver = zodResolver(onlyCreds ? credentialsSchema.passthrough() : snowflakeSchema);

   const formMethods = useForm<SnowflakeDetailFormData>({
      resolver: async (data, context, options) => {
         return resolver({ ...data, metadata: useOAuth ? data.metadata : null }, context, options);
      },
      defaultValues: {
         rememberCredential: true,
         accountName: '',
         password: '',
         metadata: defaultMetadata,
      },
   });
   const { register, handleSubmit, formState, reset, setValue, watch } = formMethods;
   const errors = formState.errors;
   const touchedFields = formState.touchedFields;
   const connectionAccessType = watch('connectionAccessType', ConnectionAccessType.INDIVIDUAL);

   // Queries
   const selectedConnectionQuery = useGetDataConnectionQuery({
      id: selectedConnectionId,
      getOptions: { includeCredentials: true },
   });

   // Effects
   useEffect(() => {
      if (selectedConnectionQuery.data) {
         // The client secret is never included in the API response. When editing an existing
         // connection, we'll assume it is set and use 'CURRENT' as a placeholder.
         const metadataResult = metadataSchema.safeParse({
            oauth: {
               ...(selectedConnectionQuery.data?.metadataPublic?.oauth ?? {}),
               clientSecret: 'CURRENT',
            },
         });

         const useOAuth = metadataResult.success && metadataResult.data !== null;

         const metadata = useOAuth ? metadataResult.data : defaultMetadata;

         const dataCredential = selectedConnectionQuery.data.dataCredentials?.[0];

         const formData: SnowflakeDetailFormData = {
            dbms: DBMS.Snowflake,
            connectionAccessType:
               selectedConnectionQuery.data.connectionAccessType ?? ConnectionAccessType.INDIVIDUAL,
            connectionName: selectedConnectionQuery.data.name ?? '',
            description: selectedConnectionQuery.data.description ?? '',
            snowflakeAccount: selectedConnectionQuery.data.snowflakeAccount ?? '',
            warehouse: selectedConnectionQuery.data.snowflakeWarehouse ?? '',
            database: selectedConnectionQuery.data.catalogName ?? '',
            accountName: dataCredential?.accountName ?? '',
            password: dataCredential?.accountPassword === undefined ? '' : 'CURRENT',
            rememberCredential: dataCredential?.credentialPersistence
               ? dataCredential.credentialPersistence === CredentialPersistence.LOCAL_STORAGE
               : true,
            metadata,
         };
         reset(formData);
         setUseOAuth(useOAuth);
      }
   }, [selectedConnectionQuery.data, reset]);

   useEffect(() => {
      const isDirtyAlt = !!Object.keys(formState.dirtyFields).length;
      if (isDirtyAlt) {
         onSaveStateChange?.('dirty');
      } else {
         onSaveStateChange?.('clean');
      }
   }, [formState, onSaveStateChange]);

   if (isSaving) {
      return <LoadingSpinner />;
   }

   const handleOnSubmit = (data: SnowflakeDetailFormData) => {
      if (onSubmit) {
         onSubmit({
            ...data,
            metadata: useOAuth ? data.metadata : null,
            connectionAccessType: useOAuth
               ? ConnectionAccessType.INDIVIDUAL
               : data.connectionAccessType,
         });
      }
   };

   return (
      <FormProvider {...formMethods}>
         <Form id={formId} onSubmit={handleSubmit(handleOnSubmit)}>
            <Stack gap={3}>
               {!onlyCreds && (
                  <>
                     <input type="hidden" {...register('dbms')} value={DBMS.Snowflake} />
                     <Form.Group>
                        <Form.Label>
                           Connection Name <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                           {...register('connectionName')}
                           disabled={editType !== 'connection'}
                           isInvalid={touchedFields.connectionName && !!errors.connectionName}
                           isValid={touchedFields.connectionName && !errors.connectionName}
                           placeholder="Connection Name"
                           required
                        />
                        <Form.Control.Feedback type="invalid">
                           {errors.connectionName?.message}
                        </Form.Control.Feedback>
                     </Form.Group>
                     <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                           {...register('description')}
                           as="textarea"
                           disabled={editType !== 'connection'}
                           isInvalid={touchedFields.description && !!errors.description}
                           isValid={touchedFields.description && !errors.description}
                           placeholder="Description"
                           rows={3}
                        />
                        <Form.Control.Feedback type="invalid">
                           {errors.description?.message}
                        </Form.Control.Feedback>
                     </Form.Group>
                     <ConnectionParamSelectField
                        connectionField="snowflakeAccount"
                        dbms={DBMS.Snowflake}
                        isRequired
                        label={
                           <div>
                              Snowflake Account <span className="text-danger">*</span>{' '}
                              <a
                                 className="fs-11p"
                                 href="https://docs.snowflake.com/en/user-guide/admin-account-identifier#format-1-preferred-account-name-in-your-organization"
                                 rel="noreferrer"
                                 target={'_blank'}
                              >
                                 (find your account identifier)
                              </a>
                           </div>
                        }
                        name="snowflakeAccount"
                        onExistingSelect={(value) => {
                           setValue('warehouse', value.snowflakeWarehouse ?? '');
                           setValue('database', value.catalogName ?? '');
                        }}
                     />
                     <Form.Group>
                        <Form.Label>
                           Warehouse <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                           {...register('warehouse')}
                           disabled={editType !== 'connection'}
                           isInvalid={touchedFields.warehouse && !!errors.warehouse}
                           isValid={touchedFields.warehouse && !errors.warehouse}
                           placeholder="Warehouse"
                           required
                        />
                        <Form.Control.Feedback type="invalid">
                           {errors.warehouse?.message}
                        </Form.Control.Feedback>
                     </Form.Group>
                     <Form.Group>
                        <Form.Label>
                           Database <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                           {...register('database')}
                           disabled={editType !== 'connection'}
                           isInvalid={touchedFields.database && !!errors.database}
                           isValid={touchedFields.database && !errors.database}
                           placeholder="Database"
                           required
                        />
                        <Form.Control.Feedback type="invalid">
                           {errors.database?.message}
                        </Form.Control.Feedback>
                     </Form.Group>

                     <Form.Group>
                        <Form.Label>Use OAuth</Form.Label>
                        <Form.Check
                           checked={useOAuth}
                           className="mb-0"
                           onChange={() => {
                              const next = !useOAuth;

                              if (
                                 next &&
                                 connectionAccessType !== ConnectionAccessType.INDIVIDUAL
                              ) {
                                 setValue('connectionAccessType', ConnectionAccessType.INDIVIDUAL, {
                                    shouldDirty: true,
                                 });
                              }

                              setUseOAuth(next);
                           }}
                           type="switch"
                        />
                        <Form.Text className="d-block">
                           When OAuth is enabled, Individual credentials must be used. Also, queries
                           using this connection will run on the runQL servers, even when using the
                           Desktop IDE.
                        </Form.Text>
                     </Form.Group>
                     <Collapse in={useOAuth}>
                        <Stack gap={3}>
                           <Form.Group>
                              <Form.Label>
                                 Client ID <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                 {...register('metadata.oauth.clientId')}
                                 placeholder="OAuth client ID"
                                 required={useOAuth}
                              />
                           </Form.Group>
                           <PasswordField
                              fieldPath="metadata.oauth.clientSecret"
                              isRequired={useOAuth}
                              label="Client Secret"
                              placeholder="OAuth client secret"
                           />
                           <Form.Group>
                              <Form.Label>
                                 Authorization Endpoint <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                 {...register('metadata.oauth.authorizationEndpoint')}
                                 placeholder="e.g., https://myorg-account_xyz.snowflakecomputing.com/oauth/authorize"
                                 required={useOAuth}
                              />
                           </Form.Group>
                           <Form.Group>
                              <Form.Label>
                                 Token Endpoint <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                 {...register('metadata.oauth.tokenEndpoint')}
                                 placeholder="e.g., https://myorg-account_xyz.snowflakecomputing.com/oauth/token-request"
                                 required={useOAuth}
                              />
                           </Form.Group>
                        </Stack>
                     </Collapse>
                     <AccessTypeField
                        disabled={useOAuth || editType !== 'connection'}
                        readOnly={useOAuth}
                        {...register('connectionAccessType', {
                           setValueAs: (v: string) => parseInt(v) as ConnectionAccessType,
                           onChange: () => {
                              setValue('accountName', '');
                              setValue('password', '');
                           },
                        })}
                     />
                  </>
               )}
               {useOAuth && selectedConnectionQuery.data ? (
                  <SnowflakeOAuthCredentials
                     dataConnection={selectedConnectionQuery.data}
                     isAuthorizationFlowDisabled={formState.isDirty}
                  />
               ) : null}
               {!useOAuth ? (
                  <>
                     <Collapse in={connectionAccessType === ConnectionAccessType.INDIVIDUAL}>
                        <div>
                           <CredentialPersistenceField />
                        </div>
                     </Collapse>
                     <ConnectionCredentialsFields
                        autoFocusName={onlyCreds}
                        readonly={editType === 'read-only'}
                     />
                  </>
               ) : null}
            </Stack>
         </Form>
      </FormProvider>
   );
}

export default SnowflakeDetailForm;
