import { useInjection } from 'inversify-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Card, Col, Form, Row, Stack } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { toast } from 'react-toastify';
import { FaEdit } from 'react-icons/fa';

import { Button } from '../../../components';
import { SchemaNode, SchemaTreeProvider } from '../../../components/SchemaTree';
import LoadingSpinner from '../../../components/UI/LoadingSpinner';
import { useBulkUpdateSchemaCacheMutation, useUpdateSchema } from '../../../hooks';
import { ApiService } from '../../../services';
import { TYPES } from '../../../types';
import { useSchemaRoots } from './hooks';

import type { OnClickTableLeafData, SchemaRoot } from '../../../components/SchemaTree';
import type { DataConnection } from '../../../entities';

const IS_GENERATING_TIMEOUT_MS = 2 * 60 * 1000;
const isFreshlyProcessing = (isGenerating: string | null) =>
   isGenerating !== null &&
   Date.now() <= new Date(isGenerating!).getTime() + IS_GENERATING_TIMEOUT_MS;

type SchemaMetadataFormData = {
   description: string;
};

const SchemaMetadataContainer = ({
   data,
   onSave,
}: {
   data: SchemaRoot;
   onSave: (values: { description: string; id: number }[]) => Promise<void>;
}) => {
   const prevSchemaMetadataIdRef = useRef(data.id);

   const {
      handleSubmit,
      register,
      formState: { isDirty, isSubmitting },
      reset,
   } = useForm<TableMetadataFormData>({ defaultValues: { description: data.schemaDescription } });

   // If we receive new data, reset the form. Keep dirty values if the id has not changed.
   useEffect(() => {
      reset(
         { description: data.schemaDescription },
         { keepDirtyValues: data.id === prevSchemaMetadataIdRef.current }
      );
      prevSchemaMetadataIdRef.current = data.id;
   }, [data, reset]);

   const handleSave = (value: SchemaMetadataFormData) => {
      if (value.description === data.schemaDescription) {
         return Promise.resolve();
      }

      const schemaCacheUpdates = [{ id: data.id, description: value.description }];

      return onSave(schemaCacheUpdates);
   };

   const handleClickReset = () => {
      reset({ description: data.schemaDescription });
   };

   return (
      <Stack className="h-100 overflow-hidden">
         <Stack className="mb-2 flex-shrink-0 justify-content-between" direction="horizontal">
            <Stack direction="horizontal">
               <span className="fs-14p fw-normal">{data.schemaName}</span>
            </Stack>
         </Stack>

         <Form onSubmit={handleSubmit(handleSave)}>
            <Stack className="w-100 w-xl-75" gap={3}>
               <div className="flex-grow-0 flex-shrink-0">
                  <Form.Group controlId="table-description">
                     <div className="px-3">
                        <Form.Label className="fs-10p fw-semibold text-reset">
                           Schema Description
                        </Form.Label>
                     </div>
                     <Card className="border-1 p-3 shadow-sm fs-10p">
                        {isFreshlyProcessing(data.isGeneratingMetadata) ? (
                           <Form.Control
                              as="textarea"
                              defaultValue="Processing..."
                              disabled
                              rows={3}
                           />
                        ) : (
                           <Form.Control {...register('description')} as="textarea" rows={3} />
                        )}
                     </Card>
                  </Form.Group>
               </div>
               <div className="flex-grow-0 flex-shrink-0">
                  <Stack
                     className="flex-grow-0 flex-shrink-0 py-2 px-3 justify-content-end"
                     direction="horizontal"
                     gap={2}
                  >
                     <Button
                        colorScheme="secondary"
                        disabled={!isDirty || isSubmitting}
                        onClick={handleClickReset}
                        size="sm"
                        type="button"
                     >
                        Reset
                     </Button>
                     <Button disabled={!isDirty} isLoading={isSubmitting} size="sm" type="submit">
                        Save
                     </Button>
                  </Stack>
               </div>
            </Stack>
         </Form>
      </Stack>
   );
};

type TableMetadataColumn = {
   dataType: string;
   description: string;
   example: string;
   id: number;
   isGenerating: string | null;
   name: string;
   type: 'column';
};

type TableMetadata = {
   columns: TableMetadataColumn[];
   description: string;
   id: number;
   isGenerating: string | null;
   name: string;
   type: 'table';
};

type TableMetadataFormData = {
   columns: {
      description: string;
      example: string;
   }[];
   description: string;
};
const TableMetadataTable = ({
   tableMetadata,
   onSave,
}: {
   onSave: (values: { description: string; exampleData?: string; id: number }[]) => Promise<void>;
   tableMetadata: TableMetadata;
}) => {
   const prevTableMetadataIdRef = useRef(tableMetadata.id);

   const {
      handleSubmit,
      register,
      formState: { isDirty, isSubmitting },
      reset,
   } = useForm<TableMetadataFormData>({ defaultValues: tableMetadata });

   // If we receive new data, reset the form. Keep dirty values if the table has not changed.
   useEffect(() => {
      reset(tableMetadata, {
         keepDirtyValues: tableMetadata.id === prevTableMetadataIdRef.current,
      });
      prevTableMetadataIdRef.current = tableMetadata.id;
   }, [tableMetadata, reset]);

   const handleSave = (values: TableMetadataFormData) => {
      const schemaCacheUpdates = [
         ...(values.description !== tableMetadata.description
            ? [{ id: tableMetadata.id, description: values.description }]
            : []),
         ...tableMetadata.columns.reduce<
            { description: string; exampleData: string; id: number }[]
         >((acc, column, idx) => {
            const { description: newDescription, example: newExample } = values.columns[idx];

            if (column.description !== newDescription || column.example !== newExample) {
               acc.push({ id: column.id, description: newDescription, exampleData: newExample });
            }

            return acc;
         }, []),
      ];

      return onSave(schemaCacheUpdates);
   };

   const handleClickReset = () => {
      reset(tableMetadata);
   };

   return (
      <Form className="h-100 d-flex flex-column" onSubmit={handleSubmit(handleSave)}>
         <div className="mb-3 flex-grow-0 flex-shrink-0">
            <Form.Group controlId="table-description">
               <div className="px-3">
                  <Form.Label className="fs-10p fw-semibold text-reset">
                     Table Description
                  </Form.Label>
               </div>
               <Card className="w-100 w-xl-75 border-1 p-3 shadow-sm fs-10p">
                  {isFreshlyProcessing(tableMetadata.isGenerating) ? (
                     <Form.Control as="textarea" defaultValue="Processing..." disabled rows={3} />
                  ) : (
                     <Form.Control {...register('description')} as="textarea" rows={3} />
                  )}
               </Card>
            </Form.Group>
         </div>
         <div className="px-3 py-1 fs-10p flex-grow-0 flex-shrink-0">
            <Row className="justify-content-center">
               <Col xs={2}>
                  <span className="fw-semibold">Column</span>
               </Col>
               <Col xs={2}>
                  <span className="fw-semibold">Data Type</span>
               </Col>
               <Col xs={5}>
                  <span className="fw-semibold">Description</span>
               </Col>
               <Col xs={3}>
                  <span className="fw-semibold">Example Data</span>
               </Col>
            </Row>
         </div>
         <div className="flex-shrink-1 overflow-y-auto hide-scrollbar">
            {tableMetadata.columns.map((column, idx) => {
               const showProcessing = isFreshlyProcessing(column.isGenerating);
               return (
                  <Card className="border-1 mb-2 p-3 shadow-sm fs-10p" key={column.id}>
                     <Row className="justify-content-center">
                        <Col xs={2}>{column.name}</Col>
                        <Col xs={2}>{column.dataType}</Col>
                        <Col xs={5}>
                           <Form.Group controlId={`column-description-${column.id}`}>
                              <Form.Label className="visually-hidden">
                                 {column.name} Description
                              </Form.Label>
                              {showProcessing ? (
                                 <Form.Control
                                    as="textarea"
                                    defaultValue="Processing..."
                                    disabled
                                    rows={1}
                                 />
                              ) : (
                                 <Form.Control
                                    {...register(`columns.${idx}.description`)}
                                    as="textarea"
                                    rows={1}
                                 />
                              )}
                           </Form.Group>
                        </Col>
                        <Col xs={3}>
                           <Form.Group controlId={`column-example-${column.id}`}>
                              <Form.Label className="visually-hidden">
                                 {column.name} Example Data
                              </Form.Label>
                              {showProcessing ? (
                                 <Form.Control defaultValue="Processing..." disabled />
                              ) : (
                                 <Form.Control {...register(`columns.${idx}.example`)} />
                              )}
                           </Form.Group>
                        </Col>
                     </Row>
                  </Card>
               );
            })}
         </div>
         <div className="flex-grow-0 flex-shrink-0">
            <Stack
               className="flex-grow-0 flex-shrink-0 py-2 px-3 justify-content-end"
               direction="horizontal"
               gap={2}
            >
               <Button
                  colorScheme="secondary"
                  disabled={!isDirty || isSubmitting}
                  onClick={handleClickReset}
                  size="sm"
                  type="button"
               >
                  Reset
               </Button>
               <Button disabled={!isDirty} isLoading={isSubmitting} size="sm" type="submit">
                  Save
               </Button>
            </Stack>
         </div>
      </Form>
   );
};

type FetchTableMetadataQueryKeyParamsDataBase = {
   dataConnectionId: number;
};
type FetchTableMetadataQueryKeyParamsFetch = {
   data: FetchTableMetadataQueryKeyParamsDataBase & {
      schemaName: string;
      tableName: string;
   };
   type: 'fetch';
};
type FetchTableMetadataQueryKeyParamsInvalidate = {
   data: FetchTableMetadataQueryKeyParamsDataBase & {
      schemaName?: string;
      tableName?: string;
   };
   type: 'invalidate';
};
type FetchTableMetadataQueryKeyParams =
   | FetchTableMetadataQueryKeyParamsFetch
   | FetchTableMetadataQueryKeyParamsInvalidate;
export const fetchTableMetadataQueryKey = ({ type, data }: FetchTableMetadataQueryKeyParams) => {
   const baseKey = ['dataConnection', data.dataConnectionId, 'tableMetadata'];

   if (type === 'fetch') {
      return [...baseKey, data.schemaName, data.tableName];
   }

   if (!data.schemaName) {
      return baseKey;
   }

   const schemaKey = [...baseKey, data.schemaName];

   if (!data.tableName) {
      return schemaKey;
   }

   return [...schemaKey, data.tableName];
};
export const useTableMetadata = ({
   dataConnection,
   schemaName,
   tableName,
   refetchInterval = false,
}: {
   dataConnection: OnClickTableLeafData['dataConnection'];
   refetchInterval?: number | false | ((data: TableMetadata | undefined) => number | false);
   schemaName: string;
   tableName: string;
}) => {
   const apiService = useInjection<ApiService>(TYPES.apiService);

   return useQuery<TableMetadata>(
      fetchTableMetadataQueryKey({
         type: 'fetch',
         data: {
            dataConnectionId: dataConnection.id,
            schemaName,
            tableName,
         },
      }),
      async () => {
         const result = await apiService.get<TableMetadata>(
            `/v1/dataConnection/${dataConnection.id}/tableMetadata`,
            {
               schemaName,
               tableName,
            }
         );

         if (!result) {
            throw new Error('Error loading table metadata');
         }

         return result;
      },
      { retry: false, refetchOnMount: 'always', staleTime: 1000 * 60 * 15, refetchInterval }
   );
};
const TableMetadataContainer = ({ data }: { data: OnClickTableLeafData }) => {
   const { dataConnection, schemaName } = data;
   const tableName = data.schemaContentItemTable.name;

   const [isRefreshingSchema, setIsRefreshingSchema] = useState(false);

   const { updateSchema } = useUpdateSchema();
   const bulkUpdateSchemaCacheMutator = useBulkUpdateSchemaCacheMutation();

   const tableMetadataQueryResult = useTableMetadata({
      dataConnection,
      schemaName,
      tableName,
      refetchInterval: (data) => {
         if (data === undefined) {
            return false;
         }

         const isSomeFreshlyProcessing =
            isFreshlyProcessing(data.isGenerating) ||
            data.columns.some((column) => isFreshlyProcessing(column.isGenerating));

         if (!isSomeFreshlyProcessing) {
            return false;
         }

         return 1000 * 20;
      },
   });

   if (tableMetadataQueryResult.isLoading) {
      return <LoadingSpinner />;
   }

   if (!tableMetadataQueryResult.data) {
      throw new Error('Error loading table metadata');
   }

   const handleClickRefreshSchema = async () => {
      setIsRefreshingSchema(true);

      toast.info(
         <>
            Refreshing <span className="fw-semibold">{tableName}</span> schema and generating
            metadata. This may take few minutes.
         </>
      );

      try {
         await updateSchema(dataConnection.id, { type: 'table', schemaName, tableName });

         await tableMetadataQueryResult.refetch();
      } finally {
         setIsRefreshingSchema(false);
      }
   };

   const handleSave = async (
      values: { description: string; exampleData?: string; id: number }[]
   ) => {
      if (values.length === 0) {
         return;
      }

      await bulkUpdateSchemaCacheMutator.mutateAsync({
         dataConnectionId: dataConnection.id,
         schemaCache: values,
      });

      await tableMetadataQueryResult.refetch();
   };

   return (
      <div className="h-100 d-flex flex-column overflow-hidden">
         <Stack
            className="pb-2 flex-shrink-0 justify-content-between"
            direction="horizontal"
            gap={3}
         >
            <Stack direction="horizontal">
               <span className="fs-14p fw-normal">
                  {schemaName} ▸ <span className="fw-medium">{tableName}</span>
               </span>
            </Stack>
            <Button
               colorScheme="secondary"
               isLoading={isRefreshingSchema}
               onClick={handleClickRefreshSchema}
               size="sm"
               variant="outline"
            >
               Refresh Table Schema
            </Button>
         </Stack>
         {isRefreshingSchema ? (
            <LoadingSpinner />
         ) : (
            <div className="flex-grow-1 overflow-hidden d-flex flex-column">
               <TableMetadataTable
                  onSave={handleSave}
                  tableMetadata={tableMetadataQueryResult.data}
               />
            </div>
         )}
      </div>
   );
};

export const MetadataTabContent = ({ dataConnection }: { dataConnection: DataConnection }) => {
   const bulkUpdateSchemaCacheMutator = useBulkUpdateSchemaCacheMutation();

   const [selectedData, setSelectedData] = useState<
      { data: OnClickTableLeafData; mode: 'table' } | { data: SchemaRoot; mode: 'schema' } | null
   >(null);

   const handleClickSchemaAction = useCallback((data: SchemaRoot) => {
      setSelectedData({ data, mode: 'schema' });
   }, []);

   const handleClickTableLeaf = useCallback((data: OnClickTableLeafData) => {
      setSelectedData({ data, mode: 'table' });
   }, []);

   const dataConnectionId = dataConnection.id!;

   const schemaRootsQueryResult = useSchemaRoots({ dataConnectionId });

   if (schemaRootsQueryResult.isLoading) {
      return <LoadingSpinner />;
   }

   if (!schemaRootsQueryResult.data) {
      throw new Error('Error loading metadata');
   }

   const schemaRoots = schemaRootsQueryResult.data;

   if (schemaRoots.length === 0) {
      return <p>No visible schemas available!</p>;
   }

   const handleSaveSchemaMetadata = async (values: { description: string; id: number }[]) => {
      if (values.length === 0) {
         return;
      }

      await bulkUpdateSchemaCacheMutator.mutateAsync({
         dataConnectionId: dataConnectionId,
         schemaCache: values,
      });

      await schemaRootsQueryResult.refetch();
   };

   return (
      <div className="h-100 d-flex flex-column">
         <PanelGroup className="flex-grow-1" direction="horizontal">
            <Panel className="overflow-auto" collapsible={true} defaultSize={20} minSize={10}>
               <SchemaTreeProvider
                  onClickTableLeaf={handleClickTableLeaf}
                  schemaContentTypes={['table']}
               >
                  <div className="d-flex flex-column">
                     {schemaRoots.map((schemaRoot) => (
                        <SchemaNode
                           extraActions={[
                              {
                                 Icon: FaEdit,
                                 label: 'Edit Schema',
                                 onClick: handleClickSchemaAction,
                              },
                           ]}
                           initialIsExpanded={schemaRoots.length === 1}
                           key={schemaRoot.id}
                           schemaRoot={schemaRoot}
                        />
                     ))}
                  </div>
               </SchemaTreeProvider>
            </Panel>
            <PanelResizeHandle className="panelHandle vertical">
               <div className="panelHandleLine" />
            </PanelResizeHandle>
            <Panel className="ps-3 h-100" defaultSize={80} minSize={50}>
               {selectedData !== null ? (
                  selectedData.mode === 'schema' ? (
                     <SchemaMetadataContainer
                        data={selectedData.data}
                        onSave={handleSaveSchemaMetadata}
                     />
                  ) : (
                     <TableMetadataContainer data={selectedData.data} />
                  )
               ) : (
                  <Card
                     className="border-1 shadow-sm h-100 d-flex align-items-center justify-content-center"
                     style={{ maxHeight: '400px' }}
                  >
                     <p className="m-0 fs-6 fw-medium">Please select a schema or table to edit</p>
                  </Card>
               )}
            </Panel>
         </PanelGroup>
      </div>
   );
};
