import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { Button, Collapse, Form, InputGroup, Stack } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';

import { CredentialPersistence } from '@runql/util';
import LoadingError from '../../../../components/UI/LoadingError';
import LoadingSpinner from '../../../../components/UI/LoadingSpinner';
import { ConnectionAccessType, SSHAuthenticationMethod, SSLMode } from '../../../../entities';
import { ADMIN_ROLES, DBMS } from '../../../../enums';
import { useGetAuthorizedExplorerQuery, useGetDataConnectionQuery } from '../../../../hooks';
import { isDesktop } from '../../../../services/DesktopQueryService';
import { getErrorMessage, IconX } from '../../../../utilities';
import {
   connectionName,
   description,
   host,
   password,
   port,
   username,
   withHostRefinement,
} from '../validators';
import {
   AccessTypeField,
   ConnectionCredentialsFields,
   ConnectionEditType,
   ConnectionParamSelectField,
   CredentialPersistenceField,
   HideDetailsField,
   SSHConnectionFields,
   SSHCredentialFields,
} from './common';
import { ConnectionFields, CredentialFields } from './ConnectionDetailsForm';

const baseCredentialsSchema = z.object({
   rememberCredential: z.boolean().optional(),
   accountName: username,
   password: password,
});
const baseSchema = z.object({
   dbms: z.literal(DBMS.MySQL),
   connectionAccessType: z.nativeEnum(ConnectionAccessType),
   connectionName: connectionName,
   description: description,
   host: host,
   port: port,
   hideDetails: z.boolean().default(false),
   sslCaCert: z.any().optional(),
   sslCaCertId: z.number().optional(),
   useSSL: z.boolean().default(false),
});

const sshCredentialsSchema = baseCredentialsSchema.extend({
   sshUsername: username,
   sshPassword: z.string().optional(),
   sshAuthMethod: z.nativeEnum(SSHAuthenticationMethod).default(SSHAuthenticationMethod.PASSWORD),
   sshKeyFile: z.string().trim().optional(),
});

const sshSchema = baseSchema.extend({
   sshHost: host,
   sshPort: port.optional(),
});

const mysqlDetailSchema = withHostRefinement(
   z.discriminatedUnion('useSSH', [
      baseSchema.merge(baseCredentialsSchema).extend({ useSSH: z.literal(false) }),
      sshSchema.merge(sshCredentialsSchema).extend({ useSSH: z.literal(true) }),
   ])
);

const onlyCredsSchema = z.discriminatedUnion('useSSH', [
   baseCredentialsSchema.extend({ useSSH: z.literal(false) }).passthrough(),
   sshCredentialsSchema.extend({ useSSH: z.literal(true) }).passthrough(),
]);

type MySQLDetailFormData = z.infer<typeof mysqlDetailSchema>;

function MySqlDetailForm({
   defaultValues,
   editType,
   formId,
   isSaving,
   onSaveStateChange,
   onSubmit,
   onlyCreds,
   selectedConnectionId,
}: {
   defaultValues?: Partial<MySQLDetailFormData>;
   editType: ConnectionEditType;
   formId: string;
   isSaving?: boolean;
   onSaveStateChange?: (state: 'clean' | 'dirty') => void;
   onSubmit?: (data: ConnectionFields & CredentialFields) => void;
   onlyCreds?: boolean;
   selectedConnectionId?: number;
}) {
   // register form
   const formMethods = useForm<MySQLDetailFormData>({
      resolver: zodResolver(onlyCreds ? onlyCredsSchema : mysqlDetailSchema),
      mode: 'onTouched',
      defaultValues: defaultValues ?? {
         useSSH: false,
         rememberCredential: true,
      },
   });
   const { handleSubmit, register, formState, watch, setValue, clearErrors, reset } = formMethods;
   // State variables
   const [explorerIsAdmin, setExplorerIsAdmin] = useState(false);

   // Queries
   const selectedConnectionQuery = useGetDataConnectionQuery({
      id: selectedConnectionId,
      getOptions: { includeCredentials: true },
   });
   const authPersonQuery = useGetAuthorizedExplorerQuery();

   // Effects
   useEffect(() => {
      //User is adding credentials, load parent connection data
      if (selectedConnectionQuery.data) {
         const dataCredential = selectedConnectionQuery.data.dataCredentials?.[0];

         const sshAuthMethod =
            dataCredential?.sshAuthMethod === SSHAuthenticationMethod.KEY_FILE &&
            typeof dataCredential?.sshKeyFile === 'string' &&
            dataCredential.sshKeyFile.length > 0
               ? SSHAuthenticationMethod.KEY_FILE
               : SSHAuthenticationMethod.PASSWORD;

         const formData: MySQLDetailFormData = {
            connectionAccessType:
               selectedConnectionQuery.data.connectionAccessType ?? ConnectionAccessType.INDIVIDUAL,
            connectionName: selectedConnectionQuery.data.name ?? '',

            dbms: DBMS.MySQL,
            description: selectedConnectionQuery.data.description ?? '',
            host: selectedConnectionQuery.data.dbHost ?? '',
            port: parseInt(selectedConnectionQuery.data.dbPort ?? ''),
            hideDetails:
               (selectedConnectionQuery.data.hideDetails as unknown as number) === 1 ? true : false,
            useSSL: selectedConnectionQuery.data.sslMode === SSLMode.VALIDATED,
            sslCaCertId: selectedConnectionQuery.data.sslCaCertId ?? undefined,
            useSSH: (selectedConnectionQuery.data.useSSH as unknown as number) === 1 ? true : false,
            sshHost: selectedConnectionQuery.data.sshHost ?? '',
            sshPort: parseInt(selectedConnectionQuery.data.sshPort ?? '22'),
            accountName: dataCredential?.accountName ?? '',
            password: dataCredential?.accountPassword === undefined ? '' : 'CURRENT',
            sshAuthMethod,
            sshUsername: dataCredential?.sshUsername ?? '',
            sshPassword: dataCredential?.sshPassword === undefined ? '' : 'CURRENT',
            sshKeyFile:
               sshAuthMethod === SSHAuthenticationMethod.KEY_FILE
                  ? dataCredential?.sshKeyFile ?? ''
                  : undefined,
            rememberCredential: dataCredential?.credentialPersistence
               ? dataCredential.credentialPersistence === CredentialPersistence.LOCAL_STORAGE
               : true,
         };
         reset(formData);
      }
   }, [reset, selectedConnectionQuery.data]);

   useEffect(() => {
      if (
         authPersonQuery.data?.person.role &&
         ADMIN_ROLES.includes(authPersonQuery.data.person.role)
      ) {
         setExplorerIsAdmin(true);
      } else {
         setExplorerIsAdmin(false);
      }
   }, [authPersonQuery.data?.person.role]);

   useEffect(() => {
      const isDirtyAlt = !!Object.keys(formState.dirtyFields).length;
      if (isDirtyAlt) {
         onSaveStateChange?.('dirty');
      } else {
         onSaveStateChange?.('clean');
      }
   }, [formState, onSaveStateChange]);

   const useSSH = !!watch('useSSH', false);
   const useSSL = !!watch('useSSL', false);
   const sslCaCertId = watch('sslCaCertId', undefined);
   const connectionAccessType = watch('connectionAccessType', ConnectionAccessType.INDIVIDUAL);

   const isSshKeyFilePermitted =
      isDesktop() && connectionAccessType === ConnectionAccessType.INDIVIDUAL;

   // Page functions
   const handleOnSubmit = (data: MySQLDetailFormData) => {
      onSubmit?.(data);
   };

   // Render
   if (authPersonQuery.isLoading || selectedConnectionQuery.isLoading || isSaving)
      return <LoadingSpinner />;
   if (authPersonQuery.isError)
      return <LoadingError message={getErrorMessage(authPersonQuery.error)} />;
   if (selectedConnectionQuery.isError)
      return <LoadingError message={getErrorMessage(selectedConnectionQuery.error)} />;

   const touchedFields = formState.touchedFields;
   const errors = formState.errors;
   return (
      <FormProvider {...formMethods}>
         <Form id={formId} onSubmit={handleSubmit(handleOnSubmit)}>
            <Stack gap={3}>
               {!onlyCreds && (
                  <>
                     <input type="hidden" {...register('dbms')} value={DBMS.MySQL} />
                     <Form.Group>
                        <Form.Label>
                           Connection Name <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                           {...register('connectionName')}
                           autoFocus
                           disabled={editType !== 'connection'}
                           isInvalid={touchedFields.connectionName && !!errors.connectionName}
                           isValid={touchedFields.connectionName && !errors.connectionName}
                           placeholder="Connection Name"
                           required
                        />
                        <Form.Control.Feedback type="invalid">
                           {errors.connectionName?.message}
                        </Form.Control.Feedback>
                     </Form.Group>
                     <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                           {...register('description')}
                           as="textarea"
                           disabled={editType !== 'connection'}
                           isInvalid={touchedFields.description && !!errors.description}
                           isValid={touchedFields.description && !errors.description}
                           placeholder="Description"
                           rows={3}
                        />
                        <Form.Control.Feedback type="invalid">
                           {errors.description?.message}
                        </Form.Control.Feedback>
                     </Form.Group>
                     <ConnectionParamSelectField
                        connectionField="dbHost"
                        dbms={DBMS.MySQL}
                        isRequired
                        label="Host"
                        onExistingSelect={(value) => {
                           setValue('port', parseInt(value.dbPort ?? '3306', 10));
                           setValue('useSSL', value.sslMode === SSLMode.VALIDATED, {
                              shouldDirty: true,
                           });
                           setValue('useSSH', value.useSSH === true, { shouldDirty: true });
                           setValue('sshHost', value.sshHost ?? '', { shouldDirty: true });
                           setValue('sshPort', value.sshPort ? parseInt(value.sshPort) : 22, {
                              shouldDirty: true,
                           });
                        }}
                     />
                     <Form.Group>
                        <Form.Label>
                           Port <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                           {...register('port', { valueAsNumber: true })}
                           defaultValue="3306"
                           disabled={editType !== 'connection'}
                           isInvalid={touchedFields.port && !!errors.port}
                           isValid={touchedFields.port && !errors.port}
                           placeholder="Port"
                           required
                        />
                        <Form.Control.Feedback type="invalid">
                           {errors.port?.message}
                        </Form.Control.Feedback>
                     </Form.Group>
                     <Form.Group>
                        <Form.Label>SSL Mode</Form.Label>
                        <Form.Select
                           disabled={editType !== 'connection'}
                           {...register('useSSL', {
                              setValueAs: (v) => v === true || v === 'true',
                           })}
                        >
                           <option value="false">Use SSL if available</option>
                           <option value="true">Require SSL and validate certificates</option>
                        </Form.Select>
                     </Form.Group>
                     <Collapse in={useSSL}>
                        <Form.Group>
                           <Form.Label>SSL CA Certificate</Form.Label>
                           {sslCaCertId ? (
                              <InputGroup>
                                 <Form.Control placeholder="Uploaded file" readOnly type="text" />
                                 <Button
                                    onClick={() =>
                                       setValue('sslCaCertId', undefined, { shouldDirty: true })
                                    }
                                    variant="secondary"
                                 >
                                    <IconX />
                                 </Button>
                              </InputGroup>
                           ) : (
                              <Form.Control {...register('sslCaCert')} type="file" />
                           )}
                        </Form.Group>
                     </Collapse>
                     <Form.Group>
                        <Form.Label>Use SSH</Form.Label>
                        <Form.Check
                           {...register('useSSH', {
                              onChange: () => {
                                 if (editType !== 'connection') {
                                    // disabling the control when using isTemplate breaks the discriminatedUnion as the value of a disabled checkbox isn't returned
                                    // prevents the checkbox value from changing when a template is loaded
                                    setValue(
                                       'useSSH',
                                       selectedConnectionQuery.data?.useSSH ?? false
                                    );
                                 }
                                 clearErrors('sshHost');
                                 clearErrors('sshPort');
                                 clearErrors('sshUsername');
                                 clearErrors('sshPassword');
                              },
                           })}
                           className="mb-0"
                           type="switch"
                        />
                        <Form.Control.Feedback type="invalid">
                           {errors.useSSH?.message}
                        </Form.Control.Feedback>
                     </Form.Group>
                     <Collapse in={useSSH}>
                        <div>
                           <SSHConnectionFields useSSH={useSSH} />
                        </div>
                     </Collapse>
                     <AccessTypeField
                        disabled={editType !== 'connection'}
                        {...register('connectionAccessType', {
                           setValueAs: (v: string) => parseInt(v) as ConnectionAccessType,
                           onChange(event) {
                              if (
                                 parseInt(event.target.value) === ConnectionAccessType.INDIVIDUAL
                              ) {
                                 setValue('hideDetails', false, { shouldDirty: true });
                              } else {
                                 setValue('sshAuthMethod', SSHAuthenticationMethod.PASSWORD, {
                                    shouldDirty: true,
                                 });
                              }

                              setValue('accountName', '', { shouldDirty: true });
                              setValue('password', '', { shouldDirty: true });
                           },
                        })}
                     />
                     {explorerIsAdmin && (
                        <Collapse in={connectionAccessType === ConnectionAccessType.SHARED}>
                           <div>
                              <HideDetailsField
                                 {...register('hideDetails')}
                                 disabled={editType !== 'connection'}
                              />
                           </div>
                        </Collapse>
                     )}
                  </>
               )}

               <Collapse in={connectionAccessType === ConnectionAccessType.INDIVIDUAL}>
                  <div>
                     <CredentialPersistenceField />
                  </div>
               </Collapse>

               <ConnectionCredentialsFields
                  autoFocusName={onlyCreds}
                  readonly={editType === 'read-only'}
               />

               <Collapse in={useSSH}>
                  <div>
                     <SSHCredentialFields
                        editType={editType}
                        isSshKeyFilePermitted={isSshKeyFilePermitted}
                        useSSH={useSSH}
                     />
                  </div>
               </Collapse>
            </Stack>
         </Form>
      </FormProvider>
   );
}

export default MySqlDetailForm;
