// Init Sentry.
import './instrument';

// Must be imported before React and React DOM
import { scan } from 'react-scan';

import { Provider } from 'inversify-react';
import ReactDOM from 'react-dom/client';
import 'reflect-metadata';
import 'boxicons/css/boxicons.min.css';

import './assets/scss/theme.scss';

import { QueryClient, QueryClientProvider } from 'react-query';

import { ReactQueryDevtools } from 'react-query/devtools';
import App from './App';
import { ThemeProvider } from './components/ThemeContext';
import container from './container';
import reportWebVitals from './reportWebVitals';
import { DesktopProvider } from './hooks/desktop';

import 'react-toastify/dist/ReactToastify.min.css';
import '@react-sigma/core/lib/react-sigma.min.css';

const isReactScanEnabled =
   process.env.NODE_ENV === 'development' &&
   typeof process.env.REACT_APP_REACT_SCAN === 'string' &&
   !!parseInt(process.env.REACT_APP_REACT_SCAN, 10);

const isReactQueryDevToolsEnabled =
   process.env.NODE_ENV === 'development' &&
   typeof process.env.REACT_APP_QUERY_DEVTOOLS === 'string' &&
   !!parseInt(process.env.REACT_APP_QUERY_DEVTOOLS, 10);

scan({ enabled: isReactScanEnabled });

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient();

root.render(
   <>
      <DesktopProvider>
         <ThemeProvider>
            <Provider container={container}>
               <QueryClientProvider client={queryClient}>
                  <App />
                  {isReactQueryDevToolsEnabled && (
                     <ReactQueryDevtools initialIsOpen={false} position="top-left" />
                  )}
               </QueryClientProvider>
            </Provider>
         </ThemeProvider>
      </DesktopProvider>
   </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.info))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
