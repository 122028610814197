import { zodResolver } from '@hookform/resolvers/zod';
import { DBMS } from '@runql/util';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { Form, Stack } from 'react-bootstrap';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { z } from 'zod';

import { Button, LoadingSpinner } from '../../../../components';
import { fetchTableContentQueryKey } from '../../../../components/SchemaTree';
import { TypeSelect } from '../../../../components/TypeSelect';
import LoadingError from '../../../../components/UI/LoadingError';
import {
   getDataConnectionSchemaQueryKey,
   useExploreTab,
   useFetchTableMetaQuery,
   useRunSystemQuery,
   useUpdateSchema,
} from '../../../../hooks';
import {
   buildChangeQueries,
   ClientDataChange,
   handleError,
   IconCheck,
   IconTrash,
   IconUndo,
   MySQLIndexModify,
} from '../../../../utilities';
import { ConfirmChangesModal } from './ConfirmChangesModal';

const tableIndexSchema = z.object({
   id: z.number(),
   tableName: z.string(),
   indexes: z.array(
      z.object({
         runqlId: z.number().optional(), // Optional if not present during creation
         catalogName: z.string().nullable().optional(),
         columnNames: z.string().min(1),
         dataConnectionId: z.number(),
         indexName: z.string().min(1),
         indexUnique: z.boolean(),
         indexType: z.string().optional(),
         isNew: z.boolean().default(false),
         isPrimary: z.boolean().default(false),
         markDeleted: z.boolean().default(false),
         schemaName: z.string().min(1),
         tableName: z.string().min(1),
         type: z.literal('index'),
         visible: z.boolean().optional(),
      })
   ),
});

export type TableIndexFormData = z.infer<typeof tableIndexSchema>;

export function SchemaIndexTab({
   onStatusChange,
   readOnly = false,
}: {
   onStatusChange?: (isDirty: boolean) => void;
   readOnly?: boolean;
}) {
   const [changeCount, setChangeCount] = useState(0);
   const [showConfirmModal, setShowConfirmModal] = useState(false);
   const [changeQueries, setChangeQueries] = useState<string[]>([]);
   const { run, isRunning } = useRunSystemQuery();
   const {
      handleSubmit,
      register,
      control,
      reset,
      formState: { isDirty, dirtyFields, isSubmitted, errors },
   } = useForm<TableIndexFormData>({
      resolver: zodResolver(tableIndexSchema),
      mode: 'onTouched',
      defaultValues: {},
   });

   const { fields, append, update, remove } = useFieldArray({
      control,
      name: 'indexes',
   });

   const previousIsDirty = useRef(isDirty);

   //Context
   const exploreTab = useExploreTab();

   // Queries
   const { tableMetaQuery, invalidate: invalidateTableMeta } = useFetchTableMetaQuery({
      tableSchema: exploreTab?.tableSchema!,
   });
   const dbms = tableMetaQuery.data?.tableCache.dataConnection.dbms;

   const navigatorTableContentQueryKey = fetchTableContentQueryKey({
      type: 'invalidate',
      data: {
         dataConnectionId: exploreTab?.tableSchema?.dataConnectionId!,
         schemaName: exploreTab?.tableSchema?.schemaName!,
         tableName: exploreTab?.tableSchema?.tableName!,
      },
   });

   const dataConnectionSchemaQueryKey = getDataConnectionSchemaQueryKey({
      dataConnectionId: exploreTab?.tableSchema?.dataConnectionId!,
   });

   const { updateSchema, state: updateSchemaState } = useUpdateSchema({
      async onSuccessCallback() {
         await invalidateTableMeta([navigatorTableContentQueryKey, dataConnectionSchemaQueryKey]);
      },
   });

   // Effects
   useEffect(() => {
      if (!tableMetaQuery.data) return;

      // load form data
      const formData: TableIndexFormData = {
         id: tableMetaQuery.data.tableCache.id!,
         tableName: tableMetaQuery.data.tableCache.tableName,
         indexes:
            tableMetaQuery.data.indexes?.map((index) => {
               return {
                  catalogName: index.catalogName,
                  columnNames: JSON.stringify(index?.columnName.split(',')),
                  dataConnectionId: index.dataConnectionId,
                  indexName: index.indexName,
                  indexUnique: !index.indexNonUnique,
                  indexType: index.indexType?.toUpperCase(),
                  isPrimary: index.columnIsPrimaryKey ?? false,
                  schemaName: index.schemaName,
                  tableName: index.tableName,
                  type: 'index',
                  visible: Boolean(index?.visible),
                  runqlId: index.id,
                  markDeleted: false,
                  isNew: false,
               };
            }) ?? [],
      };
      reset(formData);
   }, [reset, tableMetaQuery.data]);

   useEffect(() => {
      if (previousIsDirty.current !== isDirty) {
         previousIsDirty.current = isDirty;
         onStatusChange?.(isDirty);
      }
   }, [isDirty, onStatusChange]);

   function parseStringToArray(str: string): string[] | undefined {
      try {
         const parsed = JSON.parse(str);
         if (Array.isArray(parsed) && parsed.every((item) => typeof item === 'string')) {
            return parsed;
         } else {
            return undefined;
         }
      } catch (e) {
         return undefined;
      }
   }
   // Handlers
   function handleSave(data: TableIndexFormData) {
      if (dirtyFields.indexes === undefined) {
         handleError('no indexes');
         return;
      }
      if (tableMetaQuery.data?.tableCache.tableName === undefined) {
         handleError('Table name is missing');
         return;
      }
      const tableChanges: ClientDataChange = {
         type: 'modifyIndex',
         catalog: tableMetaQuery.data?.tableCache.catalogName,
         table: tableMetaQuery.data?.tableCache.tableName,
         schema: tableMetaQuery.data?.tableCache.schemaName ?? '',
         indexes: dirtyFields.indexes.reduce<Record<string, MySQLIndexModify>>((acc, value, id) => {
            const index = data.indexes[id];
            const originalIndex = tableMetaQuery.data?.indexes?.find(
               (col) => col.id === index.runqlId
            );
            if (!index) {
               handleError('Index not found');
               return acc;
            }
            if (!index.columnNames || index.columnNames.length === 0) {
               handleError('IndexColumns cannot be empty.');
               return acc;
            }
            const indexName = originalIndex?.indexName ?? index.indexName;
            const modify: MySQLIndexModify = {
               columnNames: parseStringToArray(index.columnNames) ?? [],
            };
            let hasChanges = false;
            if (value.indexName && originalIndex?.indexName !== index.indexName) {
               modify.rename = index.indexName;
               hasChanges = true;
            }
            if (value.indexType && originalIndex?.indexType !== index.indexType) {
               modify.indexType = index.indexType;
               hasChanges = true;
            }
            if (
               value.columnNames &&
               JSON.stringify(
                  originalIndex?.columnName?.split(',').map((item) => item.trim()) ?? []
               ) !== JSON.stringify(index.columnNames ?? '')
            ) {
               // Allows for us to compare the two arrays
               // Stringify works well on indexes since usually small number of cols
               // Order also matters
               modify.columnNames = parseStringToArray(index.columnNames) ?? [];
               hasChanges = true;
            }
            if (index.isNew) {
               modify.isNew = true;
               hasChanges = true;
            }
            if (index.markDeleted) {
               modify.delete = true;
               hasChanges = true;
            }
            modify.unique = index.indexUnique;
            modify.primary = index.isPrimary;
            if (hasChanges) {
               acc[indexName] = modify;
            }
            return acc;
         }, {}),
      };

      if (!tableMetaQuery.data?.tableCache.dataConnection.dbms) {
         handleError('No DBMS found');
         return;
      }
      const queries = buildChangeQueries(
         [tableChanges],
         [],
         tableMetaQuery.data?.tableCache.dataConnection.dbms
      );
      setChangeQueries(queries);
      setChangeCount(queries.length);
      setShowConfirmModal(true);
   }

   async function handleConfirmSave(): Promise<void> {
      if (changeQueries.length === 0) return;
      if (exploreTab?.tableSchema?.dataConnectionId === undefined) {
         handleError('No data connection found');
         return;
      }
      try {
         await run({
            query: changeQueries.join('\n'),
            dataConnection: exploreTab.tableSchema.dataConnection,
            exploreTabId: exploreTab.id,
            workspaceId: exploreTab.workspaceId,
            updateSchema: {
               value: true,
               target: {
                  type: 'table',
                  schemaName: exploreTab.tableSchema.schemaName,
                  tableName: exploreTab.tableSchema.tableName,
               },
            },
         });
         await invalidateTableMeta([navigatorTableContentQueryKey, dataConnectionSchemaQueryKey]);
         setShowConfirmModal(false);
      } catch (error) {
         handleError(error);
         return;
      }

      toast.success('Changes saved successfully');
   }

   function handleIndexRemove(index: number): void {
      if (fields[index].isNew) {
         remove(index);
      } else {
         update(index, {
            ...fields[index],
            markDeleted: !fields[index].markDeleted,
         });
      }
   }

   // Render
   if (!exploreTab) return <LoadingError />;
   if (tableMetaQuery.isLoading || updateSchemaState.isLoading) return <LoadingSpinner />;
   if (tableMetaQuery.data === undefined) return <div>No data</div>;

   return (
      <div className="h-100 p-2">
         <ConfirmChangesModal
            changeCount={changeCount}
            onClose={() => {
               setShowConfirmModal(false);
            }}
            onConfirm={handleConfirmSave}
            query={changeQueries}
            running={isRunning}
            show={showConfirmModal}
         />
         <Form className="h-100" onSubmit={handleSubmit(handleSave)}>
            <Stack className="h-100" gap={2}>
               <Stack className="justify-content-end" direction="horizontal" gap={1}>
                  <Button
                     colorScheme="secondary"
                     onClick={async () => {
                        const dataConnectionId = tableMetaQuery.data?.tableCache.dataConnectionId;
                        if (!dataConnectionId) {
                           handleError('No data connection found');
                           return;
                        }

                        if (
                           typeof tableMetaQuery.data?.tableCache.tableName !== 'string' ||
                           typeof tableMetaQuery.data?.tableCache.schemaName !== 'string'
                        ) {
                           // Should never happen.
                           return;
                        }

                        await updateSchema(dataConnectionId, {
                           type: 'table',
                           tableName: tableMetaQuery.data?.tableCache.tableName,
                           schemaName: tableMetaQuery.data?.tableCache.schemaName,
                        });
                     }}
                     size="sm"
                  >
                     Refresh
                  </Button>
                  {!readOnly && (
                     <>
                        <Button
                           colorScheme="secondary"
                           onClick={() => {
                              append({
                                 runqlId: 0,
                                 catalogName: '',
                                 columnNames: '',
                                 dataConnectionId:
                                    tableMetaQuery.data?.indexes[0]?.dataConnectionId ??
                                    exploreTab?.tableSchema?.dataConnectionId ??
                                    0,
                                 indexName: `new_index_${fields.length}`,
                                 indexUnique: true,
                                 indexType: dbms === DBMS.MySQL ? 'BTREE' : undefined,
                                 isNew: true,
                                 isPrimary: false,
                                 markDeleted: false,
                                 schemaName:
                                    tableMetaQuery.data?.indexes[0]?.schemaName ??
                                    exploreTab?.tableSchema?.schemaName ??
                                    '',
                                 tableName:
                                    tableMetaQuery.data?.indexes[0]?.tableName ??
                                    exploreTab?.tableSchema?.tableName ??
                                    '',
                                 type: 'index',
                                 visible: true,
                              });
                           }}
                           size="sm"
                        >
                           Add
                        </Button>
                        <Button
                           colorScheme="secondary"
                           disabled={!isDirty}
                           onClick={() => reset()}
                           size="sm"
                        >
                           Cancel
                        </Button>
                        <Button disabled={!isDirty} size="sm" type="submit">
                           Apply Changes
                        </Button>
                     </>
                  )}
               </Stack>
               {!fields?.length && (
                  <div className="d-flex justify-content-center align-items-center">
                     <div className="p-5 ">No Indexes to display</div>
                  </div>
               )}
               {/* Print column headers */}
               {fields.length > 0 && (
                  <div
                     className="fs-10p"
                     style={{
                        display: 'grid',
                        gridTemplateColumns: `1fr 2fr ${
                           dbms === DBMS.MySQL ? '1fr' : ''
                        } 4rem 4rem min-content`,
                        gap: '0.5rem',
                        alignItems: 'center',
                     }}
                  >
                     <div>Name</div>
                     <div>Columns</div>
                     {dbms === DBMS.MySQL && <div>Type</div>}
                     <div>Unique</div>
                     <div>Primary</div>
                     <div>
                        {/* For sizing */}
                        <Button size="sm" style={{ visibility: 'hidden' }}>
                           <IconTrash size={16} />
                        </Button>
                     </div>
                  </div>
               )}

               <Stack className="flex-grow-1 overflow-auto" gap={2}>
                  {fields.map((index, id) => (
                     <div
                        className={classNames('fs-14p rounded', {
                           'bg-danger text-white': index.markDeleted,
                        })}
                        key={index.id}
                        style={{
                           display: 'grid',
                           gridTemplateColumns: `1fr 2fr ${
                              dbms === DBMS.MySQL ? '1fr' : ''
                           } 4rem 4rem min-content`,
                           gap: '0.5rem',
                           alignItems: 'center',
                        }}
                     >
                        <div>
                           <Form.Control
                              {...register(`indexes.${id}.indexName`)}
                              isInvalid={isSubmitted && !!errors?.indexes?.[id]?.indexName}
                              readOnly={readOnly || index.markDeleted}
                           />
                        </div>
                        <div>
                           <Controller
                              control={control}
                              name={`indexes.${id}.columnNames`}
                              render={({ field }) => (
                                 <TypeSelect
                                    disabled={readOnly || index.markDeleted}
                                    isInvalid={isSubmitted && !!errors?.indexes?.[id]?.columnNames}
                                    onChange={field.onChange}
                                    onSelect={(value) => {
                                       field.onChange(`["${value.value}"]`);
                                    }}
                                    options={
                                       tableMetaQuery?.data?.columns?.map((column) => ({
                                          key: column.columnName,
                                          value: column.columnName,
                                       })) ?? []
                                    }
                                    placeHolder="Add index columns..."
                                    value={field.value}
                                 />
                              )}
                           />
                        </div>
                        {dbms === DBMS.MySQL && (
                           <div>
                              <Form.Control {...register(`indexes.${id}.indexType`)} readOnly />
                           </div>
                        )}
                        <div className="position-relative">
                           {index.isNew ? (
                              <Form.Check
                                 {...register(`indexes.${id}.indexUnique`)}
                                 type="switch"
                              />
                           ) : index.indexUnique ? (
                              <div
                                 style={{
                                    position: 'absolute',
                                    left: '25%',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                 }}
                              >
                                 <IconCheck />
                              </div>
                           ) : (
                              <></>
                           )}
                        </div>
                        <div className="position-relative">
                           {index.isPrimary && (
                              <div
                                 style={{
                                    position: 'absolute',
                                    left: '25%',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                 }}
                              >
                                 <IconCheck />
                              </div>
                           )}
                        </div>
                        <div className="d-flex align-items-center">
                           {!readOnly && (
                              <Button
                                 colorScheme="secondary"
                                 onClick={() => handleIndexRemove(id)}
                                 size="sm"
                                 variant="link"
                              >
                                 {index.markDeleted ? (
                                    <IconUndo size={16} />
                                 ) : (
                                    <IconTrash size={16} />
                                 )}
                              </Button>
                           )}
                        </div>
                     </div>
                  ))}
                  {fields.length === 0 && (
                     <div className="align-self-center flex">
                        No indexes to display. Click Add to create a new index.
                     </div>
                  )}
               </Stack>
            </Stack>
         </Form>
      </div>
   );
}
