import { useCallback, useEffect, useRef } from 'react';

export const useStableCallback = <Args extends unknown[], R>(callback?: (...args: Args) => R) => {
   const callbackRef = useRef(callback);

   useEffect(() => {
      callbackRef.current = callback;
   }, [callback]);

   const ret = useCallback((...args: Args): R => {
      return callbackRef.current!(...args);
   }, []);
   return callbackRef.current ? ret : undefined;
};
