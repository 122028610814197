import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Form, Stack } from 'react-bootstrap';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { z } from 'zod';

import { SchemaItemType } from '@runql/util';
import { Button, LoadingSpinner, SelectOption, TypeSelect } from '../../../../components';
import { fetchTableContentQueryKey } from '../../../../components/SchemaTree';
import LoadingError from '../../../../components/UI/LoadingError';
import { RelationAction } from '../../../../entities';
import { CATALOG_DBMS, DBMS } from '../../../../enums';
import {
   getDataConnectionSchemaQueryKey,
   useExploreTab,
   useFetchTableMetaQuery,
   useGetDataConnectionSchema,
   useRunSystemQuery,
   useUpdateSchema,
} from '../../../../hooks';
import {
   arraysEqual,
   buildChangeQueries,
   ClientDataChange,
   handleError,
   IconTrash,
   IconUndo,
   RelationModify,
} from '../../../../utilities';
import { ConfirmChangesModal } from './ConfirmChangesModal';

const tableRelationsSchema = z.object({
   id: z.number(),
   table: z.string(),
   relations: z.array(
      z.object({
         runqlId: z.number().optional(),
         relationColumnNames: z
            .string()
            .array()
            .refine((value) => value.length > 0),
         relationName: z.string().min(1),
         schemaName: z.string().optional(),
         relationTableName: z.string(),
         relationReferencedColumnNames: z
            .string()
            .array()
            .refine((value) => value.length > 0),
         relationReferencedTableName: z.string().min(1),
         relationDeleteRule: z.nativeEnum(RelationAction).optional(),
         relationUpdateRule: z.nativeEnum(RelationAction).optional(),
         markDeleted: z.boolean().default(false),
         isNew: z.boolean().default(false),
      })
   ),
});

function convertStrToRelationAction(action: string): RelationAction {
   if (action === 'NO ACTION' || action === 'NO_ACTION') {
      return RelationAction.NO_ACTION;
   } else if (action === 'SET NULL') {
      return RelationAction.SET_NULL;
   } else if (action === 'SET DEFAULT') {
      return RelationAction.SET_DEFAULT;
   } else if (action === 'CASCADE') {
      return RelationAction.CASCADE;
   } else if (action === 'RESTRICT') {
      return RelationAction.RESTRICT;
   } else {
      return RelationAction.UNKNOWN;
   }
}

function getActionOptions(dbms: DBMS | undefined): SelectOption[] {
   const actions = [
      { key: RelationAction.NO_ACTION, value: RelationAction.NO_ACTION },
      { key: RelationAction.CASCADE, value: RelationAction.CASCADE },
      { key: RelationAction.SET_NULL, value: RelationAction.SET_NULL },
      { key: RelationAction.SET_DEFAULT, value: RelationAction.SET_DEFAULT },
   ];
   if (dbms === DBMS.MySQL) {
      actions.push({ key: RelationAction.RESTRICT, value: RelationAction.RESTRICT });
   }
   return actions;
}

export type TableRelationsFormData = z.infer<typeof tableRelationsSchema>;

export function SchemaRelationsTab({
   onStatusChange,
   readOnly = false,
}: {
   onStatusChange?: (isDirty: boolean) => void;
   readOnly?: boolean;
}) {
   const [changeCount, setChangeCount] = useState(0);
   const [showConfirmModal, setShowConfirmModal] = useState(false);
   const [changeQueries, setChangeQueries] = useState<string[]>([]);
   const { run, isRunning } = useRunSystemQuery();
   const {
      handleSubmit,
      register,
      control,
      reset,
      formState: { isDirty, dirtyFields, defaultValues, isSubmitted, errors },
      setValue,
      watch,
   } = useForm<TableRelationsFormData>({
      resolver: zodResolver(tableRelationsSchema),
      mode: 'onTouched',
      defaultValues: {},
   });

   const { fields, append, update, remove } = useFieldArray({
      control,
      name: 'relations',
   });

   const previousIsDirty = useRef(isDirty);
   const relationWatch = watch('relations');

   //Context
   const exploreTab = useExploreTab();

   // Queries
   const { tableMetaQuery, invalidate: invalidateTableMeta } = useFetchTableMetaQuery({
      tableSchema: exploreTab?.tableSchema!,
   });
   const connectionSchema = useGetDataConnectionSchema(exploreTab?.tableSchema?.dataConnectionId);
   const dbms = tableMetaQuery.data?.tableCache.dataConnection.dbms;
   const catalogEnabled = dbms && CATALOG_DBMS.includes(dbms);

   const availableSchemas: SelectOption[] = useMemo(() => {
      if (!catalogEnabled) return [];
      return (
         connectionSchema.data
            ?.filter(
               (schema) =>
                  schema.catalogName === tableMetaQuery.data?.tableCache.catalogName &&
                  schema.type === SchemaItemType.SCHEMA
            )
            .map((schema) => ({
               key: schema.schemaName,
               value: schema.schemaName,
            })) || []
      );
   }, [catalogEnabled, connectionSchema.data, tableMetaQuery.data?.tableCache.catalogName]);

   const navigatorTableContentQueryKey = fetchTableContentQueryKey({
      type: 'invalidate',
      data: {
         dataConnectionId: exploreTab?.tableSchema?.dataConnectionId!,
         schemaName: exploreTab?.tableSchema?.schemaName!,
         tableName: exploreTab?.tableSchema?.tableName!,
      },
   });

   const dataConnectionSchemaQueryKey = getDataConnectionSchemaQueryKey({
      dataConnectionId: exploreTab?.tableSchema?.dataConnectionId!,
   });

   const { updateSchema, state: updateSchemaState } = useUpdateSchema({
      onSuccessCallback() {
         invalidateTableMeta([navigatorTableContentQueryKey, dataConnectionSchemaQueryKey]);
      },
   });

   const getSchemaTables = useCallback(
      (schema?: string) => {
         if (!dbms) return [];
         if (catalogEnabled) {
            if (!schema) return [];
            return (
               connectionSchema.data
                  ?.filter(
                     (table) =>
                        table.catalogName === tableMetaQuery.data?.tableCache.catalogName &&
                        table.schemaName === schema &&
                        table.type === SchemaItemType.TABLE
                  )
                  .map((table) => ({
                     key: table.tableName,
                     value: table.tableName,
                  })) || []
            );
         } else {
            return (
               connectionSchema.data
                  ?.filter(
                     (table) =>
                        table.schemaName === tableMetaQuery.data?.tableCache.schemaName &&
                        table.type === SchemaItemType.TABLE
                  )
                  .map((table) => ({
                     key: table.tableName,
                     value: table.tableName,
                  })) || []
            );
         }
      },
      [dbms, catalogEnabled, connectionSchema.data, tableMetaQuery.data]
   );

   const getTableColumns = useCallback(
      (table: string | undefined, schema?: string) => {
         if (!dbms || !table) return [];
         if (catalogEnabled) {
            if (!schema) return [];
            return (
               connectionSchema.data
                  ?.filter(
                     (column) =>
                        column.catalogName === tableMetaQuery.data?.tableCache.catalogName &&
                        column.schemaName === schema &&
                        column.tableName === table &&
                        column.type === SchemaItemType.COLUMN
                  )
                  .map((column) => ({
                     key: column.columnName,
                     value: column.columnName,
                  })) || []
            );
         } else {
            return (
               connectionSchema.data
                  ?.filter(
                     (column) =>
                        column.schemaName === tableMetaQuery.data?.tableCache.schemaName &&
                        column.tableName === table &&
                        column.type === SchemaItemType.COLUMN
                  )
                  .map((column) => ({
                     key: column.columnName,
                     value: column.columnName,
                  })) || []
            );
         }
      },
      [dbms, catalogEnabled, connectionSchema.data, tableMetaQuery.data]
   );

   // Effects
   useEffect(() => {
      if (!tableMetaQuery.data) return;

      // load form data
      const formData: TableRelationsFormData = {
         id: tableMetaQuery.data.tableCache.id!,
         table: tableMetaQuery.data.tableCache.tableName,
         relations:
            tableMetaQuery?.data?.relations?.map((relation) => ({
               isNew: false,
               markDeleted: false,
               relationColumnNames: relation.relationColumnNames ?? [],
               relationDeleteRule: convertStrToRelationAction(relation.relationDeleteRule || ''),
               relationReferencedColumnNames: relation.relationReferencedColumnNames ?? [],
               relationReferencedTableName: relation.relationReferencedTableName,
               relationName: relation.relationName ?? '',
               schemaName: relation.schemaName,
               relationTableName: relation.tableName,
               relationUpdateRule: convertStrToRelationAction(relation.relationUpdateRule || ''),
               runqlId: relation.id,
            })) ?? [],
      };
      reset(formData);
   }, [reset, tableMetaQuery.data]);

   useEffect(() => {
      if (previousIsDirty.current !== isDirty) {
         previousIsDirty.current = isDirty;
         onStatusChange?.(isDirty);
      }
   }, [isDirty, onStatusChange]);

   // Handlers
   function handleSave(data: TableRelationsFormData) {
      if (dirtyFields.relations === undefined) return;
      if (defaultValues?.table === undefined) {
         handleError('Table name is missing');
         return;
      }
      const tableChanges: ClientDataChange = {
         type: 'modifyRelation',
         table: defaultValues.table,
         schema: tableMetaQuery.data?.tableCache.schemaName ?? '',
         catalog: tableMetaQuery.data?.tableCache.catalogName,
         relations: dirtyFields.relations.reduce<Record<string, RelationModify>>(
            (acc, value, index) => {
               const relation = data.relations[index];
               const originalRelation = tableMetaQuery.data?.relations?.find(
                  (rel) => rel.relationName === relation.relationName
               );
               const relationName = originalRelation?.relationName ?? relation.relationName ?? '';
               if (!relation) {
                  handleError('Column not found');
                  return acc;
               }
               const modify: RelationModify = {};
               let hasChanges = false;

               if (value.relationName && originalRelation?.relationName !== relation.relationName) {
                  modify.name = relation.relationName;
                  hasChanges = true;
               }
               if (
                  value.relationColumnNames &&
                  !arraysEqual(
                     originalRelation?.relationColumnNames ?? [],
                     relation.relationColumnNames
                  )
               ) {
                  modify.columns = relation.relationColumnNames;
                  hasChanges = true;
               }
               if (
                  value.relationReferencedTableName &&
                  originalRelation?.relationReferencedTableName !==
                     relation.relationReferencedTableName
               ) {
                  modify.fkTable = relation.relationReferencedTableName;
                  hasChanges = true;
               }
               if (
                  value.relationReferencedColumnNames &&
                  !arraysEqual(
                     originalRelation?.relationReferencedColumnNames ?? [],
                     relation.relationReferencedColumnNames ?? []
                  )
               ) {
                  modify.fkColumns = relation.relationReferencedColumnNames;
                  hasChanges = true;
               }
               if (
                  value.relationUpdateRule &&
                  originalRelation?.relationUpdateRule !== RelationAction.UNKNOWN
               ) {
                  modify.onUpdate = relation.relationUpdateRule;
                  hasChanges = true;
               }
               if (
                  value.relationDeleteRule &&
                  originalRelation?.relationDeleteRule !== RelationAction.UNKNOWN
               ) {
                  modify.onDelete = relation.relationDeleteRule;
                  hasChanges = true;
               }
               if (relation.isNew) {
                  modify.isNew = true;
                  hasChanges = true;
               }
               if (relation.markDeleted) {
                  modify.drop = true;
                  hasChanges = true;
               }
               if (hasChanges) {
                  acc[relationName] = modify;
               }
               return acc;
            },
            {}
         ),
      };

      if (!tableMetaQuery.data?.tableCache.dataConnection.dbms) {
         handleError('No DBMS found');
         return;
      }
      const queries = buildChangeQueries(
         [tableChanges],
         tableMetaQuery.data?.relations ?? [],
         tableMetaQuery.data?.tableCache.dataConnection.dbms
      );
      setChangeQueries(queries);
      setChangeCount(queries.length);
      setShowConfirmModal(true);
   }

   async function handleConfirmSave(): Promise<void> {
      if (changeQueries.length === 0) return;
      if (exploreTab?.tableSchema?.dataConnectionId === undefined) {
         handleError('No data connection found');
         return;
      }
      try {
         const queryReturn = await run({
            query: changeQueries.join('\n'),
            dataConnection: exploreTab.tableSchema.dataConnection,
            exploreTabId: exploreTab.id,
            workspaceId: exploreTab.workspaceId,
            updateSchema: {
               value: true,
               target: {
                  type: 'table',
                  schemaName: exploreTab.tableSchema.schemaName,
                  tableName: exploreTab.tableSchema.tableName,
               },
            },
         });
         if (queryReturn.error) {
            handleError(queryReturn.error);
            return;
         }
         await invalidateTableMeta([navigatorTableContentQueryKey, dataConnectionSchemaQueryKey]);
         setShowConfirmModal(false);
      } catch (error) {
         handleError(error);
         return;
      }

      toast.success('Changes saved successfully');
   }

   function handleColumnRemove(index: number): void {
      if (fields[index].isNew) {
         remove(index);
      } else {
         update(index, {
            ...fields[index],
            markDeleted: !fields[index].markDeleted,
         });
      }
   }

   function handleSchemaChanged(index: number): void {
      setValue(`relations.${index}.relationReferencedTableName`, '');
      setValue(`relations.${index}.relationReferencedColumnNames`, []);
   }

   function handleTableChanged(index: number): void {
      setValue(`relations.${index}.relationReferencedColumnNames`, []);
   }

   // Render
   if (!exploreTab) return <LoadingError />;
   if (tableMetaQuery.isLoading || updateSchemaState.isLoading) return <LoadingSpinner />;
   if (tableMetaQuery.data === undefined) return <div>No data</div>;

   return (
      <div className="h-100 p-2">
         <ConfirmChangesModal
            changeCount={changeCount}
            onClose={() => {
               setShowConfirmModal(false);
            }}
            onConfirm={handleConfirmSave}
            query={changeQueries}
            running={isRunning}
            show={showConfirmModal}
         />
         <Form className="h-100" onSubmit={handleSubmit(handleSave)}>
            <Stack className="h-100" gap={2}>
               <Stack className="justify-content-end" direction="horizontal" gap={1}>
                  <Button
                     colorScheme="secondary"
                     onClick={async () => {
                        const dataConnectionId = tableMetaQuery.data?.tableCache.dataConnectionId;
                        if (!dataConnectionId) {
                           handleError('No data connection found');
                           return;
                        }

                        if (
                           typeof tableMetaQuery.data?.tableCache.tableName !== 'string' ||
                           typeof tableMetaQuery.data?.tableCache.schemaName !== 'string'
                        ) {
                           // Should never happen.
                           return;
                        }

                        await updateSchema(dataConnectionId, {
                           type: 'table',
                           tableName: tableMetaQuery.data?.tableCache.tableName,
                           schemaName: tableMetaQuery.data?.tableCache.schemaName,
                        });
                     }}
                     size="sm"
                  >
                     Refresh
                  </Button>
                  {!readOnly && (
                     <>
                        <Button
                           colorScheme="secondary"
                           onClick={() => {
                              append({
                                 isNew: true,
                                 relationColumnNames: [],
                                 markDeleted: false,
                                 relationDeleteRule: RelationAction.NO_ACTION,
                                 relationReferencedTableName: '',
                                 relationName:
                                    tableMetaQuery.data?.tableCache.tableName +
                                    `_${(tableMetaQuery.data?.relations?.length || 0) + 1}`,
                                 schemaName: '',
                                 relationTableName: '',
                                 relationUpdateRule: RelationAction.NO_ACTION,
                                 relationReferencedColumnNames: [],
                              });
                           }}
                           size="sm"
                        >
                           Add
                        </Button>
                        <Button
                           colorScheme="secondary"
                           disabled={!isDirty}
                           onClick={() => {
                              reset();
                           }}
                           size="sm"
                        >
                           Cancel
                        </Button>
                        <Button disabled={!isDirty} size="sm" type="submit">
                           Apply Changes
                        </Button>
                     </>
                  )}
               </Stack>
               {!fields?.length && (
                  <div className="d-flex justify-content-center align-items-center">
                     <div className="p-5">No Relations to display</div>
                  </div>
               )}
               {fields.length > 0 && (
                  <div className="fs-10p">
                     <div
                        style={{
                           display: 'grid',
                           gridTemplateColumns: `repeat(${
                              5 + (catalogEnabled ? 1 : 0) + (dbms !== DBMS.Redshift ? 1 : 0)
                           }, 1fr) min-content`,
                           gap: '0.5rem',
                           alignItems: 'center',
                        }}
                     >
                        <div>Name</div>
                        <div>Column</div>
                        {catalogEnabled && <div>Schema</div>}
                        <div>FK Table</div>
                        <div>FK Column</div>
                        {dbms !== DBMS.Redshift && (
                           <>
                              {dbms !== DBMS.Oracle && <div>On Update</div>}
                              <div>On Delete</div>
                           </>
                        )}
                        <div>
                           {/* For sizing */}
                           <Button size="sm" style={{ visibility: 'hidden' }}>
                              <IconTrash size={16} />
                           </Button>
                        </div>
                     </div>
                  </div>
               )}

               <div className="flex-grow-1 overflow-auto">
                  {fields.map((relation, index) => {
                     const selectedSchema = relationWatch?.[index]?.schemaName;
                     const selectedTable = relationWatch?.[index]?.relationReferencedTableName;

                     return (
                        <div
                           className={`fs-10p ${
                              relation.markDeleted ? 'bg-danger text-white' : ''
                           }`}
                           key={index}
                        >
                           <div
                              style={{
                                 display: 'grid',
                                 gridTemplateColumns: `repeat(${
                                    5 + (catalogEnabled ? 1 : 0) + (dbms !== DBMS.Redshift ? 1 : 0)
                                 }, 1fr) min-content`,
                                 gap: '0.5rem',
                                 alignItems: 'center',
                              }}
                           >
                              <div>
                                 <Form.Control
                                    {...register(`relations.${index}.relationName`)}
                                    disabled={!relation.isNew}
                                    isInvalid={
                                       isSubmitted && !!errors?.relations?.[index]?.relationName
                                    }
                                    isValid={
                                       isSubmitted && !errors?.relations?.[index]?.relationName
                                    }
                                    readOnly={readOnly || relation.markDeleted}
                                 />
                              </div>
                              <div>
                                 <Controller
                                    control={control}
                                    name={`relations.${index}.relationColumnNames`}
                                    render={({ field }) => (
                                       <TypeSelect
                                          allowCustom
                                          disabled={!relation.isNew}
                                          isInvalid={
                                             isSubmitted &&
                                             !!errors?.relations?.[index]?.relationColumnNames
                                          }
                                          onChange={(val) => field.onChange([val.value])}
                                          options={
                                             tableMetaQuery?.data?.columns?.map((column) => ({
                                                key: column.columnName,
                                                value: column.columnName,
                                             })) || []
                                          }
                                          placeHolder="Choose Column..."
                                          value={field.value
                                             .map((option) => `${option}`)
                                             .join(', ')}
                                       />
                                    )}
                                 />
                              </div>
                              {catalogEnabled && (
                                 <div>
                                    <Controller
                                       control={control}
                                       name={`relations.${index}.schemaName`}
                                       render={({ field }) => (
                                          <TypeSelect
                                             allowCustom
                                             disabled={!relation.isNew}
                                             isInvalid={
                                                isSubmitted &&
                                                !!errors?.relations?.[index]?.schemaName
                                             }
                                             onChange={(value) => {
                                                handleSchemaChanged(index);
                                                field.onChange(value.value);
                                             }}
                                             options={availableSchemas}
                                             placeHolder="Choose Schema..."
                                             value={field.value}
                                          />
                                       )}
                                    />
                                 </div>
                              )}
                              <div>
                                 <Controller
                                    control={control}
                                    name={`relations.${index}.relationReferencedTableName`}
                                    render={({ field }) => (
                                       <TypeSelect
                                          allowCustom
                                          disabled={!relation.isNew}
                                          isInvalid={
                                             isSubmitted &&
                                             !!errors?.relations?.[index]
                                                ?.relationReferencedTableName
                                          }
                                          onChange={(value) => {
                                             handleTableChanged(index);
                                             field.onChange(value.value);
                                          }}
                                          options={getSchemaTables(selectedSchema)}
                                          placeHolder="Choose Table..."
                                          value={field.value}
                                       />
                                    )}
                                 />
                              </div>
                              <div>
                                 <Controller
                                    control={control}
                                    name={`relations.${index}.relationReferencedColumnNames`}
                                    render={({ field }) => (
                                       <TypeSelect
                                          allowCustom
                                          disabled={!relation.isNew}
                                          isInvalid={
                                             isSubmitted &&
                                             !!errors?.relations?.[index]
                                                ?.relationReferencedColumnNames
                                          }
                                          onChange={(val) => field.onChange([val.value])}
                                          options={getTableColumns(selectedTable, selectedSchema)}
                                          placeHolder="Choose Column..."
                                          value={field.value
                                             ?.map((option) => `${option}`)
                                             .join(', ')}
                                       />
                                    )}
                                 />
                              </div>
                              {dbms !== DBMS.Redshift && (
                                 <>
                                    {dbms !== DBMS.Oracle && (
                                       <div>
                                          <Controller
                                             control={control}
                                             name={`relations.${index}.relationUpdateRule`}
                                             render={({ field }) => (
                                                <TypeSelect
                                                   disabled={!relation.isNew}
                                                   isInvalid={
                                                      isSubmitted &&
                                                      !!errors?.relations?.[index]
                                                         ?.relationUpdateRule
                                                   }
                                                   onChange={(value) => field.onChange(value.value)}
                                                   options={getActionOptions(dbms)}
                                                   value={field.value}
                                                />
                                             )}
                                          />
                                       </div>
                                    )}
                                    <div>
                                       <Controller
                                          control={control}
                                          name={`relations.${index}.relationDeleteRule`}
                                          render={({ field }) => (
                                             <TypeSelect
                                                disabled={!relation.isNew}
                                                isInvalid={
                                                   isSubmitted &&
                                                   !!errors?.relations?.[index]?.relationDeleteRule
                                                }
                                                onChange={(value) => field.onChange(value.value)}
                                                options={getActionOptions(dbms)}
                                                value={field.value}
                                             />
                                          )}
                                       />
                                    </div>
                                 </>
                              )}
                              <div>
                                 {!readOnly && (
                                    <Button
                                       colorScheme="secondary"
                                       onClick={() => {
                                          handleColumnRemove(index);
                                       }}
                                       size="sm"
                                       variant="link"
                                    >
                                       {relation.markDeleted ? (
                                          <IconUndo size={16} />
                                       ) : (
                                          <IconTrash size={16} />
                                       )}
                                    </Button>
                                 )}
                              </div>
                           </div>
                        </div>
                     );
                  })}
               </div>
            </Stack>
         </Form>
      </div>
   );
}
