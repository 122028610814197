import { inject, injectable } from 'inversify';
import { CollaboratorRole, WorkspaceCollaborator } from '../entities';
import { TYPES } from '../types';
import { ApiService } from './ApiService';
import { ApiServiceInterface } from './ApiServiceInterface';

const path = '/v1/workspaceCollaborator';

export interface GetOptionsWorkspaceCollab {}

export interface ListOptionsWorkspaceCollab {
   connectionDetails?: boolean;
   personDetails?: boolean;
   workspaceDetails?: boolean;
   workspaceId?: number;
}

export interface WorkspaceCollabBulkPayload {
   collaborators: {
      action: 'add' | 'remove' | 'invite' | 'update';
      email?: string;
      id?: number;
      role?: CollaboratorRole;
   }[];
   workspaceId: number;
}

@injectable()
export class WorkspaceCollaboratorService implements ApiServiceInterface<WorkspaceCollaborator> {
   private apiService: ApiService;

   constructor(@inject(TYPES.apiService) apiService: ApiService) {
      this.apiService = apiService;
   }

   async delete(id: string | number): Promise<null> {
      return await this.apiService.delete(`${path}/${id}`);
   }

   async get(
      id: string | number,
      params?: Record<string, string>
   ): Promise<WorkspaceCollaborator | undefined> {
      const result = await this.apiService.get<WorkspaceCollaborator>(`${path}/${id}`, params);
      if (!result) {
         return undefined;
      }

      const data = result as WorkspaceCollaborator;
      this.cast(data);
      return data;
   }

   async getByWorkspace(
      workspaceId: string | number,
      params?: Record<string, string>
   ): Promise<WorkspaceCollaborator | undefined> {
      const result = await this.apiService.get<WorkspaceCollaborator>(
         `${path}/byWorkspace/${workspaceId}`,
         params
      );
      if (!result) {
         return undefined;
      }

      return result;
   }

   async list(params: Record<string, string>): Promise<WorkspaceCollaborator[]> {
      const result = await this.apiService.get<WorkspaceCollaborator[]>(path, params);
      if (!result) {
         return [];
      }

      const data = result as WorkspaceCollaborator[];
      data.forEach((item) => this.cast(item));
      return data;
   }

   async listOptions(options?: ListOptionsWorkspaceCollab): Promise<WorkspaceCollaborator[]> {
      const params: Record<string, string> = {};
      if (options !== undefined) {
         for (const k in options) {
            const v = options[k as keyof typeof options];
            if (v) {
               params[k] = v.toString();
            }
         }
      }
      return this.list(params);
   }

   async patch(
      id: string | number,
      body: WorkspaceCollaborator,
      params?: Record<string, string>
   ): Promise<WorkspaceCollaborator | undefined> {
      const result = await this.apiService.patch<WorkspaceCollaborator>(
         `${path}/${id}`,
         body,
         params
      );
      if (!result) {
         return undefined;
      }

      const data = result as WorkspaceCollaborator;
      this.cast(data);
      return data;
   }

   /** @deprecated This method should not be used */
   async patchFromEntity(entity: WorkspaceCollaborator) {
      if (entity.id === undefined) {
         return undefined;
      }

      return this.patch(entity.id, entity);
   }

   async post(
      newWorkspaceCollab: Partial<WorkspaceCollaborator>
   ): Promise<WorkspaceCollaborator | undefined> {
      const result = await this.apiService.post<WorkspaceCollaborator>(
         `${path}`,
         newWorkspaceCollab
      );
      if (!result) {
         return undefined;
      }

      const data = result as WorkspaceCollaborator;
      this.cast(data);
      return data;
   }

   async put(
      _body: WorkspaceCollaborator,
      _params?: Record<string, string> | undefined
   ): Promise<WorkspaceCollaborator | undefined> {
      throw new Error('Method not implemented.');
   }

   async bulkUpdate(body: WorkspaceCollabBulkPayload) {
      try {
         const result = await this.apiService.post(`${path}/bulk-update`, body);
         if (!result) {
            return undefined;
         }
         return result;
      } catch (err) {
         return { error: true };
      }
   }

   cast(item: WorkspaceCollaborator): void {
      if (!item) {
         return;
      }
   }
}
