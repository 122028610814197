import { useInjection } from 'inversify-react';
import { TYPES } from '../../types';
import {
   GetOptionsOrgRolePermission,
   ListOptionsOrgRolePermission,
   OrgRolePermissionService,
} from '../../services';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { OrgRolePermission } from '../../entities';
import { QueryKey, QueryKeyType } from '../../enums';
import { handleError } from '../../utilities';

const useOrgRolePermissionService = () => {
   return useInjection<OrgRolePermissionService>(TYPES.orgRolePermissionService);
};

export const useListRolePermissions = ({
   callbacks,
   options,
}: {
   callbacks?: {
      onError?: (err: unknown) => void;
      onSuccess?: (data: OrgRolePermission[]) => void;
   };
   options?: ListOptionsOrgRolePermission;
} = {}) => {
   const orgRolePermissionService = useOrgRolePermissionService();
   return useQuery<OrgRolePermission[]>(
      getOrgRolePermissionQueryKey({ options, type: QueryKeyType.LIST }),
      () => orgRolePermissionService.listOptions(options),
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false,
         refetchOnMount: true,
         retry: false,
         onSuccess: callbacks?.onSuccess,
         onError: callbacks?.onError,
      }
   );
};

export const useUpdateOrgRolePermission = (callbacks?: {
   onError?: (err: unknown) => void;
   onSuccess?: (
      data: OrgRolePermission | undefined,
      variables: OrgRolePermission,
      context: unknown
   ) => void;
}) => {
   const queryClient = useQueryClient();
   const service = useOrgRolePermissionService();

   return useMutation({
      mutationFn: async (data: OrgRolePermission) => {
         return service.patch(data.id ?? 'UNKNOWN', data);
      },
      async onSuccess(data, variables, context) {
         if (callbacks?.onSuccess) callbacks?.onSuccess(data, variables, context);

         // invalidate queries
         if (data?.id) {
            await queryClient.invalidateQueries(
               getOrgRolePermissionQueryKey({ type: QueryKeyType.LIST })
            );
         }
      },
      onError: callbacks?.onError || handleError,
   });
};

export function getOrgRolePermissionQueryKey(keyParams: {
   id?: number;
   options?: ListOptionsOrgRolePermission | GetOptionsOrgRolePermission;
   type: QueryKeyType;
}): any[] {
   const queryKey: any[] = [QueryKey.Workspace, keyParams.type];
   if (keyParams.id) queryKey.push(keyParams.id);
   if (keyParams.options) queryKey.push(keyParams.options);

   return queryKey;
}
