import { FormEvent, useEffect, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { Link, Navigate, useParams, useSearchParams } from 'react-router-dom';
import { GoogleLoginButton, MicrosoftLoginButton } from 'react-social-login-buttons';

import { Button, DownloadLinks } from '../components';
import PublicPage from '../components/PublicPage';
import {
   useAcceptInviteMutator,
   useCheckInviteLinkQuery,
   useExtension,
   useGetAuthorizedExplorerQuery,
} from '../hooks';
import { isDesktop } from '../services/DesktopQueryService';
import { handleError, post } from '../utilities';
import { signInWithGoogle, signInWithMS } from '../utilities/socialLogin';

export const SignUpPage = (): React.JSX.Element => {
   const params = useParams();
   const [emailSent, setEmailSent] = useState(false);
   const [formProcessing, setFormProcessing] = useState(false);
   const [searchParams] = useSearchParams();
   const extension = useExtension();
   const [error, setError] = useState<string>();
   const acceptInviteMutator = useAcceptInviteMutator();

   const invite = params.link;
   const inviteData = useCheckInviteLinkQuery(invite, {
      onSuccess(data) {
         if (data && !data.ok) {
            setError(data.message ?? 'Unknown Error');
         }
      },
   });

   // For some reason, s3 redirection appends a trailing slash
   const code = searchParams.get('org')?.replace('/', '');
   useEffect(() => {
      if (!code) return;
      window.localStorage.setItem('code', code);
   }, [code]);

   // User is signed in when accepting an invitation even before setting the password
   const { data: authData, refetch: refetchAuth } = useGetAuthorizedExplorerQuery();
   const [signedUp, setSignedUp] = useState(authData?.authenticated === true && !invite);

   const emailSignup = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
      event.preventDefault();
      if (!event.currentTarget.checkValidity()) {
         event.stopPropagation();
         return;
      }
      setFormProcessing(true);
      try {
         const data = new FormData(event.currentTarget);
         if (invite) {
            const firstName = data.get('firstName') as string;
            const lastName = data.get('lastName') as string;
            const password = data.get('password') as string;
            if (!firstName || !lastName || !password) return;
            await acceptInviteMutator.mutateAsync({
               firstName,
               lastName,
               link: invite,
               password,
            });
            await refetchAuth();
            setSignedUp(true);
         } else {
            const body = {
               ...Object.fromEntries(data.entries()),
               code,
            };
            await post('/auth/makelink', { body });
            setEmailSent(true);
         }
      } catch (e) {
         handleError(e);
      }
      setFormProcessing(false);
   };

   if (error) {
      return (
         <PublicPage>
            <Alert variant="danger">{error}</Alert>
         </PublicPage>
      );
   }

   if (emailSent) {
      return (
         <PublicPage>
            <div className="card pt-4 pb-1 ps-4 pe-4">
               <div className="mb-3">
                  <h4 className="text-center mb-1">Check Your Inbox</h4>
                  <div className="fs-12p text-center mb-1">
                     A confirmation link has been dispatched to your email address. Keep your eyes
                     peeled for the message and click the confirmation link for your adventure to
                     begin!
                  </div>
                  <div className="fs-12p text-center">
                     <Link to="/">Return to Sign In</Link>
                  </div>
               </div>
            </div>
         </PublicPage>
      );
   }

   if (signedUp) {
      return (
         <Navigate
            replace
            state={{
               fromSignupSuccess: true,
               targetPath: '/',
            }}
            to="/appSelect"
         />
      );
   }

   return (
      <PublicPage>
         <div className="row gap-4">
            <div className="col-sm">
               <div className="card pt-4 pb-1 ps-4 pe-4" style={{ maxWidth: '500px' }}>
                  <div className="mb-3">
                     <h4 className="text-center mb-1 fw-500">
                        {invite ? (
                           <>Finish Setting Up Your Account</>
                        ) : isDesktop() ? (
                           <>Create Account</>
                        ) : (
                           <>
                              Start with the runQL <span className="fw-800">Cloud</span> IDE
                           </>
                        )}
                     </h4>
                  </div>
                  <div className="row mb-3">
                     <div className="col-12">
                        <GoogleLoginButton
                           align="center"
                           className="button-social-signin"
                           onClick={() => signInWithGoogle(!!extension)}
                        >
                           <div className="text-center fw-normal">Sign in with Google</div>
                        </GoogleLoginButton>
                     </div>
                     <div className="col-12">
                        <MicrosoftLoginButton
                           align="center"
                           className="button-social-signin"
                           onClick={() => signInWithMS(!!extension)}
                        >
                           <div className="text-center fw-normal">Sign in with Microsoft</div>
                        </MicrosoftLoginButton>
                     </div>
                  </div>
                  <div className="py-3 d-flex align-items-center">
                     <hr className="flex-grow-1" />
                     <div className="badge bg-secondary">OR</div>
                     <hr className="flex-grow-1" />
                  </div>

                  <form onSubmit={emailSignup}>
                     <div className="row">
                        <div className="col-sm-6">
                           <div className="position-relative mb-3">
                              <Form.Label className="form-label fs-12p fw-normal">
                                 First name
                              </Form.Label>
                              <Form.Control
                                 className="form-control-sm"
                                 defaultValue={inviteData?.data?.firstName}
                                 name="firstName"
                                 required
                                 type="text"
                              ></Form.Control>
                           </div>
                        </div>
                        <div className="col-sm-6">
                           <div className="position-relative mb-3">
                              <Form.Label className="form-label fs-12p fw-normal">
                                 Last name
                              </Form.Label>
                              <Form.Control
                                 className="form-control-sm"
                                 defaultValue={inviteData?.data?.lastName}
                                 name="lastName"
                                 required
                                 type="text"
                              ></Form.Control>
                           </div>
                        </div>
                        <div className="col-12 mb-1">
                           <Form.Label className="form-label fs-12p fw-normal">
                              Email address
                           </Form.Label>
                           <Form.Control
                              className="form-control-sm"
                              defaultValue={inviteData?.data?.email}
                              disabled={!!invite}
                              name="email"
                              required
                              type="email"
                           ></Form.Control>
                        </div>
                        {invite && (
                           <div className="col-12 mb-1">
                              <Form.Label className="form-label fs-12p fw-normal">
                                 Password
                              </Form.Label>
                              <Form.Control
                                 className="form-control-sm"
                                 name="password"
                                 required
                                 type="password"
                              ></Form.Control>
                           </div>
                        )}
                     </div>
                     <Button
                        className="w-100 mt-4"
                        colorScheme="secondary"
                        isLoading={formProcessing}
                        size="md"
                        type="submit"
                     >
                        {invite ? 'Create Account' : 'Sign Up'}
                     </Button>
                  </form>
                  <div className="m-3"></div>
                  <div className="row">
                     <div className="col fs-11p">
                        {searchParams.has('error') && (
                           <Alert variant="danger">{searchParams.get('error')}</Alert>
                        )}
                     </div>
                  </div>
               </div>
            </div>
            {!isDesktop() && !invite && (
               <div className="col-sm card pt-4 pb-1 ps-4 pe-4" style={{ maxWidth: '500px' }}>
                  <div className="mb-3">
                     <h4 className="text-center mb-1 fw-500">
                        Start with the runQL <span className="fw-800">Desktop</span> IDE
                     </h4>
                  </div>
                  <DownloadLinks />
               </div>
            )}
         </div>
         <div className="row gap-4">
            <div className="col-sm">
               {!invite && (
                  <div className="fs-12p mt-3 text-center">
                     <div className="text-decoration-none">
                        Already have an account?&nbsp;&nbsp;
                        <Link className="text-decoration-none" to="/">
                           Sign In
                        </Link>
                     </div>
                  </div>
               )}
               <div className="mt-3 mb-1 text-center">
                  <div className="fs-11p">
                     By signing up you agree to the&nbsp;
                     <a
                        className="text-decoration-none"
                        href="https://runql.com/terms-of-service.html"
                        rel="noreferrer"
                        target="_Blank"
                     >
                        Terms of Service
                     </a>
                     &nbsp;and&nbsp;
                     <a
                        className="text-decoration-none"
                        href="https://runql.com/acceptable-use.html"
                        rel="noreferrer"
                        target="_Blank"
                     >
                        Acceptable Use Policy
                     </a>
                     .
                  </div>
               </div>
            </div>
            {!isDesktop() && !invite && <div className="col-sm" />}
         </div>
      </PublicPage>
   );
};

export default SignUpPage;
