import { useCallback, useEffect, useRef, useState } from 'react';
import { Stack, Spinner } from 'react-bootstrap';
import { HiSparkles } from 'react-icons/hi2';
import { MdModeEdit, MdOutlineSave, MdOutlineCancel } from 'react-icons/md';
import classNames from 'classnames';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { AiPulse, AiSparkles, InlineInput, Button } from '../components';
import { QueryVersion } from '../entities';
import { useSubscribe } from '../utilities';

const ButtonSpinner = () => (
   <>
      &nbsp;
      <Spinner animation="border" aria-hidden="true" as="span" role="status" size="sm" />
      &nbsp;
   </>
);

export const QueryDocs = ({
   allowDocGeneration,
   isGenerating,
   isGeneratingExplanation,
   onChangeDocumentation,
   onClickGenerateDocumentation,
   onClickGenerateExplanation,
   onSetExplanation,
   queryVersion,
   readOnly,
   setShowUpgradeModal,
}: {
   allowDocGeneration?: boolean;
   isGenerating?: boolean;
   isGeneratingExplanation?: boolean;
   onChangeDocumentation?: (params: {
      description?: string;
      explanation?: string;
      question?: string;
      title?: string;
   }) => void;
   onClickGenerateDocumentation?: React.MouseEventHandler<HTMLButtonElement>;
   onClickGenerateExplanation?: React.MouseEventHandler<HTMLButtonElement>;
   onSetExplanation?: (explanation: string) => void;
   queryVersion: QueryVersion;
   readOnly?: boolean;
   setShowUpgradeModal?: () => void;
}) => {
   const [editingExplanation, setEditingExplanation] = useState(false);
   const [explanation, setExplanation] = useState(queryVersion?.explanation ?? '');
   const [prevExplanation, setPrevExplanation] = useState(explanation);
   const inputRef = useRef<HTMLInputElement | null>(null);
   const scrollRef = useRef<HTMLDivElement | null>(null);

   const scrollToExplanation = useCallback(() => {
      scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
   }, []);

   const handleClickGenerateAnalystDescription = useCallback(
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
         if (onClickGenerateExplanation) {
            setExplanation('Generating analyst description...');
            onClickGenerateExplanation(e);
         }
      },
      [onClickGenerateExplanation]
   );

   const handleEditAnalystDescription = useCallback(() => {
      setPrevExplanation(explanation);
      setEditingExplanation(true);
      scrollToExplanation();
   }, [explanation, setEditingExplanation, scrollToExplanation]);

   const handleSaveAnalystDescription = useCallback(() => {
      onSetExplanation?.(explanation);
      setEditingExplanation(false);
   }, [setEditingExplanation, explanation, onSetExplanation]);

   const handleCancelEditAnalystDescription = useCallback(() => {
      setExplanation(prevExplanation);
      onSetExplanation?.(prevExplanation);
      setEditingExplanation(false);
   }, [prevExplanation, onSetExplanation]);

   useEffect(() => {
      if (queryVersion?.explanation) setExplanation(queryVersion?.explanation);
   }, [queryVersion?.explanation, setExplanation]);

   useSubscribe(`scrollToExplanation-${queryVersion.id}`, scrollToExplanation, [
      scrollToExplanation,
   ]);

   return (
      <Stack className="h-100 overflow-auto px-2 fs-12p" gap={4}>
         <Stack direction="horizontal" gap={2}>
            <div className="fs-12p fw-bold">Documentation</div>
            {readOnly || !allowDocGeneration ? null : (
               <Button onClick={onClickGenerateDocumentation} size="sm" variant="link">
                  {isGenerating ? (
                     <ButtonSpinner />
                  ) : (
                     <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <HiSparkles className="me-2" size={15} />
                        <p style={{ alignSelf: 'center', marginBottom: 0 }}>Generate</p>
                     </div>
                  )}
               </Button>
            )}
         </Stack>
         {!readOnly && !allowDocGeneration && (
            <div
               className="pt-2"
               style={{
                  fontSize: '12px',
                  color: '#4c82f7',
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '3px',
               }}
            >
               Loving the magic of automatic documentation?{' '}
               <Button
                  onClick={() => {
                     if (setShowUpgradeModal) {
                        setShowUpgradeModal();
                     }
                  }}
                  style={{
                     backgroundColor: 'transparent',
                     border: 'none',
                     color: 'inherit',
                     padding: 0,
                     margin: 0,
                     textDecoration: 'underline',
                     cursor: 'pointer',
                     fontSize: '12px',
                     fontWeight: 'bold',
                  }}
               >
                  Upgrade now
               </Button>{' '}
               to keep it going!
            </div>
         )}
         <div>
            <div>
               <div className="fs-12p fw-500">Title</div>
            </div>
            <div className="flex-grow-1">
               <AiPulse on={isGenerating}>
                  <AiSparkles
                     resetOnChange={queryVersion?.queryId}
                     trigger={
                        !!queryVersion?.generatedTitle &&
                        !!queryVersion?.title &&
                        queryVersion?.title !== 'New query'
                     }
                     triggerOnChange={queryVersion?.title}
                  >
                     {(targetRef) => (
                        <div ref={targetRef}>
                           <InlineInput
                              className="small-form-control-input"
                              onChange={(title) => {
                                 onChangeDocumentation?.({ title });
                              }}
                              placeholder="Add a title…"
                              readOnly={readOnly}
                              value={queryVersion?.title ?? ''}
                           />
                        </div>
                     )}
                  </AiSparkles>
               </AiPulse>
            </div>
         </div>
         <div>
            <div>
               <div className="fs-12p fw-500">Description</div>
            </div>
            <div className="flex-grow-1">
               <AiPulse on={isGenerating}>
                  <AiSparkles
                     resetOnChange={queryVersion?.queryId}
                     trigger={!!queryVersion?.description && !!queryVersion?.generatedDescription}
                     triggerOnChange={queryVersion?.description}
                  >
                     {(targetRef) => (
                        <div ref={targetRef}>
                           <InlineInput
                              className="small-form-control-input"
                              multiLine
                              onChange={(description) => {
                                 onChangeDocumentation?.({ description });
                              }}
                              placeholder="Add a description…"
                              readOnly={readOnly}
                              value={queryVersion?.description ?? ''}
                           />
                        </div>
                     )}
                  </AiSparkles>
               </AiPulse>
            </div>
         </div>
         <div>
            <div>
               <div className="fs-12p fw-500">Question</div>
            </div>
            <div className="flex-grow-1">
               <AiPulse on={isGenerating}>
                  <AiSparkles
                     resetOnChange={queryVersion?.queryId}
                     trigger={!!queryVersion?.question && !!queryVersion?.generatedQuestion}
                     triggerOnChange={queryVersion?.question}
                  >
                     {(targetRef) => (
                        <div ref={targetRef}>
                           <InlineInput
                              className="small-form-control-input"
                              multiLine
                              onChange={(question) => {
                                 onChangeDocumentation?.({ question });
                              }}
                              placeholder="Add the question this query answers…"
                              readOnly={readOnly}
                              value={queryVersion?.question ?? ''}
                           />
                        </div>
                     )}
                  </AiSparkles>
               </AiPulse>
            </div>
         </div>
         <div className="mb-2">
            <Stack gap={1}>
               <Stack direction="horizontal" gap={2}>
                  <div className="fs-12p fw-500">Analyst Description</div>
                  {readOnly || !allowDocGeneration ? null : (
                     <Button
                        disabled={editingExplanation}
                        isLoading={isGeneratingExplanation}
                        loadingMessage="Generating..."
                        onClick={handleClickGenerateAnalystDescription}
                        size="sm"
                        variant="link"
                     >
                        <Stack direction="horizontal" gap={1}>
                           <HiSparkles size={15} />
                           <span>Generate</span>
                        </Stack>
                     </Button>
                  )}
                  {readOnly ? null : editingExplanation ? (
                     <>
                        <Button
                           colorScheme="secondary"
                           onClick={handleSaveAnalystDescription}
                           size="sm"
                        >
                           <Stack direction="horizontal" gap={1}>
                              <MdOutlineSave size={15} />
                              <span>Save</span>
                           </Stack>
                        </Button>
                        <Button
                           colorScheme="secondary"
                           onClick={handleCancelEditAnalystDescription}
                           size="sm"
                        >
                           <Stack direction="horizontal" gap={1}>
                              <MdOutlineCancel size={15} />
                              <span>Cancel</span>
                           </Stack>
                        </Button>
                     </>
                  ) : (
                     <Button onClick={handleEditAnalystDescription} size="sm" variant="link">
                        <Stack direction="horizontal" gap={1}>
                           <MdModeEdit size={15} />
                           <span>Edit</span>
                        </Stack>
                     </Button>
                  )}
               </Stack>
               <div className="flex-grow-1">
                  <AiPulse on={isGeneratingExplanation}>
                     <AiSparkles
                        resetOnChange={queryVersion?.queryId}
                        trigger={
                           !!queryVersion?.explanation && !!queryVersion?.generatedExplanation
                        }
                        triggerOnChange={queryVersion?.explanation}
                     >
                        {(targetRef) => (
                           <div
                              className={classNames({
                                 'explanation-textbox': !editingExplanation,
                                 readOnly,
                              })}
                              ref={targetRef}
                           >
                              {editingExplanation ? (
                                 <InlineInput
                                    className="small-form-control-input"
                                    multiLine
                                    onChange={(newVal) => {
                                       setExplanation(newVal);
                                    }}
                                    placeholder="Explain what this query does..."
                                    readOnly={readOnly}
                                    ref={inputRef}
                                    value={explanation}
                                 />
                              ) : (
                                 <Markdown remarkPlugins={[remarkGfm]}>{explanation}</Markdown>
                              )}
                           </div>
                        )}
                     </AiSparkles>
                  </AiPulse>
               </div>
            </Stack>
         </div>
      </Stack>
   );
};

export default QueryDocs;
