import { VariantProps, cva, cx } from 'class-variance-authority';
import { ButtonHTMLAttributes, forwardRef } from 'react';
import { Spinner } from 'react-bootstrap';

// TODO: Add ghost variant styles for colorSchemes other than secondary.
const buttonVariants = cva('btn', {
   variants: {
      colorScheme: {
         dark: '',
         primary: '',
         secondary: '',
         success: '',
         danger: '',
      },
      variant: {
         solid: '',
         outline: '',
         ghost: 'border-0',
         link: 'btn-link',
      },
      size: {
         sm: 'btn-sm p-1',
         md: 'btn-md p-2',
         lg: 'btn-lg p-3',
      },
   },
   compoundVariants: [
      {
         colorScheme: 'primary',
         variant: 'solid',
         className: 'btn-primary',
      },
      {
         colorScheme: 'secondary',
         variant: 'solid',
         className: 'btn-secondary',
      },
      {
         colorScheme: 'primary',
         variant: 'link',
         className: 'btn-link-primary',
      },
      {
         colorScheme: 'success',
         variant: 'solid',
         className: 'btn-success',
      },
      {
         colorScheme: 'danger',
         variant: 'solid',
         className: 'btn-danger',
      },
      {
         colorScheme: 'dark',
         variant: 'solid',
         className: 'btn-dark',
      },
      {
         colorScheme: 'primary',
         variant: 'outline',
         className: 'btn-outline-primary',
      },
      {
         colorScheme: 'secondary',
         variant: 'outline',
         className: 'btn-outline-secondary',
      },
      {
         colorScheme: 'success',
         variant: 'outline',
         className: 'btn-outline-success',
      },
      {
         colorScheme: 'danger',
         variant: 'outline',
         className: 'btn-outline-danger',
      },
      {
         colorScheme: 'secondary',
         variant: 'ghost',
         className: 'btn-outline-secondary',
      },
   ],
   defaultVariants: {
      colorScheme: 'primary',
      size: 'sm',
      variant: 'solid',
   },
});

const spinnerVariants = cva('spinner-border', {
   variants: {
      size: {
         sm: 'me-1',
         md: 'me-2',
         lg: 'me-3',
      },
   },
   defaultVariants: {
      size: 'sm',
   },
});

export interface ButtonProps
   extends ButtonHTMLAttributes<HTMLButtonElement>,
      VariantProps<typeof buttonVariants> {
   colorScheme?: 'primary' | 'secondary' | 'success' | 'danger' | 'dark';
   href?: string;
   isLoading?: boolean;
   loadingMessage?: string;
   message?: string;
   size?: 'sm' | 'md' | 'lg';
   type?: 'button' | 'submit';
   variant?: 'solid' | 'outline' | 'ghost' | 'link';
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
   (
      {
         colorScheme,
         message,
         size = 'sm',
         variant,
         className,
         children,
         isLoading,
         loadingMessage,
         disabled,
         type = 'button',
         href,
         ...props
      },
      ref
   ) => {
      const minWidth = message ? message.length * 6 : 50;
      const buttonClasses = cx(
         buttonVariants({
            colorScheme,
            variant,
            size,
         }),
         className
      );
      const onClick =
         props.onClick ??
         (href
            ? () => {
                 // For some reason, adding useNavigate() causes the code editor
                 // gutter to disappear. So, we use window.location.href
                 // instead.
                 window.location.href = href;
              }
            : undefined);

      return (
         <button
            className={buttonClasses}
            disabled={disabled || isLoading}
            onClick={onClick}
            ref={ref}
            type={type}
            {...props}
            style={{
               minWidth: isLoading ? minWidth : undefined,
               ...props.style,
            }}
         >
            {isLoading && (
               <Spinner
                  animation="border"
                  className={cx(spinnerVariants({ size: size }))}
                  role="status"
                  style={{
                     width: size === 'sm' ? '12px' : size === 'md' ? '20px' : '24px',
                     height: size === 'sm' ? '12px' : size === 'md' ? '20px' : '24px',
                  }}
               />
            )}
            {isLoading ? loadingMessage ?? 'Loading...' : children ?? message}
         </button>
      );
   }
);

export default Button;
