import { createContext, useState, useContext, ReactNode, useCallback, memo } from 'react';
import { useQueryClient } from 'react-query';
import { Button, Form, Modal, Stack } from 'react-bootstrap';
import { DataConnection, DataCredential } from '../entities';
import { QueryKeyType } from '../enums';
import {
   getWorkspaceConnectionQueryKey,
   useNewDataCredentialMutator,
   useDataConnection,
   useReTestDataConnectionMutator,
} from '../hooks/entities';
import ConnectionDetailsForm, {
   connectionDetailsFormId,
} from '../pages/DataConnections/AddConnectionWizard/ConnectionDetailForm/ConnectionDetailsForm';
import { isSnowflakeOAuthConnection } from '../utilities';

export interface DataCredentialsModalProps {
   connectionId: number;
   handleClose: (success: boolean) => void;
   querySavedResultsId?: number;
   show: boolean;
   workspaceId?: number;
}

type AddCredentialsContextType = {
   openModal: (params: Omit<DataCredentialsModalProps, 'show'>) => void;
};

const AddCredentialsContext = createContext<AddCredentialsContextType | undefined>(undefined);

export const AddCredentialsProvider = ({ children }: { children: ReactNode }) => {
   const [modalProps, setModalProps] = useState<DataCredentialsModalProps | null>(null);
   const [, setCallbacks] = useState<((success: boolean) => void)[]>([]);

   const openModal = useCallback(
      (params: Omit<DataCredentialsModalProps, 'show' | 'addCallback'>) => {
         if (params.handleClose) {
            setCallbacks((callbacks) => [...callbacks, params.handleClose]);
         }
         if (!modalProps?.show) {
            setModalProps({ ...params, show: true });
         }
      },
      [modalProps]
   );

   const closeModal = useCallback(
      (success: boolean) => {
         setCallbacks((callbacks) => {
            callbacks.forEach((callback) => callback(success));
            return [];
         });
         if (modalProps) {
            setModalProps({ ...modalProps, show: false });
         }
      },
      [modalProps]
   );

   return (
      <AddCredentialsContext.Provider value={{ openModal }}>
         {children}
         {modalProps && <DataCredentialsModal {...modalProps} handleClose={closeModal} />}
      </AddCredentialsContext.Provider>
   );
};

export const useAddCredentials = () => {
   const context = useContext(AddCredentialsContext);
   if (!context) {
      throw new Error('useAddCredentials must be used within an AddCredentialsProvider');
   }
   return context.openModal;
};

const DataCredentialsModal = memo(
   ({
      connectionId,
      handleClose,
      show,
   }: {
      connectionId: number | undefined;
      handleClose?(success: boolean): void;
      show: boolean;
   }): JSX.Element => {
      const connection = useDataConnection(connectionId);
      const [state, setState] = useState<string>();
      const [areCredentialsInvalid, setAreCredentialsInvalid] = useState<boolean>(false);

      // Hooks
      const queryClient = useQueryClient();
      const testConnectionMutator = useReTestDataConnectionMutator();
      const newCredentialMutator = useNewDataCredentialMutator({
         onSuccess(_newCredential, _variables, _context) {
            queryClient.invalidateQueries(
               getWorkspaceConnectionQueryKey({ type: QueryKeyType.LIST })
            );
         },
      });

      const isSnowflakeOAuth = connection && isSnowflakeOAuthConnection(connection);

      const onSubmit = async (
         connection: DataConnection | undefined,
         newCredential: DataCredential | undefined
      ) => {
         if (!newCredential) {
            handleClose?.(false);
            return;
         }

         setAreCredentialsInvalid(false);
         try {
            if (connection) {
               const { success } = await testConnectionMutator.mutateAsync(connection);
               if (!success) {
                  setAreCredentialsInvalid(true);
                  return;
               }
            }

            if (!isSnowflakeOAuth) {
               await newCredentialMutator.mutateAsync({
                  newCredential,
               });
            }
            handleClose?.(true);
         } catch (err) {
            handleClose?.(false);
         }
      };

      // Page functions
      if (connectionId === undefined) {
         return <></>;
      }

      return (
         <Modal
            centered
            onHide={() => {
               handleClose?.(false);
            }}
            show={show}
            size={isSnowflakeOAuth ? 'lg' : undefined}
         >
            <Modal.Header closeButton>
               <Modal.Title className="fs-14p workspaceName">Add Your Credentials</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               {connection?.name && (
                  <Form.Group className="mb-3">
                     <Form.Label>Data Source: {connection?.name}</Form.Label>
                  </Form.Group>
               )}
               <ConnectionDetailsForm
                  onSaveStateChange={setState}
                  onSubmit={onSubmit}
                  onlyCreds
                  selectedConnectionId={connectionId}
                  selectedDBMS={connection?.dbms}
               />
            </Modal.Body>
            <Modal.Footer>
               <Stack className="w-100 justify-content-between" direction="horizontal">
                  <p className="text-danger fs-10p m-0">
                     {areCredentialsInvalid ? 'Invalid Credentials. Please try again.' : ''}
                  </p>
                  <Stack direction="horizontal" gap={2}>
                     <Button
                        className="btn btn-sm btn-secondary"
                        onClick={() => {
                           handleClose?.(false);
                        }}
                        variant="secondary"
                     >
                        Cancel
                     </Button>
                     <Button
                        className="btn btn-sm btn-primary"
                        disabled={!isSnowflakeOAuth && state === 'clean'}
                        form={connectionDetailsFormId}
                        type="submit"
                        variant="primary"
                     >
                        Save
                     </Button>
                  </Stack>
               </Stack>
            </Modal.Footer>
         </Modal>
      );
   }
);

export default DataCredentialsModal;
