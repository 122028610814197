import { inject, injectable } from 'inversify';
import { WorkspaceSchemaConnection } from '../entities';
import { TYPES } from '../types';
import { ApiService } from './ApiService';
import { ApiServiceInterface } from './ApiServiceInterface';

const path = '/v1/workspaceSchemaConnection';

export interface WorkspaceSchemaConnectionListOptions {
   includeConnectionDetails?: boolean;
   workspaceId?: number;
}

export interface WorkspaceSchemaConnectionGetOptions {
   includeConnectionDetails: boolean;
}

export interface RunWorkspaceQueryBody {
   exploreTabId: number;
   query: string;
   querySavedId?: number;
}

@injectable()
export class WorkspaceSchemaConnectionService
   implements ApiServiceInterface<WorkspaceSchemaConnection>
{
   private apiService: ApiService;

   constructor(@inject(TYPES.apiService) apiService: ApiService) {
      this.apiService = apiService;
   }

   async delete(id: string | number): Promise<null> {
      await this.apiService.delete(`${path}/${id}`);
      return null;
   }

   async get(
      id: string | number,
      params?: Record<string, string>
   ): Promise<WorkspaceSchemaConnection | undefined> {
      const result = await this.apiService.get<WorkspaceSchemaConnection>(`${path}/${id}`, params);
      if (!result) {
         return undefined;
      }

      return result;
   }

   getOptions(id: number, options?: WorkspaceSchemaConnectionGetOptions) {
      const params: Record<string, string> = {};
      if (options !== undefined) {
         for (const k in options) {
            const v = options[k as keyof typeof options];
            if (v) {
               params[k] = v.toString();
            }
         }
      }

      return this.get(id, params);
   }

   async list(params: Record<string, string>): Promise<WorkspaceSchemaConnection[]> {
      const result = await this.apiService.get<WorkspaceSchemaConnection[]>(path, params);
      if (!result) {
         return [];
      }

      return result;
   }

   listOptions(
      options?: WorkspaceSchemaConnectionListOptions
   ): Promise<WorkspaceSchemaConnection[]> {
      const params: Record<string, string> = {};
      if (options !== undefined) {
         for (const k in options) {
            const v = options[k as keyof typeof options];
            if (v) {
               params[k] = v.toString();
            }
         }
      }
      return this.list(params);
   }

   async patch(
      _id: string | number,
      _body: WorkspaceSchemaConnection,
      _params?: Record<string, string> | undefined
   ): Promise<WorkspaceSchemaConnection | undefined> {
      throw new Error('Method not implemented.');
   }

   async post(
      _body: WorkspaceSchemaConnection,
      _params?: Record<string, string> | undefined
   ): Promise<WorkspaceSchemaConnection | undefined> {
      throw new Error('Method not implemented.');
   }

   async put(
      body: WorkspaceSchemaConnection,
      params?: Record<string, string> | undefined
   ): Promise<WorkspaceSchemaConnection | undefined> {
      const result = await this.apiService.put<WorkspaceSchemaConnection>(`${path}`, body, params);
      if (!result) {
         return undefined;
      }

      return result;
   }
}
