import { Stack } from 'react-bootstrap';
import { MdCheck } from 'react-icons/md';

import { LoadingSpinner, QueryReviewPage, Button } from '../components';
import LoadingError from '../components/UI/LoadingError';
import { QueryVersion } from '../entities';
import { getErrorMessage, useTitle } from '../utilities';
import { useListQueryQuery, useQueryApproval } from '../hooks';
import { useState } from 'react';
const CertifyButton = ({
   queryVersion,
   onClick,
}: {
   onClick?: () => void;
   queryVersion: QueryVersion;
}) => {
   const { approve, saving } = useQueryApproval(queryVersion);

   const handleClick = () => {
      onClick?.();
      approve();
   };

   return (
      <Button
         colorScheme="success"
         isLoading={saving}
         loadingMessage="Saving..."
         onClick={handleClick}
      >
         <Stack direction="horizontal" gap={1}>
            <MdCheck />
            <span>Certify</span>
         </Stack>
      </Button>
   );
};

export function QueryReview() {
   useTitle('Certification Requests');

   const [activeQueryVersion, setActiveQueryVersion] = useState<QueryVersion | null>(null);

   const queries = useListQueryQuery({
      listOptions: {
         expandedPersonData: true,
         includeDataConnectionDetails: true,
         version: 'review',
         includeWorkspace: true,
         includeParent: true,
      },
   });

   const handleCertifyClick = () => {
      if (!queries.data) return;
      const currentIndex = queries.data.items.findIndex(
         (item) => item.id === activeQueryVersion?.id
      );
      if (currentIndex === -1) return;
      const nextItem = queries.data.items[currentIndex + 1] || queries.data.items[currentIndex - 1];
      if (nextItem) {
         setActiveQueryVersion(nextItem);
      }
   };

   if (queries.isError) {
      return <LoadingError message={getErrorMessage(queries.error)} />;
   }

   if (!queries.data) {
      return <LoadingSpinner />;
   }

   return (
      <QueryReviewPage
         activeQueryVersion={activeQueryVersion}
         defaultTab="comments"
         diffVersion={(queryVersion) => queryVersion.query?.approvedVersion}
         extraActions={(queryVersion) => [
            <CertifyButton
               key="approve"
               onClick={handleCertifyClick}
               queryVersion={queryVersion}
            />,
         ]}
         hideTabs={{ logs: true }}
         noItemsMessage="No certification requests"
         queryVersions={queries.data.items}
         setActiveQueryVersion={setActiveQueryVersion}
         upgradeMessage="Certify and approve queries for all users in your organization."
      />
   );
}

export default QueryReview;
