import { forwardRef, useImperativeHandle, useRef, useMemo, useCallback } from 'react';
import { useInjection } from 'inversify-react';
import { useQueries } from 'react-query';
import CodeEditor, { CodeEditorMethods } from '../../../components/UI/CodeEditor';
import LoadingSpinner from '../../../components/UI/LoadingSpinner';
import { DataConnection, QueryStep, StepType } from '../../../entities';
import { DBMS, QueryKey } from '../../../enums';
import { PromptTypes } from '../../../models/prompt';
import { DataConnectionService } from '../../../services';
import { TYPES } from '../../../types';
import { useSaveSnippet } from '../../../hooks';

export const SQL_TEMPLATE = 'SELECT * FROM ';
const PYTHON_TEMPLATE = 'data';

export interface QueryEditorMethods {
   insertSnippet: (snippet: string) => void;
}

export const QueryEditor = forwardRef(
   (
      {
         step,
         loading,
         onChange,
         onFocus,
         onRun,
         onSelection,
         readOnly,
         dataConnections: _dataConnections,
      }: {
         dataConnections: DataConnection[];
         loading?: boolean;
         onChange: (step: QueryStep) => void;
         onFocus?: () => void;
         onRun?: (query?: string) => void;
         onSelection?: (selection?: string) => void;
         readOnly?: boolean;
         step: QueryStep;
      },
      ref
   ): JSX.Element => {
      const editor = useRef<CodeEditorMethods>(null);

      // Data Services
      const dataSourceService = useInjection<DataConnectionService>(TYPES.dataConnectionService);

      // Non-federated queries have a single data connection
      const dataConnections = useMemo(() => {
         switch (step.type) {
            case StepType.DATA_CONNECTION:
               const dataConnection = _dataConnections.find((c) => c.id === step.dataConnectionId);
               return dataConnection ? [dataConnection] : [];
            case StepType.FEDERATED:
               return _dataConnections;
            default:
               return [];
         }
      }, [_dataConnections, step.type, step.dataConnectionId]);

      let dbms;
      const taskType: string | undefined = dataConnections[0]?.taskType;
      let queryTemplate;
      switch (step.type) {
         case StepType.DATA_CONNECTION:
            dbms = dataConnections[0]?.dbms ?? DBMS.MySQL;
            queryTemplate = taskType
               ? PromptTypes[dbms]?.find((type) => type.id === taskType)?.query
               : SQL_TEMPLATE;
            break;
         case StepType.FEDERATED:
            dbms = DBMS.MySQL;
            queryTemplate = SQL_TEMPLATE;
            break;
         case StepType.PYTHON:
            dbms = DBMS.Python;
            queryTemplate = 'data';
            break;
      }

      // Set the template
      const queryChanged = useRef(false);
      if (
         ['', SQL_TEMPLATE, PYTHON_TEMPLATE]
            .concat(
               Object.values(PromptTypes).flatMap((type) => type.map((prompt) => prompt.query))
            )
            .includes(step.queryText ?? '') &&
         queryTemplate &&
         !queryChanged.current
      ) {
         step.queryText = queryTemplate ?? '';
      }

      // Queries
      const queries = useQueries(
         dataConnections
            .filter((c) => step.type === StepType.FEDERATED || c.id === step.dataConnectionId)
            .map((c) => ({
               queryKey: [QueryKey.EditorSchemaForDataConnection, c.id],
               queryFn: () => dataSourceService.getEditorSchema(c.id!),
               retry: false,
               staleTime: 1000 * 60 * 15,
            }))
      ).map((q) => q.data);

      const dsSchema = useMemo(() => {
         return queries.reduce((acc, query) => {
            if (!query) return acc;

            const newSchema = { ...query.schema };

            // Add the fully-qualified table names (e.g. catalog.schema.table)
            for (const table in newSchema) {
               if (!table) continue;
               // Add partially qualified table names
               const parts = table.split('.');
               newSchema[`${parts[1]}.${parts[2]}`] = newSchema[table];
               newSchema[parts[2]] = newSchema[table];
               for (const column of newSchema[table]) {
                  // Add columns without table names
                  newSchema[column] = [];
               }
            }

            return { ...acc, ...newSchema };
         }, {} as Record<string, string[]>);
         // Keeping a stable value is a little tricky here. The outer arrays
         // aren't stable, but the data is, which is why we map to data and
         // spread it across the memo deps. useMemo requires the length of
         // dependancies to remain stable.
         //
         // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [...queries, ...Array(Math.max(0, 30 - queries.length)).fill(null)]);

      useImperativeHandle(ref, () => ({
         insertSnippet: async (snippet: string) => {
            editor.current?.insert(snippet);
         },
      }));

      const onEditorChange = useCallback(
         async (value: string) => {
            queryChanged.current = true;
            onChange({ ...step, queryText: value });
         },
         [onChange, step]
      );

      const saveSnippet = useSaveSnippet();

      return (
         <>
            <div className="card border-0">
               <div className="queryFont code-container border-bottom-line border-top-line">
                  {loading ? (
                     <div className="col-12 d-flex justify-content-center align-items-center">
                        <LoadingSpinner />
                     </div>
                  ) : (
                     <CodeEditor
                        dialect={dbms}
                        onChange={onEditorChange}
                        onFocus={onFocus}
                        onRun={onRun}
                        onSelection={onSelection}
                        query={step.queryText}
                        readOnly={readOnly}
                        ref={editor}
                        saveSnippet={saveSnippet}
                        schema={dsSchema}
                     />
                  )}
               </div>
            </div>
         </>
      );
   }
);

export default QueryEditor;
