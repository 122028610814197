import { useState, useRef, useEffect } from 'react';
import { Form, Nav, Dropdown, Stack, OverlayTrigger, Tooltip, InputGroup } from 'react-bootstrap';
import classNames from 'classnames';
import { IconType } from 'react-icons';
import { BiChevronDown } from 'react-icons/bi';
import Loader from 'react-spinners/PulseLoader';
import { format as formatDate } from 'date-fns';

import {
   IconNewChat,
   IconSendChat,
   IconMenuWorkspaces,
   IconCheckBox,
   IconCheckBoxChecked,
   IconInternal,
} from '../utilities/icons';
import { Button, InlineInput } from '../components';
import {
   PersonUtilities,
   DataChatThread,
   DataChatMessage,
   DataChatMessageQuery,
   DataChatThreadState,
   useOpenQuery,
   DataChatMessageRole,
   DataChatMessageSuggestion,
   Person,
   QueryVersion,
} from '../entities';
import { usePerson, useAddDataChatMessage, usePatchDataChatThread } from '../hooks';
import { PersonRole } from '../enums';

type SendCallback = (message: Partial<DataChatMessage>, thread?: Partial<DataChatThread>) => void;

export type ChatSuggestion = {
   icon?: IconType;
   label: string;
   onClick: () => void;
};

const SuggestionButton = ({ icon, label, onClick }: ChatSuggestion) => {
   const CustomIcon = icon;
   return (
      <Button colorScheme="secondary" onClick={onClick} size="md" style={{ borderRadius: '8px' }}>
         <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
            {CustomIcon && <CustomIcon size="20px" />}
            <span style={{ fontSize: '14px', fontWeight: '400' }}>{label}</span>
         </div>
      </Button>
   );
};

const ChatStart = ({
   greeting,
   onSend,
   suggestions,
   footer,
}: {
   footer?: React.ReactNode;
   greeting?: string;
   onSend: SendCallback;
   suggestions?: ChatSuggestion[];
}): JSX.Element => {
   return (
      <Stack
         className="w-100 h-100 p-3 justify-content-center align-items-center position-relative"
         gap={3}
      >
         <h1 className="m-0" style={{ opacity: '75%', fontSize: '30px', fontWeight: '600' }}>
            {greeting ?? 'How can I help?'}
         </h1>
         <ChatInput big onSend={onSend} thread={null} />
         {!!suggestions?.length && (
            <Stack className="justify-content-center" direction="horizontal" gap={2}>
               {suggestions.map((suggestion, index) => (
                  <SuggestionButton key={index} {...suggestion} />
               ))}
            </Stack>
         )}
         <div className="position-absolute w-100 py-2 px-3" style={{ bottom: '0' }}>
            {footer}
         </div>
      </Stack>
   );
};

const ChatBubble = ({
   message,
   person,
   side,
   renderQueries,
}: {
   message: Partial<DataChatMessage>;
   person: Person;
   renderQueries: (queries: DataChatMessageQuery[]) => React.ReactNode;
   side: 'ours' | 'theirs';
}): JSX.Element => {
   const currentUser = usePerson();

   const msgDateFormatted = formatDate(
      message.created !== undefined ? new Date(message.created) : new Date(),
      'yyyy-MM-dd hh:mm aa'
   );
   return (
      <>
         <div
            className={classNames(
               'card chat-bubble py-2 px-3',
               side === 'ours' ? 'bg-secondary' : 'bg-chat-primary',
               currentUser.id === person.id ? 'chat-bubble-right' : 'chat-bubble-left',
               { 'chat-bubble-internal': message.isInternal },
               { 'chat-bubble-wide': !!message.messageQueries?.length }
            )}
         >
            <Stack gap={1}>
               {person.id !== currentUser.id ? (
                  <Stack
                     className="query-card-footer fs-9p w-100 text-muted"
                     direction="horizontal"
                  >
                     <div className="fw-500 potential-badge">
                        {`${PersonUtilities.getFullName(person)} - ${msgDateFormatted}`}
                     </div>
                  </Stack>
               ) : null}
               <div>
                  {message.isInternal && (
                     <>
                        <IconInternal />{' '}
                     </>
                  )}
                  {message.content}
               </div>
               {person.id === currentUser.id ? (
                  <Stack
                     className="query-card-footer fs-9p w-100 text-muted justify-content-end"
                     direction="horizontal"
                  >
                     <div>
                        {formatDate(
                           message.created !== undefined ? new Date(message.created) : new Date(),
                           'yyyy-MM-dd hh:mm aa'
                        )}
                     </div>
                  </Stack>
               ) : null}
               {currentUser.role !== PersonRole.ORG_BUSINESS_USER &&
                  !!message.messageQueries?.length &&
                  renderQueries(message.messageQueries)}
            </Stack>
         </div>
         {currentUser.role === PersonRole.ORG_BUSINESS_USER &&
            !!message.messageQueries?.length &&
            renderQueries(message.messageQueries)}
      </>
   );
};

const ThreadStateBadgeMini = ({ thread, person }: { person: Person; thread: DataChatThread }) => {
   return (
      <div
         className={`data-chat-state-badge ${getThreadStateClass({ thread, person })}`}
         style={{ width: '12px', height: '12px' }}
      ></div>
   );
};

const getThreadStateLabel = ({
   thread,
   person,
   small,
}: {
   person: Person;
   small?: boolean;
   thread: DataChatThread;
}) => {
   switch (thread.state) {
      case DataChatThreadState.NEW:
      case DataChatThreadState.ASK:
         return 'New';

      case DataChatThreadState.PENDING:
         const who = person.id === thread.creator.id ? 'You' : 'Requestor';
         return small ? who : `Waiting for ${who}`;

      case DataChatThreadState.OPEN:
         return small ? 'Analyst' : 'Waiting for Analyst';

      case DataChatThreadState.SOLVED:
         return 'Solved';

      case DataChatThreadState.ACCEPTED:
         return 'Accepted';

      case DataChatThreadState.CANCELLED:
         return 'Cancelled';

      default:
         return '';
   }
};

const ThreadStateBadge = ({ thread, person }: { person: Person; thread: DataChatThread }) => {
   const label = getThreadStateLabel({ thread, person }).toUpperCase();

   return (
      <div
         className={classNames(
            'data-chat-state-badge rounded px-2 fw-semibold fs-10p py-1',
            getThreadStateClass({
               thread,
               person,
            })
         )}
         style={{ letterSpacing: '0.025em' }}
      >
         {label}
      </div>
   );
};

const ThreadStateLabel = ({ thread, person }: { person: Person; thread: DataChatThread }) => {
   const label = getThreadStateLabel({ thread, person, small: true }).toUpperCase();

   return (
      <div
         className={classNames(
            'data-chat-state-label fs-9p fw-semibold',
            getThreadStateClass({
               thread,
               person,
            })
         )}
         style={{ letterSpacing: '0.025em' }}
      >
         {label}
      </div>
   );
};

const getIsBusinessUser = (person: Person) => person.role === PersonRole.ORG_BUSINESS_USER;

const ChatInput = ({
   big,
   currentQueryVersion,
   disabled,
   onSend,
   suggestions,
   thread,
}: {
   big?: boolean;
   currentQueryVersion?: QueryVersion;
   disabled?: boolean;
   onSend: SendCallback;
   suggestions?: DataChatMessageSuggestion[];
   thread: DataChatThread | null;
}): JSX.Element => {
   const [includeQuery, setIncludeQuery] = useState(false);
   const [isInternal, setIsInternal] = useState(false);
   const [message, setMessage] = useState<string>('');
   const inputRef = useRef<HTMLInputElement>(null);
   const person = usePerson();
   const openQuery = useOpenQuery();
   const patchDataChatThread = usePatchDataChatThread();
   // Clear the input when switching threads
   useEffect(() => {
      setMessage('');
   }, [thread?.id]);

   const isBusinessUser = getIsBusinessUser(person);

   const prompt = isBusinessUser
      ? !thread || thread.messages.length === 0
         ? 'Ask anything'
         : thread?.assignee
         ? `Respond to ${thread.assignee.firstName}`
         : 'Add a message'
      : thread?.creator
      ? `Respond to ${thread.creator.firstName}`
      : 'Add a message';

   const newQuery = () =>
      openQuery({
         workspaceId: thread?.workspaceId,
         dataChatThreadId: thread?.id,
         newTab: true,
         source: 'answers',
         queryVersion: {
            steps: [],
         },
      });

   const sendMessage = () => {
      onSend({
         content: message,
         isInternal: isInternal,
         ...(currentQueryVersion?.version && includeQuery
            ? { queryVersionId: currentQueryVersion.id }
            : {}),
      });
      setMessage('');
      setIncludeQuery(false);
      setIsInternal(false);
   };

   const isChatInputVisible =
      !thread ||
      !(
         isBusinessUser &&
         [DataChatThreadState.CANCELLED, DataChatThreadState.ACCEPTED].includes(thread?.state)
      );

   return (
      <Form
         className="w-100 align-items-center"
         onSubmit={(e) => {
            e.preventDefault();
            sendMessage();
         }}
         style={{ maxWidth: big ? '768px' : undefined }}
      >
         <Stack gap={2}>
            {suggestions && (
               <Stack direction="horizontal" gap={3}>
                  {suggestions.map((response, i) => (
                     <Button
                        key={i}
                        onClick={() => onSend(response, { state: response.nextState })}
                     >
                        {response.content}
                     </Button>
                  ))}
               </Stack>
            )}
            {isChatInputVisible && (
               <InputGroup>
                  <div className="flex-grow-1 flex-shrink-1">
                     <InlineInput
                        autoFocus
                        className={classNames(
                           'chat-input',
                           big ? 'form-control fs-14p' : 'small-form-control-input',
                           { 'rounded-end-0': isBusinessUser }
                        )}
                        disabled={disabled}
                        multiLine
                        onChange={(value) => setMessage(value)}
                        onEnter={sendMessage}
                        placeholder={prompt}
                        ref={inputRef}
                        value={message}
                     />
                  </div>
                  {isBusinessUser ? (
                     <button
                        className="btn btn-primary px-3"
                        disabled={message.trim() === ''}
                        type="submit"
                     >
                        <IconSendChat size="18px" />
                     </button>
                  ) : null}
               </InputGroup>
            )}

            {!isBusinessUser && (
               <Stack
                  className={classNames(
                     suggestions ? 'justify-content-center' : 'justify-content-end'
                  )}
                  direction="horizontal"
                  gap={1}
               >
                  {currentQueryVersion && !currentQueryVersion.version && (
                     <div>
                        <i>Save to include data</i>
                     </div>
                  )}
                  {currentQueryVersion?.version && (
                     <Button
                        colorScheme="secondary"
                        onClick={() => setIncludeQuery(!includeQuery)}
                        style={{
                           flexBasis: 'content',
                        }}
                        variant="ghost"
                     >
                        {includeQuery ? (
                           <IconCheckBoxChecked size="20px" />
                        ) : (
                           <IconCheckBox size="20px" />
                        )}
                        &nbsp;Include Data
                     </Button>
                  )}
                  <Button
                     colorScheme="secondary"
                     onClick={() => setIsInternal(!isInternal)}
                     style={{
                        flexBasis: 'content',
                     }}
                     variant="ghost"
                  >
                     {isInternal ? (
                        <IconCheckBoxChecked size="20px" />
                     ) : (
                        <IconCheckBox size="20px" />
                     )}
                     &nbsp;Internal Note
                  </Button>
                  {thread && (
                     <Dropdown>
                        <Dropdown.Toggle className="border-0" size="sm" variant="outline-secondary">
                           <Stack direction="horizontal" gap={1}>
                              <ThreadStateBadgeMini person={person} thread={thread} />
                              {thread?.state === DataChatThreadState.PENDING && (
                                 <>Waiting for Requestor</>
                              )}
                              {thread?.state === DataChatThreadState.OPEN && (
                                 <>Waiting for Analyst</>
                              )}
                              {thread?.state === DataChatThreadState.SOLVED && <>Solved</>}
                              {thread?.state === DataChatThreadState.CANCELLED && <>Cancelled</>}
                              {thread?.state === DataChatThreadState.ACCEPTED && <>Accepted</>}
                           </Stack>
                           <BiChevronDown size={16} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="fs-10p">
                           <Dropdown.Item
                              active={thread?.state === DataChatThreadState.PENDING}
                              onClick={() => {
                                 patchDataChatThread.mutate({
                                    id: thread.id!,
                                    data: { state: DataChatThreadState.PENDING },
                                 });
                              }}
                           >
                              <Stack direction="horizontal" gap={1}>
                                 <ThreadStateBadgeMini
                                    person={person}
                                    thread={{ ...thread, state: DataChatThreadState.PENDING }}
                                 />
                                 <span>Waiting for Requestor</span>
                              </Stack>
                           </Dropdown.Item>
                           <Dropdown.Item
                              active={thread?.state === DataChatThreadState.OPEN}
                              onClick={() => {
                                 patchDataChatThread.mutate({
                                    id: thread.id!,
                                    data: { state: DataChatThreadState.OPEN },
                                 });
                              }}
                           >
                              <Stack direction="horizontal" gap={1}>
                                 <ThreadStateBadgeMini
                                    person={person}
                                    thread={{ ...thread, state: DataChatThreadState.OPEN }}
                                 />
                                 <span>Waiting for Analyst</span>
                              </Stack>
                           </Dropdown.Item>
                           <Dropdown.Item
                              active={thread?.state === DataChatThreadState.SOLVED}
                              onClick={() => {
                                 patchDataChatThread.mutate({
                                    id: thread.id!,
                                    data: { state: DataChatThreadState.SOLVED },
                                 });
                              }}
                           >
                              <Stack direction="horizontal" gap={1}>
                                 <ThreadStateBadgeMini
                                    person={person}
                                    thread={{ ...thread, state: DataChatThreadState.SOLVED }}
                                 />
                                 <span>Solved</span>
                              </Stack>
                           </Dropdown.Item>
                           <Dropdown.Item
                              active={thread?.state === DataChatThreadState.CANCELLED}
                              onClick={() => {
                                 patchDataChatThread.mutate({
                                    id: thread.id!,
                                    data: { state: DataChatThreadState.CANCELLED },
                                 });
                              }}
                           >
                              <Stack direction="horizontal" gap={1}>
                                 <ThreadStateBadgeMini
                                    person={person}
                                    thread={{ ...thread, state: DataChatThreadState.CANCELLED }}
                                 />
                                 <span>Cancelled</span>
                              </Stack>
                           </Dropdown.Item>
                           {thread?.assignee && (
                              <>
                                 <Dropdown.Divider />
                                 <Dropdown.Item
                                    active={false}
                                    onClick={() => {
                                       patchDataChatThread.mutate({
                                          id: thread.id!,
                                          data: { assigneeId: null },
                                       });
                                    }}
                                 >
                                    Unassign
                                 </Dropdown.Item>
                              </>
                           )}
                        </Dropdown.Menu>
                     </Dropdown>
                  )}
                  {!currentQueryVersion && thread?.creatorWorkspaces && (
                     <>
                        <Dropdown>
                           <Dropdown.Toggle
                              className="border-0"
                              size="sm"
                              variant="outline-secondary"
                           >
                              <IconMenuWorkspaces className="me-1" size="12px" />
                              {
                                 thread.creatorWorkspaces.find((w) => w.id === thread.workspaceId)
                                    ?.name
                              }
                              <BiChevronDown size={16} />
                           </Dropdown.Toggle>
                           <Dropdown.Menu className="fs-10p">
                              {thread.creatorWorkspaces.map((w) => (
                                 <Dropdown.Item
                                    active={thread.workspaceId === w.id}
                                    key={w.id}
                                    onClick={() => {
                                       patchDataChatThread.mutate({
                                          id: thread.id!,
                                          data: { workspaceId: w.id },
                                       });
                                    }}
                                 >
                                    {w.name}
                                 </Dropdown.Item>
                              ))}
                           </Dropdown.Menu>
                        </Dropdown>
                        <Button colorScheme="secondary" onClick={newQuery} variant="ghost">
                           New Query
                        </Button>
                     </>
                  )}
                  {isChatInputVisible ? (
                     <Button type="submit">
                        <IconSendChat size="16px" />
                     </Button>
                  ) : null}
               </Stack>
            )}
         </Stack>
      </Form>
   );
};

const getThreadStateClass = ({ thread, person }: { person: Person; thread: DataChatThread }) => {
   const classPrefix = 'data-chat-state-';
   switch (thread.state) {
      // New
      case DataChatThreadState.NEW:
      case DataChatThreadState.ASK:
         return `${classPrefix}new`;

      // Requestor => Pending; Analyst => Open
      case DataChatThreadState.OPEN:
         return person.id === thread.creator.id ? `${classPrefix}pending` : `${classPrefix}open`;

      // Requestor => Open; Analyst => Pending
      case DataChatThreadState.PENDING:
         return person.id === thread.creator.id ? `${classPrefix}open` : `${classPrefix}pending`;

      // Solved
      case DataChatThreadState.SOLVED:
         return `${classPrefix}solved`;

      // Accepted
      case DataChatThreadState.ACCEPTED:
         return `${classPrefix}accepted`;

      // Cancelled
      case DataChatThreadState.CANCELLED:
         return `${classPrefix}cancelled`;

      // Should not happen
      default:
         return '';
   }
};

const AssigneeAvatar = ({ assignee }: { assignee: Person | null }) => {
   let [tooltip, initials] = ['Unassigned', '--'];
   if (assignee !== null) {
      const name = PersonUtilities.getFullName(assignee);
      tooltip = `Assigned to ${name}`;
      initials = name
         .split(' ')
         .slice(0, 2)
         .map((s) => s[0])
         .join('');
   }

   return (
      <OverlayTrigger overlay={<Tooltip>{tooltip}</Tooltip>}>
         <div
            className="sidebar-card-avatar fs-9p d-flex justify-content-center align-items-center rounded-circle"
            style={{ width: '20px', height: '20px' }}
         >
            {initials}
         </div>
      </OverlayTrigger>
   );
};

export const ChatHistory = ({
   threads,
   currentId,
   onChange,
   title,
   noNewThread,
}: {
   currentId: number | null;
   noNewThread?: boolean;
   onChange: (thread: DataChatThread | null) => void;
   threads: DataChatThread[];
   title: string;
}): JSX.Element => {
   const person = usePerson();
   const isBusinessUser = getIsBusinessUser(person);

   return (
      <Nav className="h-100 overflow-y-auto flex-column" style={{ flexWrap: 'nowrap' }}>
         <Stack
            className="p-2 pb-0 mb-2 justify-content-between align-items-center"
            direction="horizontal"
            gap={2}
         >
            <div className="fw-bold fs-16">{title}</div>
            {!noNewThread && (
               <Button
                  colorScheme="secondary"
                  onClick={() => onChange(null)}
                  size="sm"
                  style={{ height: '100%' }}
               >
                  <IconNewChat size="16px" />
               </Button>
            )}
         </Stack>
         {threads.map((thread, idx) => {
            const latestMessageAtDate = new Date(thread.latestMessageAt);
            const latestMessageAtFormattedLong = formatDate(
               latestMessageAtDate,
               'yyyy-MM-dd hh:mm aa'
            );

            const isActive = thread.id === currentId;

            return (
               <div
                  className={classNames(
                     'card sidebar-card',
                     { 'mt-1': idx !== 0 },
                     getThreadStateClass({ thread, person })
                  )}
                  key={thread.id}
               >
                  <Nav.Link
                     active={isActive}
                     className={classNames({
                        unread:
                           (isBusinessUser &&
                              thread.latestMessageAt > thread.creatorLastViewedAt) ||
                           (!isBusinessUser &&
                              thread.state === DataChatThreadState.OPEN &&
                              (!thread.assignee || thread.assignee.id === person.id)),
                     })}
                     onClick={(e) => {
                        e.preventDefault();
                        if (!isActive) {
                           onChange(thread);
                        }
                     }}
                     style={{ ...(isActive ? { pointerEvents: 'auto' } : {}) }}
                  >
                     <Stack gap={1}>
                        <Stack
                           className="justify-content-between align-items-start"
                           direction="horizontal"
                           gap={1}
                        >
                           <div className="truncate-lines truncate-lines-2">{thread.title}</div>

                           {thread.assignee !== null || !isBusinessUser ? (
                              <AssigneeAvatar assignee={thread.assignee} />
                           ) : null}
                        </Stack>
                        <Stack
                           className="justify-content-between align-items-center"
                           direction="horizontal"
                           gap={1}
                        >
                           <div className="truncate-lines text-muted fs-11p fw-light">
                              <span>{latestMessageAtFormattedLong}</span>
                              <span>
                                 {person.id !== thread.creator?.id
                                    ? ` - ${PersonUtilities.getFullName(thread.creator)}`
                                    : ''}
                              </span>
                           </div>
                           <ThreadStateLabel person={person} thread={thread} />
                        </Stack>
                     </Stack>
                  </Nav.Link>
               </div>
            );
         })}
      </Nav>
   );
};

export const Chat = ({
   greeting,
   renderQueries,
   suggestions,
   thread,
   newChatFooter,
   noNewThread,
   onNewThread,
   currentQueryVersion,
}: {
   currentQueryVersion?: QueryVersion;
   greeting?: string;
   newChatFooter?: React.ReactNode;
   noNewThread?: boolean;
   onNewThread?: (thread: DataChatThread) => void;
   renderQueries: (queries: DataChatMessageQuery[]) => React.ReactNode;
   suggestions?: ChatSuggestion[];
   thread: DataChatThread | null;
}): JSX.Element => {
   const [messages, setMessages] = useState<Partial<DataChatMessage>[]>(thread?.messages ?? []);
   useEffect(() => {
      setMessages(thread?.messages ?? []);
   }, [thread?.messages]);
   const runaResponding =
      (!thread?.state ||
         [DataChatThreadState.NEW, DataChatThreadState.ASK].includes(thread?.state)) &&
      messages.length > 0 &&
      messages[messages.length - 1].role === DataChatMessageRole.CREATOR;
   const scrollRef = useRef<HTMLDivElement | null>(null);
   const person = usePerson();
   const isBusinessUser = getIsBusinessUser(person);
   const patchDataChatThread = usePatchDataChatThread();

   const addDataChatMessage = useAddDataChatMessage();
   const onSend: SendCallback = (message, newThread) => {
      let newState = newThread?.state;
      if (!isBusinessUser) {
         newState ??= DataChatThreadState.PENDING;
      }
      setMessages([
         ...messages,
         {
            ...message,
            role: isBusinessUser ? DataChatMessageRole.CREATOR : DataChatMessageRole.ANALYST,
         },
      ]);
      addDataChatMessage.mutate(
         {
            id: newThread?.id ?? message.threadId ?? thread?.id,
            message: {
               content: message.content,
               isInternal: message.isInternal,
               queryVersionId: message.queryVersionId,
            },
            state: newState,
         },
         {
            onSuccess: (newThread, oldThread) => {
               if (!oldThread.id) {
                  onNewThread?.(newThread);
               }
            },
         }
      );
   };

   // Scroll to the bottom when Runa responds
   useEffect(() => {
      scrollRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
   }, [runaResponding, messages.length]);

   // Update view teimstamp
   const patchDataChatThreadMutate = patchDataChatThread.mutate;
   useEffect(() => {
      if (!isBusinessUser) return;
      if (!thread?.latestMessageAt) return;
      if (thread.latestMessageAt > thread.creatorLastViewedAt) {
         patchDataChatThreadMutate({
            id: thread.id!,
            data: { viewedByCreator: true },
         });
      }
   }, [
      thread?.id,
      thread?.latestMessageAt,
      thread?.creatorLastViewedAt,
      patchDataChatThreadMutate,
      isBusinessUser,
   ]);

   if (messages.length === 0) {
      if (noNewThread) {
         return (
            <div className="h-100 d-flex justify-content-center align-items-center">
               Select a thread.
            </div>
         );
      } else {
         return (
            <ChatStart
               footer={newChatFooter}
               greeting={greeting}
               onSend={onSend}
               suggestions={suggestions}
            />
         );
      }
   }
   const lastMessage = messages[messages.length - 1];
   const responses = lastMessage.metadata?.success
      ? lastMessage.metadata?.data.suggestedResponses
      : undefined;
   return (
      <div className="h-100 d-flex flex-column justify-content-end position-relative">
         {thread && isBusinessUser ? (
            <div className="position-absolute top-0 start-0 py-3 px-4" style={{ zIndex: 1 }}>
               <ThreadStateBadge person={person} thread={thread} />
            </div>
         ) : null}
         <Stack className="py-3 px-4 pb-0 overflow-auto flex-grow-0" gap={3}>
            {messages.map((message, index) => (
               <Stack className="flex-grow-0" gap={2} key={index}>
                  <Stack
                     gap={2}
                     ref={!runaResponding && index === messages.length - 1 ? scrollRef : undefined}
                  >
                     {message.content && (
                        <ChatBubble
                           message={message}
                           person={
                              message.role === DataChatMessageRole.CREATOR
                                 ? thread?.creator ?? person
                                 : message.role === DataChatMessageRole.SYSTEM
                                 ? {
                                      firstName: 'runQL AI',
                                   }
                                 : message.person ?? person
                           }
                           renderQueries={renderQueries}
                           side={
                              isBusinessUser === (message.role === DataChatMessageRole.CREATOR)
                                 ? 'ours'
                                 : 'theirs'
                           }
                        />
                     )}
                  </Stack>
               </Stack>
            ))}
            {runaResponding && <Loader color="#6366f1" size={6} />}
            {runaResponding && <div ref={scrollRef} />}
         </Stack>
         <Stack className="flex-grow-0 py-3 px-5" gap={2}>
            <ChatInput
               currentQueryVersion={currentQueryVersion}
               onSend={onSend}
               suggestions={responses}
               thread={thread}
            />
         </Stack>
      </div>
   );
};

export default Chat;
