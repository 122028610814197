import { Stack } from 'react-bootstrap';
import { AppleLoginButton, MicrosoftLoginButton } from 'react-social-login-buttons';

const VERSION_PLACEHOLDER = 'VERSION';

const getVersion = async (url: string) => {
   const response = await fetch(url);
   if (!response.ok) {
      throw new Error(`Network error: ${response.statusText}`);
   }
   const textData = await response.text();

   // Simple regex to extract the version number
   const versionMatch = textData.match(/version:\s*(.*)/);
   if (versionMatch && versionMatch[1]) {
      return versionMatch[1].trim();
   } else {
      throw new Error('Version not found');
   }
};

const download = async (filename: string, ymlSuffix: string = '') => {
   filename = filename.replace(
      VERSION_PLACEHOLDER,
      await getVersion(`/download/latest${ymlSuffix}.yml`)
   );
   const url = `https://app.runql.com/download/${filename}`;
   const anchor = document.createElement('a');
   anchor.href = url;
   anchor.download = filename;
   document.body.appendChild(anchor);
   anchor.click();
   document.body.removeChild(anchor);
};

export const DownloadLinks = (): React.JSX.Element => {
   return (
      <Stack>
         <AppleLoginButton
            align="center"
            className="button-social-signin"
            onClick={() => download(`runQL-${VERSION_PLACEHOLDER}-arm64.dmg`, '-mac')}
         >
            <div className="text-center fw-normal plausible-event-name--download-mac-apple">
               Download for Apple Silicon Mac
            </div>
         </AppleLoginButton>
         <AppleLoginButton
            align="center"
            className="button-social-signin"
            onClick={() => download(`runQL-${VERSION_PLACEHOLDER}.dmg`, '-mac')}
         >
            <div className="text-center fw-normal plausible-event-name--download-mac-intel">
               Download for Intel Mac
            </div>
         </AppleLoginButton>
         <MicrosoftLoginButton
            align="center"
            className="button-social-signin plausible-event-name--download-windows"
            onClick={() => download(`runQL-${VERSION_PLACEHOLDER}.exe`)}
         >
            <div className="text-center fw-normal">Download for Windows</div>
         </MicrosoftLoginButton>
      </Stack>
   );
};
