export * from './QueryBuilder';
export * from './api';
export * from './dataConnection';
export * from './error';
export * from './formatters';
export * from './icons';
export * from './kb';
export * from './pubsub';
export * from './queryParser';
export * from './sigmaHelpers';
export * from './sleep';
export * from './title';
export * from './typeGuards';

export const TEXT_MAX_LENGTH = 65535;

export function assertNever(x: never): never {
   throw new Error('Unexpected object: ' + x);
}

export function arraysEqual<T>(arr1: T[], arr2: T[]): boolean {
   if (arr1.length !== arr2.length) {
      return false;
   }
   return arr1.every((value, index) => value === arr2[index]);
}
