import { useMemo, useState, useEffect } from 'react';
import { Badge, Collapse, Nav, Stack, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BiChevronDown, BiChevronRight } from 'react-icons/bi';
import { useHotkeys } from 'react-hotkeys-hook';

import { SERVICE_PLANS } from '@runql/util';

import { Page, Tab, Button, queryTags } from '../components';
import QueryReviewItem from '../components/QueryReviewItem';
import { QueryVersion, PersonUtilities } from '../entities';
import { type PanelTabs, QueryProvider, useOrg, useRunQueries, useWorkspaces } from '../hooks';
import { UpgradeModal } from '../pages';
import { QueryPanelProvider } from '../hooks/QueryPanelContext';
import { IconInformation } from '../utilities/icons';
import { getShortDateTimeString } from '../utilities/formatters';

const QueryHeader = ({ queryVersion }: { queryVersion: QueryVersion }): JSX.Element => {
   const tags = useMemo(() => queryTags(queryVersion), [queryVersion]);
   return (
      <Stack gap={1}>
         <Stack className="align-items-center" direction="horizontal" gap={2}>
            <div className="truncate-lines">{queryVersion.title}</div>
            {queryVersion.description && (
               <OverlayTrigger
                  overlay={<Tooltip>{queryVersion.description}</Tooltip>}
                  placement="auto"
               >
                  <div className="lh-1">
                     <IconInformation size={14} />
                  </div>
               </OverlayTrigger>
            )}
         </Stack>
         {tags.length > 0 && (
            <Stack className="truncate-lines" direction="horizontal" gap={1}>
               {tags.map((tag, i) => (
                  <Badge bg="tag" key={i} pill>
                     {tag}
                  </Badge>
               ))}
            </Stack>
         )}

         {(queryVersion?.modified || queryVersion?.createdByPerson) && (
            <Stack className="fs-9p text-muted truncate-lines" direction="horizontal" gap={1}>
               {queryVersion?.modified && <>{getShortDateTimeString(queryVersion?.modified)}</>}
               {queryVersion?.createdByPerson && (
                  <>
                     {' – '}
                     {PersonUtilities.getFullName(
                        queryVersion?.aiSuggestion
                           ? {
                                firstName: 'runQL AI',
                             }
                           : queryVersion?.query?.createdByPerson ?? queryVersion.createdByPerson
                     )}
                  </>
               )}
               {queryVersion?.createdByPerson &&
                  queryVersion?.query?.createdByPersonId &&
                  queryVersion.createdByPersonId !== queryVersion.query.createdByPersonId && (
                     <> (modified by {PersonUtilities.getFullName(queryVersion.createdByPerson)})</>
                  )}
            </Stack>
         )}
      </Stack>
   );
};

type WorkspaceQueryVersions = {
   id: number;
   name: string;
   queryVersions: QueryVersion[];
};

const SideNav = ({
   workspaceQueryVersions,
   setActiveQueryVersion,
   activeQueryVersion,
}: {
   activeQueryVersion: QueryVersion | null;
   setActiveQueryVersion: (queryVersion: QueryVersion) => void;
   workspaceQueryVersions: WorkspaceQueryVersions[];
}) => {
   const [show, setShow] = useState(
      new Set<Number>(workspaceQueryVersions.length > 0 ? [workspaceQueryVersions[0].id] : [])
   );

   // Expand the workspace containing the active query version
   useEffect(() => {
      if (!activeQueryVersion) return;

      const workspace = workspaceQueryVersions.find((w) =>
         w.queryVersions.some((qv) => qv.id === activeQueryVersion.id)
      );

      if (workspace && !show.has(workspace.id)) {
         setShow((s) => new Set([...s, workspace.id]));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps -- Not including show so it doesn't stop the user from closing the workspace
   }, [activeQueryVersion, workspaceQueryVersions]);

   return (
      <Nav className="flex-column navigation-menu-width pt-1">
         {workspaceQueryVersions.map(({ id, name, queryVersions }) => (
            <div
               key={id}
               onClick={() =>
                  show.has(id)
                     ? setShow(new Set([...show].filter((i) => i !== id)))
                     : setShow(new Set([...show, id]))
               }
            >
               <Nav.Link
                  className="py-1 ps-1 pe-2 fw-600 fs-13p border-bottom-line top-nav-bar-location-title hover-highlight d-flex align-items-center overflow-hidden text-truncate"
                  key={id}
               >
                  {show.has(id) ? <BiChevronDown size={16} /> : <BiChevronRight size={16} />}
                  {name}
               </Nav.Link>
               <Collapse in={show.has(id)}>
                  <Stack gap={1}>
                     {queryVersions.map((version) => (
                        <div
                           className="px-1"
                           key={version.id}
                           onClick={(e) => {
                              e.stopPropagation();
                              setActiveQueryVersion(version);
                           }}
                        >
                           <div className="card sidebar-card">
                              <Nav.Link
                                 active={version.id === activeQueryVersion?.id}
                                 className="d-flex flex-column"
                              >
                                 <QueryHeader queryVersion={version} />
                              </Nav.Link>
                           </div>
                        </div>
                     ))}
                  </Stack>
               </Collapse>
            </div>
         ))}
      </Nav>
   );
};

export const QueryReviewPage = ({
   children,
   defaultTab,
   diffVersion,
   extraActions,
   extraTabs,
   hideTabs,
   queryVersions,
   upgradeMessage,
   noItemsMessage,
   activeQueryVersion,
   setActiveQueryVersion,
}: {
   activeQueryVersion: QueryVersion | null;
   children?: React.ReactNode;
   defaultTab: PanelTabs;
   diffVersion?: (queryVersion: QueryVersion) => QueryVersion | undefined;
   extraActions?: (queryVersion: QueryVersion) => React.ReactNode[];
   extraTabs?: (queryVersion?: QueryVersion) => Tab[];
   hideTabs?: {
      comments?: boolean;
      logs?: boolean;
   };
   noItemsMessage: string;
   queryVersions: QueryVersion[];
   setActiveQueryVersion: (queryVersion: QueryVersion | null) => void;
   upgradeMessage: string;
}) => {
   const [showUpgradeModal, setShowUpgradeModal] = useState(false);
   const org = useOrg();
   const workspaces = useWorkspaces();
   const workspaceQueryVersions: WorkspaceQueryVersions[] = useMemo(
      () =>
         Array.from(Map.groupBy(queryVersions, (v) => v.query?.workspaceId ?? 0).entries()).map(
            ([id, queryVersions]) => ({
               id,
               name: workspaces?.find((w) => w.id === id)?.name ?? `Workspace ${id}`,
               queryVersions,
            })
         ),
      [queryVersions, workspaces]
   );

   const { modals } = useRunQueries();

   const queryVersionsOrdered = useMemo(
      () => workspaceQueryVersions.flatMap(({ queryVersions }) => queryVersions),
      [workspaceQueryVersions]
   );
   const currentIndex = useMemo(() => {
      if (!activeQueryVersion || queryVersionsOrdered.length === 0) return -1;
      return queryVersionsOrdered.findIndex((qv) => qv.id === activeQueryVersion.id);
   }, [activeQueryVersion, queryVersionsOrdered]);

   // Add keyboard navigation using useHotkeys
   useHotkeys(
      ['ArrowDown', 'ArrowRight', 'j'],
      () => {
         if (currentIndex === -1) return;
         const nextIndex = (currentIndex + 1) % queryVersionsOrdered.length;
         setActiveQueryVersion(queryVersionsOrdered[nextIndex]);
      },
      { enabled: currentIndex !== -1 && queryVersionsOrdered.length > 1 },
      [currentIndex, queryVersionsOrdered]
   );

   useHotkeys(
      ['ArrowUp', 'ArrowLeft', 'k'],
      () => {
         if (currentIndex === -1) return;
         const nextIndex =
            (currentIndex - 1 + queryVersionsOrdered.length) % queryVersionsOrdered.length;
         setActiveQueryVersion(queryVersionsOrdered[nextIndex]);
      },
      { enabled: currentIndex !== -1 && queryVersionsOrdered.length > 1 },
      [currentIndex, queryVersionsOrdered]
   );

   useEffect(() => {
      if (queryVersions.length === 0) {
         setActiveQueryVersion(null);
         return;
      }

      if (activeQueryVersion === null) {
         setActiveQueryVersion(queryVersions[0]);
         return;
      }

      const match = queryVersions.find((qv) => qv.id === activeQueryVersion.id);

      if (!match) {
         setActiveQueryVersion(queryVersions[0]);
         return;
      }
   }, [queryVersions, activeQueryVersion, setActiveQueryVersion]);

   const reviewAllowed = useMemo(() => {
      return org?.plan !== undefined && SERVICE_PLANS[org.plan].certification;
   }, [org]);

   // Loading...
   if (!org) {
      // Otherwise, the upgrade message will flash briefly.
      return null;
   }

   if (!reviewAllowed) {
      return (
         <Page>
            <UpgradeModal onClose={() => setShowUpgradeModal(false)} show={showUpgradeModal} />
            <p className="pt-2" style={{ fontSize: '12px', color: '#4c82f7', fontWeight: 'bold' }}>
               {upgradeMessage} Available in <strong>team</strong> and <strong>enterprise</strong>{' '}
               plans.
            </p>
            <Button onClick={() => setShowUpgradeModal(true)}>Upgrade Now</Button>
         </Page>
      );
   }

   // Loading...
   if (queryVersions.length > 0 && activeQueryVersion === null) {
      return null;
   }

   return (
      <Page
         nav={
            workspaceQueryVersions.length ? (
               <SideNav
                  activeQueryVersion={activeQueryVersion}
                  setActiveQueryVersion={setActiveQueryVersion}
                  workspaceQueryVersions={workspaceQueryVersions}
               />
            ) : undefined
         }
      >
         <>
            {children}
            {activeQueryVersion !== null ? (
               <QueryProvider queryVersion={activeQueryVersion}>
                  <QueryPanelProvider defaultTab={defaultTab}>
                     <QueryReviewItem
                        diffVersion={diffVersion}
                        extraActions={extraActions}
                        extraTabs={extraTabs}
                        hideTabs={hideTabs}
                        version={activeQueryVersion}
                     />
                  </QueryPanelProvider>
               </QueryProvider>
            ) : (
               <div className="d-flex h-100 align-items-center justify-content-center text-muted">
                  {noItemsMessage}
               </div>
            )}
            {modals}
         </>
      </Page>
   );
};

export default QueryReviewPage;
