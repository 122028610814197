import React, {
   forwardRef,
   useContext,
   useEffect,
   useImperativeHandle,
   useRef,
   useState,
   useMemo,
} from 'react';

import {
   autocompletion,
   completionStatus,
   acceptCompletion,
   closeCompletion,
   moveCompletionSelection,
} from '@codemirror/autocomplete';
import { EditorState, Prec } from '@codemirror/state';
import { GutterMarker, Tooltip, gutter, showTooltip, tooltips } from '@codemirror/view';
import { StateField } from '@codemirror/state';
import { json } from '@codemirror/lang-json';
import { cypher } from '@codemirror/legacy-modes/mode/cypher';
import { MySQL, PostgreSQL, MSSQL, StandardSQL, SQLDialect, sql } from '@codemirror/lang-sql';
import { python } from '@codemirror/lang-python';
import {
   bracketMatching,
   defaultHighlightStyle,
   StreamLanguage,
   syntaxHighlighting,
} from '@codemirror/language';
import { EditorView } from '@codemirror/view';
import { keymap, KeyBinding } from '@codemirror/view';
import { insertTab, indentLess } from '@codemirror/commands';

import { tags as t } from '@lezer/highlight';
import { createTheme } from '@uiw/codemirror-themes';
import CodeMirror, { basicSetup, ReactCodeMirrorRef } from '@uiw/react-codemirror';

import { BiCheck, BiCopy } from 'react-icons/bi';
import { FaPlay } from 'react-icons/fa';
import { DBMS } from '../../enums/dbms';
import { ThemeContext } from '../ThemeContext';
import { copyToClipboard } from '../../utilities/clipboard';
import Button from '../Button';
import ReactDOMServer from 'react-dom/server';
import { useDebounce, useStableCallback } from '../../hooks';

/**
 * This is not where you edit the display settings for code mirror.
 * We set some custom theme items here as the default so we don't get codemirror defaults.
 * The styles array here forces the classes to be attributed to the different words - keywords, bool, number, etc.  Without this those special words do not get a class name.
 * To customize the look and feel of the text in code mirror use the css in theme-custom.scss
 */
const lightTheme = createTheme({
   theme: 'light',
   settings: {
      background: 'rgba(36, 50, 72, 0.005)',
      foreground: '#0E1927',
      lineHighlight: '#0b0f19',
      gutterBackground: 'rgba(36, 50, 72, 0.005)',
      gutterForeground: '#9397ad',
      fontFamily: '"JetBrains Mono", monospace',
   },
   styles: [
      { tag: t.bracket, color: '#569cd6' },
      { tag: t.comment, color: '#9397ad' },
      { tag: t.variableName, color: '#4c82f7' },
      { tag: t.quote, color: '#0b0f19' },
      { tag: t.moduleKeyword, color: '#fd7e14' },
      { tag: [t.string, t.special(t.brace)], color: '#6a9955' },
      { tag: t.number, color: '#4c82f7' },
      { tag: t.bool, color: '#4c82f7' },
      { tag: t.null, color: '#4c82f7' },
      { tag: t.keyword, color: '#569cd6' },
      { tag: t.operator, color: '#fd7e14' },
      { tag: t.className, color: '#569cd6' },
      { tag: t.definition(t.typeName), color: '#569cd6' },
      { tag: t.typeName, color: '#fd7e14' },
      { tag: t.angleBracket, color: '#0b0f19' },
      { tag: t.tagName, color: '#569cd6' },
      { tag: t.attributeName, color: '#4c82f7' },
      { tag: t.propertyName, color: '#198754' },
      { tag: t.annotation, color: '#fd7e14' },
   ],
});

const darkTheme = createTheme({
   theme: 'dark',
   settings: {
      background: '#0b0f19',
      foreground: 'transparent',
      lineHighlight: '#0b0f19',
      gutterBackground: '#0b0f19',
      gutterForeground: '#565973',
      fontFamily: '"JetBrains Mono", monospace',
   },
   styles: [
      { tag: t.bracket, color: '#569cd6' },
      { tag: t.comment, color: '#565973' },
      { tag: t.variableName, color: '#4c82f7' },
      { tag: t.quote, color: '#0b0f19' },
      { tag: t.moduleKeyword, color: '#fd7e14' },
      { tag: [t.string, t.special(t.brace)], color: '#198754' },
      { tag: t.number, color: '#4c82f7' },
      { tag: t.bool, color: '#4c82f7' },
      { tag: t.null, color: '#4c82f7' },
      { tag: t.keyword, color: '#569cd6' },
      { tag: t.operator, color: '#fd7e14' },
      { tag: t.className, color: '#569cd6' },
      { tag: t.definition(t.typeName), color: '#569cd6' },
      { tag: t.typeName, color: '#fd7e14' },
      { tag: t.angleBracket, color: '#0b0f19' },
      { tag: t.tagName, color: '#569cd6' },
      { tag: t.attributeName, color: '#4c82f7' },
      { tag: t.propertyName, color: '#198754' },
      { tag: t.annotation, color: '#fd7e14' },
   ],
});

// The autocomplete menu will show but be in a pending state if new
// characters came in. In this state, you can't perform completion operations,
// so we retry until it's active.
const runWhenActive =
   (onActive: (e: EditorView) => boolean, onInactive?: (e: EditorView) => boolean) =>
   (e: EditorView) => {
      const run = () => {
         switch (completionStatus(e.state)) {
            case 'active':
               return onActive(e);
            case 'pending':
               setTimeout(run, 100);
               return true;
            default:
               return onInactive?.(e) ?? false;
         }
      };
      return run();
   };

function getExtensionList({
   dialect,
   onRun,
   schema,
   saveSnippet,
   snippetSaved,
   queryStartLines,
}: {
   dialect?: DBMS;
   onRun?: (line: number) => void;
   queryStartLines: React.MutableRefObject<number[]>;
   saveSnippet?: (snippet: string) => void;
   schema?: Record<string, string[]>;
   snippetSaved?: string;
}) {
   const keyBindings: readonly KeyBinding[] = [
      // Include default autocomplete keybindngs, but replace Enter with Tab
      { key: 'Escape', run: closeCompletion },
      { key: 'ArrowDown', run: runWhenActive(moveCompletionSelection(true)) },
      { key: 'ArrowUp', run: runWhenActive(moveCompletionSelection(false)) },
      { key: 'PageDown', run: runWhenActive(moveCompletionSelection(true, 'page')) },
      { key: 'PageUp', run: runWhenActive(moveCompletionSelection(false, 'page')) },
      {
         key: 'Enter',
         run: runWhenActive(acceptCompletion),
      },
      {
         key: 'Tab',
         preventDefault: true,
         shift: indentLess,
         run: runWhenActive(acceptCompletion, insertTab),
      },
      {
         key: 'Meta-S',
         run: (view) => {
            if (!saveSnippet) return false;
            const snippet = view.state.sliceDoc(
               view.state.selection.main.from,
               view.state.selection.main.to
            );
            if (!snippet) return false;
            saveSnippet(snippet);
            return true;
         },
      },
      {
         any: (view, event) => {
            if (event.altKey || event.metaKey) {
               view.dom.dispatchEvent(new KeyboardEvent(event.type, event));
               return event.key === 'Enter';
            }
            return false;
         },
      },
   ];

   const cursorTooltipField = StateField.define<readonly Tooltip[]>({
      create: getCursorTooltips,

      update(tooltips, tr) {
         if (!tr.docChanged && !tr.selection) return tooltips;
         return getCursorTooltips(tr.state);
      },

      provide: (f) => showTooltip.computeN([f], (state) => state.field(f)),
   });

   function getCursorTooltips(state: EditorState): readonly Tooltip[] {
      if (!saveSnippet) return [];
      return state.selection.ranges
         .filter((range) => !range.empty)
         .map((range) => {
            return {
               pos: range.from,
               above: true,
               strictSide: true,
               arrow: true,
               create: () => {
                  const snippet = state.sliceDoc(
                     state.selection.main.from,
                     state.selection.main.to
                  );
                  let dom = document.createElement('div');
                  dom.className = 'cm-tooltip-cursor save-snippet-cursor';
                  if (snippet === snippetSaved) {
                     dom.textContent = `Snippet saved!`;
                  } else {
                     dom.textContent = `Click here to save snippet`;
                     dom.onclick = () => {
                        saveSnippet?.(snippet);
                     };
                  }
                  return { dom };
               },
            };
         });
   }

   const cmExtensions = [
      basicSetup({
         foldGutter: false,
         dropCursor: false,
         allowMultipleSelections: false,
         indentOnInput: false,
         closeBrackets: true,
         tabSize: 4,
         highlightSelectionMatches: true,
      }),
      bracketMatching(),
      autocompletion({ defaultKeymap: false }),
      EditorView.lineWrapping,
      // Apply keybindings with the highest precedence
      Prec.highest([keymap.of(keyBindings)]),
      cursorTooltipField,
      tooltips({ parent: document.body }),
   ];

   switch (dialect) {
      case DBMS.MySQL:
         cmExtensions.push(
            sql({
               dialect: SQLDialect.define({ ...MySQL.spec, caseInsensitiveIdentifiers: true }),
               upperCaseKeywords: true,
               schema: schema,
            })
         );
         break;
      case DBMS.MSSQL:
         cmExtensions.push(
            sql({
               dialect: SQLDialect.define({ ...MSSQL.spec, caseInsensitiveIdentifiers: true }),
               upperCaseKeywords: true,
               schema: schema,
            })
         );
         break;
      case DBMS.Postgres:
      case DBMS.Redshift:
         cmExtensions.push(
            sql({
               dialect: SQLDialect.define({ ...PostgreSQL.spec, caseInsensitiveIdentifiers: true }),
               upperCaseKeywords: true,
               schema: schema,
            })
         );
         break;
      case DBMS.Hugging_Face:
         cmExtensions.push(json());
         break;
      case DBMS.Neo4j:
         cmExtensions.push([
            StreamLanguage.define(cypher),
            syntaxHighlighting(defaultHighlightStyle, { fallback: true }),
         ]);
         break;
      case DBMS.Python:
         cmExtensions.push(python());
         break;
      case undefined:
      default:
         cmExtensions.push(
            sql({
               dialect: SQLDialect.define({
                  ...StandardSQL.spec,
                  caseInsensitiveIdentifiers: true,
               }),
               upperCaseKeywords: true,
               schema: schema,
            })
         );
         break;
   }

   cmExtensions.push(
      gutter({
         lineMarker(view, line) {
            const lineNumber = view.state.doc.lineAt(line.from).number;
            return new (class extends GutterMarker {
               toDOM() {
                  const container = document.createElement('div');
                  container.style.width = '18px';
                  if (queryStartLines?.current?.includes(lineNumber))
                     container.innerHTML = ReactDOMServer.renderToStaticMarkup(
                        <Button style={{ height: '10px', width: '25px' }} variant="link">
                           <FaPlay size="8px" />
                        </Button>
                     );
                  return container;
               }
            })();
         },
         domEventHandlers: {
            mousedown(view, line) {
               const lineNumber = view.state.doc.lineAt(line.from).number;
               onRun?.(lineNumber);
               return true;
            },
         },
      })
   );

   return cmExtensions;
}

export interface CodeEditorMethods {
   insert: (s: string) => void;
}

/**
 * A pre-configured Code Mirror window
 */
export const CodeEditor = forwardRef(
   (
      {
         dialect,
         onChange,
         query,
         readOnly,
         schema,
         saveSnippet,
         onFocus,
         onRun,
         onSelection,
      }: {
         dialect?: DBMS;
         onChange?: (value: string) => void;
         onFocus?: () => void;
         onRun?: (query?: string) => void;
         onSelection?: (selection?: string) => void;
         query?: string;
         readOnly?: boolean;
         saveSnippet?: (snippet: string) => void;
         schema?: Record<string, string[]>;
      },
      ref
   ): JSX.Element => {
      const themeContext = useContext(ThemeContext);
      const lastSelection = useRef<string>();
      // We keep queryStartLines both in state and as a ref so the extension
      // doesn't have a closure on a state variable but we also have a way to
      // trigger a re-render.
      const [, setQueryStartLines] = useState<number[]>([]);
      const queryStartLinesRef = useRef<number[]>([]);
      const querySections = useRef<Record<number, string>>({});

      const refs = React.useRef<ReactCodeMirrorRef>({});
      useImperativeHandle(ref, () => {
         return {
            insert(s: string) {
               editorView?.dispatch(editorView.state.replaceSelection(s));
            },
         };
      });
      const [showCopySuccess, setShowCopySuccess] = useState(false);
      const [editorView, setEditorView] = useState<EditorView>();

      const debouncedQuery = useDebounce(query ?? '', 200);

      // Move the cursor to the end of the query when first added
      const cursorMoved = useRef<boolean>();
      useEffect(() => {
         if (cursorMoved.current || !editorView) return;
         cursorMoved.current = true;
         if (!query) return;
         editorView.dispatch({
            selection: {
               anchor: editorView.state.doc.length,
               head: editorView.state.doc.length,
            },
         });
      }, [query, editorView]);

      // calculates where run buttons on sidebar will be located.
      useEffect(() => {
         const lines = debouncedQuery.split('\n');
         const sections: string[] = [];
         const startingLines: number[] = [];
         let startingLine: number | null = null;
         let statement = '';
         for (let index = 0; index < lines.length; index++) {
            const line = lines[index];
            const trimmedLine = line.replace(/(^|\s)(--|#).*/, '').trim();
            // Blanks and comments between queries
            if (startingLine === null && trimmedLine === '') continue;
            // Start of new query
            if (startingLine === null) {
               startingLine = index + 1;
            }
            if (startingLine !== null) {
               statement += line + '\n';
            }
            // End of query
            if (trimmedLine.match(/;$/) || index === lines.length - 1) {
               sections[startingLine] = statement;
               startingLines.push(startingLine!);
               startingLine = null;
               statement = '';
            }
         }
         setQueryStartLines(startingLines);
         queryStartLinesRef.current = startingLines;
         querySections.current = sections;
      }, [debouncedQuery]);

      const onEditorChange = React.useCallback(
         (value: string) => {
            onChange?.(value);
         },
         [onChange]
      );

      const lastCallRef = useRef<number | null>(null);
      const pendingChangeRef = useRef<string | null>(null);
      const timeoutRef = useRef<NodeJS.Timeout | null>(null); // Stores the timeout handle
      const THROTTLE_DELAY_MS = 1000;

      const handleEditorChange = (value: string) => {
         const now = Date.now();
         // call onChange if first time, or throttle delay has passed
         if (lastCallRef.current === null || now - lastCallRef.current > THROTTLE_DELAY_MS) {
            if (timeoutRef.current) {
               clearTimeout(timeoutRef.current);
               timeoutRef.current = null;
            }
            onEditorChange(value);
            lastCallRef.current = now;
            pendingChangeRef.current = null;
         } else {
            pendingChangeRef.current = value;
            // Schedule sending the pending change if not already scheduled
            if (!timeoutRef.current) {
               timeoutRef.current = setTimeout(() => {
                  if (pendingChangeRef.current !== null) {
                     onEditorChange(pendingChangeRef.current);
                     pendingChangeRef.current = null;
                     lastCallRef.current = Date.now();
                  }
                  timeoutRef.current = null;
               }, THROTTLE_DELAY_MS - (now - lastCallRef.current));
            }
         }
      };

      const handleBlur = () => {
         // dispatch the pending change value if editor loses focus
         if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
         }
         if (pendingChangeRef.current !== null) {
            onEditorChange(pendingChangeRef.current);
            pendingChangeRef.current = null;
         }
      };

      const copy = () => {
         if (query) {
            showAlert();
            copyToClipboard(query);
         }
      };

      const showAlert = () => {
         setShowCopySuccess(true);

         setTimeout(() => {
            setShowCopySuccess(false);
         }, 2000);
      };

      const [snippetSaved, setSnippetSaved] = useState<string>();
      const onRunStable = useStableCallback((lineNumber: number) => {
         const query = querySections.current[lineNumber];
         // Only run if we found a section. Otherwise, passing
         // undefined would run everything.
         if (query) {
            onRun?.(querySections.current[lineNumber]);
         }
      });
      const extensions = useMemo(() => {
         return getExtensionList({
            dialect,
            onRun: onRunStable,
            queryStartLines: queryStartLinesRef,
            saveSnippet: saveSnippet
               ? (s: string) => {
                    setSnippetSaved(s);
                    saveSnippet(s);
                    editorView?.focus();
                 }
               : undefined,
            schema,
            snippetSaved,
         });
      }, [dialect, editorView, onRunStable, saveSnippet, schema, snippetSaved]);

      return (
         <div style={{ position: 'relative' }}>
            <div className="">
               <CodeMirror
                  autoFocus={!readOnly && !cursorMoved.current}
                  className={`queryRun ${readOnly ? 'readOnly' : 'editable'}`}
                  extensions={extensions}
                  indentWithTab={false}
                  onBlur={handleBlur}
                  onChange={handleEditorChange}
                  onCreateEditor={(editorView) => {
                     setEditorView(editorView);
                  }}
                  onFocus={() => onFocus?.()}
                  onUpdate={(editor) => {
                     if (editor.state.selection.main.empty) {
                        if (lastSelection.current) {
                           lastSelection.current = undefined;
                           onSelection?.();
                        }
                     } else {
                        const snippet = editor.state.sliceDoc(
                           editor.state.selection.main.from,
                           editor.state.selection.main.to
                        );
                        if (snippet !== lastSelection.current) {
                           lastSelection.current = snippet;
                           onSelection?.(snippet);
                        }
                     }
                  }}
                  placeholder="Write a query…"
                  readOnly={readOnly}
                  ref={refs}
                  theme={themeContext?.mode === 'dark' ? darkTheme : lightTheme}
                  value={query ?? ''}
               />
            </div>
            <div style={{ position: 'absolute', top: 0, right: 0 }}>
               <button
                  className="btn btn-xs btn-link btn-secondary bg-transparent copy-icon p-2 border-0"
                  onClick={copy}
                  title="Copy"
               >
                  {showCopySuccess ? <BiCheck size={16} /> : <BiCopy size={16} />}
               </button>
            </div>
         </div>
      );
   }
);
export default CodeEditor;
