import { inject, injectable } from 'inversify';
import { QueryFeedbackUpdate } from '../entities';
import { ApiService } from '.';
import { TYPES } from '../types';

const path = '/v1/queryFeedback';

@injectable()
export class QueryFeedbackService {
   private apiService: ApiService;

   constructor(@inject(TYPES.apiService) apiService: ApiService) {
      this.apiService = apiService;
   }

   async update(body: QueryFeedbackUpdate) {
      return (await this.apiService.put(path, body)) ?? undefined;
   }
}
