import _debounce from 'lodash/debounce';
import { useCallback, useMemo, useState } from 'react';
import { Button, Stack } from 'react-bootstrap';

import { StepType } from '../entities';
import { FEDERATED_DBMS } from '../enums';
import { useCurrentStep, useExtension } from '../hooks';
import { CreateTableModalProvider } from './CreateTableModal';
import { DeleteTableModalProvider } from './DeleteTableModal';
import ManageWorkspaceConnections from './ManageWorkspaceConnections';
import {
   SchemaGroupNode,
   SchemaTreeProvider,
   sortItems as sortSchemaRootGroups,
   useSchemaRoots,
} from './SchemaTree';
import { SearchInput } from './UI';
import LoadingError from './UI/LoadingError';
import LoadingSpinner from './UI/LoadingSpinner';

import { DBMS_SCHEMA_SELECT_SUPPORTED } from '@runql/util';
import type { SchemaRoot, DataConnection as SchemaTreeDataConnection } from './SchemaTree';
import { IconPlus } from '../utilities';

export const Navigator = ({ workspaceId }: { workspaceId: number }) => {
   const extension = useExtension();
   const [showManageWorkspaceConnectionsModal, setShowManageWorkspaceConnectionsModal] =
      useState(false);

   const [searchText, setSearchText] = useState('');

   const [currentStep] = useCurrentStep();
   // If in "federated" mode, we need to display the runQL-generated catalog name for data
   // connections that do not use catalogs so users know how to reference the data connection in the
   // federated
   const isFederatedMode = currentStep?.queryStep.type === StepType.FEDERATED;

   const schemaRootsResult = useSchemaRoots({ workspaceId });
   const schemaRootGroupEntries = useMemo(
      () =>
         Object.entries(
            (schemaRootsResult.data ?? []).reduce<
               Record<
                  string,
                  {
                     data: SchemaRoot[];
                     dataConnection: SchemaTreeDataConnection;
                     name: string;
                     showFederatedWarning: boolean;
                  }
               >
            >((acc, schemaRoot) => {
               const groupKey = schemaRoot.dataConnection.id;

               if (!acc[groupKey]) {
                  const isFederatedCompatible = FEDERATED_DBMS.includes(
                     schemaRoot.dataConnection.dbms
                  );

                  const groupName = `${schemaRoot.dataConnection.name}${
                     schemaRoot.dataConnection.catalogName !== null
                        ? ` | ${schemaRoot.dataConnection.catalogName}`
                        : isFederatedMode && isFederatedCompatible
                        ? ` | ${schemaRoot.dataConnection.federatedCatalogName}`
                        : ''
                  }`;

                  acc[groupKey] = {
                     name: groupName,
                     dataConnection: schemaRoot.dataConnection,
                     data: [],
                     showFederatedWarning: isFederatedMode && !isFederatedCompatible,
                  };
               }

               acc[groupKey].data.push(schemaRoot);
               return acc;
            }, {})
         ).sort(([, a], [, b]) => sortSchemaRootGroups(a, b)),
      [schemaRootsResult.data, isFederatedMode]
   );

   // eslint-disable-next-line react-hooks/exhaustive-deps
   const handleChangeSearchQuery = useCallback(
      _debounce((newText: string) => setSearchText(newText.toLowerCase().trim()), 350),
      []
   );

   // Schema content is fetched lazily by default. But we allow the user to search for tables within
   // the schemas. So, when we detect the user may perform a search, we override the lazy fetching.
   const [fetchSchemaContentOverride, setFetchSchemaContentOverride] = useState(false);
   const handleMaySearch = useCallback(() => {
      setFetchSchemaContentOverride(true);
   }, []);

   if (!schemaRootsResult.data) {
      return schemaRootsResult.isLoading ? (
         <LoadingSpinner />
      ) : schemaRootsResult.isError ? (
         <LoadingError />
      ) : null;
   }

   if (schemaRootsResult.data.length === 0) {
      return (
         <>
            <div>
               <Button
                  className="fs-10p my-3 px-1 py-0 opacity-75"
                  onClick={() => setShowManageWorkspaceConnectionsModal(true)}
                  size="sm"
                  variant="secondary"
               >
                  Attach Schemas
               </Button>
            </div>
            <ManageWorkspaceConnections
               onClose={() => setShowManageWorkspaceConnectionsModal(false)}
               show={showManageWorkspaceConnectionsModal}
               workspaceId={workspaceId}
            />
         </>
      );
   }

   return (
      <CreateTableModalProvider>
         <DeleteTableModalProvider>
            <Stack className="h-100" gap={2}>
               <div className="d-flex justify-content-between">
                  <div className="flex-grow-1" onMouseEnter={handleMaySearch}>
                     <SearchInput
                        entityName="connections, schemas, and tables"
                        onFocus={handleMaySearch}
                        onTextChanged={handleChangeSearchQuery}
                     />
                  </div>
                  <Button
                     className="ps-2 pe-0"
                     onClick={() => setShowManageWorkspaceConnectionsModal(true)}
                     title="Attach/Remove Schemas"
                     variant="link"
                  >
                     <IconPlus size={16} />
                  </Button>
               </div>
               <ul className="list-unstyled flex-grow-1 overflow-auto m-0 pb-2">
                  <SchemaTreeProvider areTablesExpandable={true} hasContextMenu={!extension}>
                     {schemaRootGroupEntries.map(([key, { name, dataConnection, data }]) => (
                        <li key={key}>
                           <SchemaGroupNode
                              data={data}
                              dataConnection={dataConnection}
                              enableConnectionOverride={true}
                              enableSchemaOverride={DBMS_SCHEMA_SELECT_SUPPORTED.includes(
                                 dataConnection.dbms
                              )}
                              fetchSchemaContentOverride={fetchSchemaContentOverride}
                              id={key}
                              name={name}
                              schemaNodesAreExpandedInitially={schemaRootsResult.data.length === 1}
                              searchQuery={searchText}
                              workspaceId={workspaceId}
                           />
                        </li>
                     ))}
                  </SchemaTreeProvider>
               </ul>
            </Stack>
            {workspaceId && (
               <ManageWorkspaceConnections
                  onClose={() => setShowManageWorkspaceConnectionsModal(false)}
                  show={showManageWorkspaceConnectionsModal}
                  workspaceId={workspaceId}
               />
            )}
         </DeleteTableModalProvider>
      </CreateTableModalProvider>
   );
};

export default Navigator;
