import { useCallback, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { MdCheck } from 'react-icons/md';
import { Stack } from 'react-bootstrap';

import { QueryReviewPage, LoadingSpinner, Button } from '../components';
import ErrorBoundary from '../components/ErrorBoundary';
import LoadingError from '../components/UI/LoadingError';
import { QueryVersion, QueryVersionPatch } from '../entities';
import { useDeleteQueryVersionMutator, useListQueryQuery, useUpdateQueryMutator } from '../hooks';
import { getErrorMessage, getShortDateString, useTitle } from '../utilities';

export function QueryUpdates() {
   useTitle('Schema Change - Query Updates');

   // Page State
   const [isSaving, setIsSaving] = useState(false);
   const [activeQueryVersion, setActiveQueryVersion] = useState<QueryVersion | null>(null);

   const savedQueriesQuery = useListQueryQuery({
      listOptions: {
         expandedPersonData: true,
         includeDataConnectionDetails: true,
         version: 'ai-suggestions',
         includeWorkspace: true,
         includeParent: true,
      },
   });

   const updateQueryMutation = useUpdateQueryMutator();
   const removeVersionMutation = useDeleteQueryVersionMutator();

   const publish = useCallback(
      async (queryVersion: QueryVersion) => {
         if (!queryVersion?.id || !savedQueriesQuery.data?.items) return;

         let queryUpdate: QueryVersionPatch = {
            queryVersion: {
               id: queryVersion.id,
            },
            publish: true,
         };
         setIsSaving(true);
         await updateQueryMutation.mutateAsync(queryUpdate);
         setIsSaving(false);

         // Find the current item's index
         const currentIndex = savedQueriesQuery.data.items.findIndex(
            (item) => item.id === queryVersion.id
         );
         if (currentIndex === -1) return;

         // If there's a next item, select it, otherwise select the previous item
         const nextItem =
            savedQueriesQuery.data.items[currentIndex + 1] ||
            savedQueriesQuery.data.items[currentIndex - 1];
         if (nextItem) {
            setActiveQueryVersion(nextItem);
         }
      },
      [updateQueryMutation, savedQueriesQuery.data, setActiveQueryVersion]
   );

   const ignore = useCallback(
      async (queryVersion: QueryVersion) => {
         if (!queryVersion?.id || !savedQueriesQuery.data?.items) return;
         setIsSaving(true);
         await removeVersionMutation.mutateAsync(queryVersion.id);
         setIsSaving(false);

         // Find the current item's index
         const currentIndex = savedQueriesQuery.data.items.findIndex(
            (item) => item.id === queryVersion.id
         );
         if (currentIndex === -1) return;

         // If there's a next item, select it, otherwise select the previous item
         const nextItem =
            savedQueriesQuery.data.items[currentIndex + 1] ||
            savedQueriesQuery.data.items[currentIndex - 1];
         if (nextItem) {
            setActiveQueryVersion(nextItem);
         }
      },
      [removeVersionMutation, savedQueriesQuery.data, setActiveQueryVersion]
   );

   if (savedQueriesQuery.isError)
      return <LoadingError message={getErrorMessage(savedQueriesQuery.error)} />;

   if (!savedQueriesQuery.data) {
      return <LoadingSpinner />;
   }

   const queryVersions = savedQueriesQuery.data.items;

   return (
      <ErrorBoundary title={'An error occurred getting query updates.'}>
         <QueryReviewPage
            activeQueryVersion={activeQueryVersion}
            defaultTab="results"
            diffVersion={(queryVersion) => queryVersion.parent}
            extraActions={(queryVersion) => [
               <Button
                  colorScheme="secondary"
                  isLoading={isSaving}
                  key="ignore"
                  loadingMessage="Saving..."
                  onClick={() => ignore(queryVersion)}
               >
                  Ignore Suggestion
               </Button>,
               <Button
                  colorScheme="success"
                  isLoading={isSaving}
                  key="accept"
                  loadingMessage="Saving..."
                  onClick={() => publish(queryVersion)}
               >
                  <Stack direction="horizontal" gap={1}>
                     <MdCheck />
                     <span>Accept Changes</span>
                  </Stack>
               </Button>,
            ]}
            extraTabs={(queryVersion) => [
               {
                  title: 'Schema Changes',
                  body: (
                     <div className="ps-1">
                        <div className="fw-600 fs-11p pt-1">
                           Change Date: {getShortDateString(queryVersion?.created)}
                        </div>
                        {/* Changes */}
                        {((queryVersion?.schemaChanges?.tableNameChanges?.length ?? 0) > 0 ||
                           (queryVersion?.schemaChanges?.columnNameChanges?.length ?? 0) > 0) && (
                           <>
                              <div className="fw-600 fs-13p pt-3">Changes</div>
                              {(queryVersion?.schemaChanges?.tableNameChanges?.length ?? 0) > 0 && (
                                 <>
                                    <div>Tables:</div>
                                    {queryVersion?.schemaChanges?.tableNameChanges.map((t, i) => (
                                       <div key={i}>
                                          {t.oldTableName} <FaArrowRight /> {t.newTableName}
                                       </div>
                                    ))}
                                 </>
                              )}
                              {(queryVersion?.schemaChanges?.columnNameChanges?.length ?? 0) >
                                 0 && (
                                 <>
                                    <div className="pt-2">Columns:</div>
                                    {queryVersion?.schemaChanges?.columnNameChanges.map((c, i) => (
                                       <div key={i}>
                                          {c.tableName}.{c.oldColumnName} <FaArrowRight />{' '}
                                          {c.tableName}.{c.newColumnName}
                                       </div>
                                    ))}
                                 </>
                              )}
                           </>
                        )}

                        {/* Deletions */}
                        {(queryVersion?.schemaChanges?.tableDeletions?.length ?? 0) > 0 ||
                           ((queryVersion?.schemaChanges?.columnDeletions?.length ?? 0) > 0 && (
                              <>
                                 <div className="fw-600 fs-13p pt-3">Deletions</div>
                                 {(queryVersion?.schemaChanges?.tableDeletions?.length ?? 0) >
                                    0 && (
                                    <>
                                       <div>Tables:</div>
                                       {queryVersion?.schemaChanges?.tableDeletions.map((t, i) => (
                                          <div key={i}>{t.tableName}</div>
                                       ))}
                                    </>
                                 )}
                                 {(queryVersion?.schemaChanges?.columnDeletions?.length ?? 0) >
                                    0 && (
                                    <>
                                       <div className="pt-2">Columns:</div>
                                       {queryVersion?.schemaChanges?.columnDeletions.map((c, i) => (
                                          <div key={i}>
                                             {c.tableName}.{c.columnName}
                                          </div>
                                       ))}
                                    </>
                                 )}
                              </>
                           ))}

                        {/* Additions */}
                        {(queryVersion?.schemaChanges?.tableAdditions?.length ?? 0) > 0 ||
                           ((queryVersion?.schemaChanges?.columnAdditions?.length ?? 0) > 0 && (
                              <>
                                 <div className="fw-600 fs-13p pt-3">Additions</div>
                                 {(queryVersion?.schemaChanges?.tableAdditions?.length ?? 0) >
                                    0 && (
                                    <>
                                       <div>Tables:</div>
                                       {queryVersion?.schemaChanges?.tableAdditions.map((t, i) => (
                                          <div key={i}>{t.tableName}</div>
                                       ))}
                                    </>
                                 )}
                                 {(queryVersion?.schemaChanges?.columnAdditions?.length ?? 0) >
                                    0 && (
                                    <>
                                       <div className="pt-2">Columns:</div>
                                       {queryVersion?.schemaChanges?.columnAdditions.map((c, i) => (
                                          <div key={i}>
                                             {c.tableName}.{c.columnName} ({c.columnType})
                                          </div>
                                       ))}
                                    </>
                                 )}
                              </>
                           ))}
                     </div>
                  ),
               },
            ]}
            hideTabs={{ logs: true, comments: true }}
            noItemsMessage="No suggested updates"
            queryVersions={queryVersions}
            setActiveQueryVersion={setActiveQueryVersion}
            upgradeMessage="Update all your queries in one click when schemas change."
         />
      </ErrorBoundary>
   );
}

export default QueryUpdates;
