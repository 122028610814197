import { zodResolver } from '@hookform/resolvers/zod';
import { CredentialPersistence } from '@runql/util';
import { useEffect, useState } from 'react';
import { Collapse, Form, Stack } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { LoadingSpinner } from '../../../../components';
import LoadingError from '../../../../components/UI/LoadingError';
import { ConnectionAccessType, SSHAuthenticationMethod } from '../../../../entities';
import { ADMIN_ROLES, DBMS } from '../../../../enums';
import { useGetAuthorizedExplorerQuery, useGetDataConnectionQuery } from '../../../../hooks';
import { isDesktop } from '../../../../services';
import { useIsDesktopFlagEnabled } from '../../../../hooks/desktop';
import { getErrorMessage } from '../../../../utilities';
import {
   connectionName,
   description,
   host,
   password,
   port,
   username,
   withHostRefinement,
} from '../validators';
import {
   AccessTypeField,
   ConnectionCredentialsFields,
   ConnectionParamSelectField,
   CredentialPersistenceField,
   HideDetailsField,
   SSHConnectionFields,
   SSHCredentialFields,
} from './common';
import { ConnectionFields, CredentialFields } from './ConnectionDetailsForm';

const credentialsSchema = z.object({
   rememberCredential: z.boolean().optional(),
   accountName: username,
   password: password,
});

const oracleSchema = withHostRefinement(
   z
      .object({
         dbms: z.literal(DBMS.Oracle),
         connectionAccessType: z.nativeEnum(ConnectionAccessType),
         connectionName: connectionName,
         description: description,
         host: host,
         port: port,
         database: z.string().trim(),
         sharedConnection: z.boolean().default(false),
         hideDetails: z.boolean().default(false),
         useSSH: z.boolean().default(false),
         sshHost: z.string().optional(),
         sshPort: z.number().optional(),
         sshUsername: z.string().optional(),
         sshPassword: z.string().optional(),
         sshAuthMethod: z
            .nativeEnum(SSHAuthenticationMethod)
            .default(SSHAuthenticationMethod.PASSWORD),
         sshKeyFile: z.string().trim().optional(),
      })
      .merge(credentialsSchema)
      .superRefine((data, ctx) => {
         if (data.useSSH) {
            if (!data.sshHost) {
               ctx.addIssue({
                  code: 'custom',
                  path: ['sshHost'],
                  message: 'Required',
               });
            }
            if (!data.sshPort) {
               ctx.addIssue({
                  code: 'custom',
                  path: ['sshPort'],
                  message: 'Required',
               });
            }
            if (!data.sshUsername) {
               ctx.addIssue({
                  code: 'custom',
                  path: ['sshUsername'],
                  message: 'Required',
               });
            }

            if (data.sshAuthMethod === SSHAuthenticationMethod.PASSWORD && !data.sshPassword) {
               ctx.addIssue({
                  code: 'custom',
                  path: ['sshPassword'],
                  message: 'Required',
               });
            }

            if (data.sshAuthMethod === SSHAuthenticationMethod.KEY_FILE && !data.sshKeyFile) {
               ctx.addIssue({
                  code: 'custom',
                  path: ['sshKeyFile'],
                  message: 'Required',
               });
            }
         }
      })
);

type OracleDetailFormData = z.infer<typeof oracleSchema>;

function OracleDetailForm({
   editType,
   formId,
   isSaving,
   onSaveStateChange,
   onSubmit,
   onlyCreds,
   selectedConnectionId,
}: {
   editType: 'connection' | 'credential' | 'read-only';
   formId: string;
   isSaving?: boolean;
   onSaveStateChange?: (state: 'clean' | 'dirty') => void;
   onSubmit?: (data: ConnectionFields & CredentialFields) => void;
   onlyCreds?: boolean;
   selectedConnectionId?: number;
}) {
   // register form
   const formMethods = useForm<OracleDetailFormData>({
      resolver: zodResolver(onlyCreds ? credentialsSchema.passthrough() : oracleSchema),
      mode: 'onTouched',
      defaultValues: {
         dbms: DBMS.Oracle,
         connectionName: '',
         description: '',
         host: '',
         port: 1521,
         database: '',
         sharedConnection: false,
         hideDetails: false,
         accountName: '',
         password: '',
         useSSH: false,
         sshHost: '',
         sshPassword: '',
         sshPort: 22,
         sshUsername: '',
         sshKeyFile: '',
         sshAuthMethod: SSHAuthenticationMethod.PASSWORD,
         rememberCredential: true,
      },
   });
   const {
      handleSubmit,
      register,
      formState: { errors, touchedFields, dirtyFields },
      reset,
      watch,
      setValue,
      clearErrors,
   } = formMethods;

   const connectionAccessType = watch('connectionAccessType', ConnectionAccessType.INDIVIDUAL);
   const useSSH = !!watch('useSSH', false);
   const sshAvailable = useIsDesktopFlagEnabled('oracleSSH');
   const isSshPermitted = sshAvailable && connectionAccessType === ConnectionAccessType.INDIVIDUAL;

   // State variables
   const [explorerIsAdmin, setExplorerIsAdmin] = useState(false);

   // Queries
   const selectedConnectionQuery = useGetDataConnectionQuery({
      id: selectedConnectionId,
      getOptions: { includeCredentials: true },
   });
   const authPersonQuery = useGetAuthorizedExplorerQuery();

   // Effects
   useEffect(() => {
      if (selectedConnectionQuery.data) {
         const dataCredential = selectedConnectionQuery.data.dataCredentials?.[0];

         const sshAuthMethod =
            dataCredential?.sshAuthMethod === SSHAuthenticationMethod.KEY_FILE &&
            typeof dataCredential?.sshKeyFile === 'string' &&
            dataCredential.sshKeyFile.length > 0
               ? SSHAuthenticationMethod.KEY_FILE
               : SSHAuthenticationMethod.PASSWORD;

         const formData: OracleDetailFormData = {
            dbms: DBMS.Oracle,
            connectionAccessType:
               selectedConnectionQuery.data.connectionAccessType ?? ConnectionAccessType.INDIVIDUAL,
            connectionName: selectedConnectionQuery.data.name ?? '',
            description: selectedConnectionQuery.data.description ?? '',
            host: selectedConnectionQuery.data.dbHost ?? '',
            port: parseInt(selectedConnectionQuery.data.dbPort ?? '1521'),
            database: selectedConnectionQuery.data.catalogName ?? '',
            sharedConnection:
               selectedConnectionQuery.data.connectionAccessType === ConnectionAccessType.SHARED,
            hideDetails:
               (selectedConnectionQuery.data.hideDetails as unknown as number) === 1 ? true : false,
            accountName: dataCredential?.accountName ?? '',
            password: dataCredential?.accountPassword === undefined ? '' : 'CURRENT',
            useSSH: selectedConnectionQuery.data.useSSH ?? false,
            sshHost: selectedConnectionQuery.data.sshHost ?? '',
            sshPort: parseInt(selectedConnectionQuery.data.sshPort ?? '22'),
            sshAuthMethod,
            sshUsername: dataCredential?.sshUsername ?? '',
            sshPassword: dataCredential?.sshPassword === undefined ? '' : 'CURRENT',
            sshKeyFile:
               sshAuthMethod === SSHAuthenticationMethod.KEY_FILE
                  ? dataCredential?.sshKeyFile ?? ''
                  : undefined,
            rememberCredential: dataCredential?.credentialPersistence
               ? dataCredential.credentialPersistence === CredentialPersistence.LOCAL_STORAGE
               : true,
         };

         reset(formData);
      }
   }, [selectedConnectionQuery.data, reset]);

   useEffect(() => {
      if (
         authPersonQuery.data?.person.role &&
         ADMIN_ROLES.includes(authPersonQuery.data.person.role)
      ) {
         setExplorerIsAdmin(true);
      } else {
         setExplorerIsAdmin(false);
      }
   }, [authPersonQuery.data]);

   useEffect(() => {
      const isDirtyAlt = !!Object.keys(dirtyFields).length;
      if (isDirtyAlt) {
         onSaveStateChange?.('dirty');
      } else {
         onSaveStateChange?.('clean');
      }
   }, [dirtyFields, onSaveStateChange]);

   // Event handlers
   const onSubmitHandler = (data: OracleDetailFormData) => {
      onSubmit?.(data);
   };

   // Render
   if (selectedConnectionQuery.isLoading || authPersonQuery.isLoading || isSaving)
      return <LoadingSpinner />;
   if (authPersonQuery.isError)
      return <LoadingError message={getErrorMessage(authPersonQuery.error)} />;
   if (selectedConnectionQuery.isError)
      return <LoadingError message={getErrorMessage(selectedConnectionQuery.error)} />;

   return (
      <FormProvider {...formMethods}>
         <Form id={formId} onSubmit={handleSubmit(onSubmitHandler)}>
            <Stack gap={3}>
               {!onlyCreds && (
                  <>
                     <Form.Group controlId="connectionName">
                        <Form.Label>
                           Connection Name <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                           {...register('connectionName')}
                           disabled={editType !== 'connection'}
                           isInvalid={touchedFields.connectionName && !!errors.connectionName}
                           isValid={touchedFields.connectionName && !errors.connectionName}
                           placeholder="Connection Name"
                           required
                        />
                        <Form.Control.Feedback type="invalid">
                           {errors.connectionName?.message}
                        </Form.Control.Feedback>
                     </Form.Group>
                     <Form.Group controlId="description">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                           {...register('description')}
                           as="textarea"
                           disabled={editType !== 'connection'}
                           isInvalid={touchedFields.description && !!errors.description}
                           isValid={touchedFields.description && !errors.description}
                           placeholder="Description"
                           rows={3}
                        />
                        <Form.Control.Feedback type="invalid">
                           {errors.description?.message}
                        </Form.Control.Feedback>
                     </Form.Group>
                     <ConnectionParamSelectField
                        connectionField="dbHost"
                        dbms={DBMS.Oracle}
                        isRequired
                        label="Host"
                        onExistingSelect={(value) => {
                           setValue('port', parseInt(value.dbPort ?? '1521'));
                           setValue('database', value.catalogName ?? '');
                        }}
                     />
                     <Form.Group controlId="port">
                        <Form.Label>
                           Port <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                           {...register('port', { valueAsNumber: true })}
                           defaultValue="5432"
                           disabled={editType !== 'connection'}
                           isInvalid={touchedFields.port && !!errors.port}
                           isValid={touchedFields.port && !errors.port}
                           placeholder="Port"
                           required
                        />
                        <Form.Control.Feedback type="invalid">
                           {errors.port?.message}
                        </Form.Control.Feedback>
                     </Form.Group>
                     <Form.Group controlId="database">
                        <Form.Label>
                           Database <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                           {...register('database')}
                           disabled={editType !== 'connection'}
                           isInvalid={touchedFields.database && !!errors.database}
                           isValid={touchedFields.database && !errors.database}
                           placeholder="Database"
                           required
                        />
                        <Form.Control.Feedback type="invalid">
                           {errors.database?.message}
                        </Form.Control.Feedback>
                     </Form.Group>
                     <Form.Group>
                        <Form.Label>Use SSH</Form.Label>
                        {connectionAccessType === ConnectionAccessType.SHARED ? (
                           <div>SSH is not available for shared connections</div>
                        ) : !isDesktop() ? (
                           <div>SSH is available on the desktop IDE</div>
                        ) : !sshAvailable ? (
                           <div>Please update for SSH support</div>
                        ) : (
                           <Form.Check
                              {...register('useSSH', {
                                 onChange() {
                                    clearErrors('sshHost');
                                    clearErrors('sshPort');
                                    clearErrors('sshUsername');
                                    clearErrors('sshPassword');
                                 },
                              })}
                              className="mb-0"
                              type="switch"
                           />
                        )}
                        <Form.Text className="d-block">
                           Individual connections are always stored encrypted on your computer.
                        </Form.Text>
                        <Form.Control.Feedback type="invalid">
                           {errors.useSSH?.message}
                        </Form.Control.Feedback>
                     </Form.Group>
                     <Collapse in={useSSH}>
                        <div>
                           <SSHConnectionFields useSSH={useSSH} />
                        </div>
                     </Collapse>

                     <AccessTypeField
                        disabled={editType !== 'connection'}
                        {...register('connectionAccessType', {
                           setValueAs: (v: string) => parseInt(v) as ConnectionAccessType,
                           onChange(event) {
                              const value = parseInt(event.target.value);
                              if (value === ConnectionAccessType.INDIVIDUAL) {
                                 setValue('hideDetails', false, { shouldDirty: true });
                              } else if (value === ConnectionAccessType.SHARED) {
                                 // Shared connections should not have SSH enabled
                                 setValue('useSSH', false, { shouldDirty: true });
                              }

                              setValue('accountName', '', { shouldDirty: true });
                              setValue('password', '', { shouldDirty: true });
                           },
                        })}
                     />
                     {explorerIsAdmin && (
                        <Collapse in={connectionAccessType === ConnectionAccessType.SHARED}>
                           <div>
                              <HideDetailsField
                                 {...register('hideDetails')}
                                 disabled={editType !== 'connection'}
                              />
                           </div>
                        </Collapse>
                     )}
                  </>
               )}
               <Collapse in={connectionAccessType === ConnectionAccessType.INDIVIDUAL}>
                  <div>
                     <CredentialPersistenceField />
                  </div>
               </Collapse>

               <ConnectionCredentialsFields
                  autoFocusName={onlyCreds}
                  readonly={editType === 'read-only'}
               />

               <Collapse in={useSSH}>
                  <div>
                     <SSHCredentialFields
                        editType={editType}
                        isSshKeyFilePermitted={isSshPermitted}
                        useSSH={useSSH}
                     />
                  </div>
               </Collapse>
            </Stack>
         </Form>
      </FormProvider>
   );
}

export default OracleDetailForm;
