import { memo, useEffect, useState } from 'react';
import {
   Nav,
   NavItem,
   NavLink,
   Spinner,
   TabContainer,
   TabContent,
   TabPane,
   Stack,
} from 'react-bootstrap';
import { BiExpandVertical, BiCollapseVertical } from 'react-icons/bi';

import { QueryVersion, WalkthroughStep } from '../entities';
import { QueryReturn } from '../interfaces';
import { getShorterDateTimeString, IconPinned, IconUnpinned } from '../utilities';
import { QueryResults } from './QueryResults';
import { QueryChart } from './QueryChart';
import ErrorBoundary from './ErrorBoundary';
import { useCurrentQuery } from '../hooks';
import { useQueryPanelContext } from '../hooks/QueryPanelContext';
import { IconResults, IconChart } from '../utilities';
import { AiPulse } from './AiSparkles';
import { Button } from '../components';

const TabHeader = ({
   active,
   queryResults,
   pinned,
   updatePin,
   eventKey,
   timestamp,
}: {
   active?: boolean;
   eventKey: string;
   pinned?: boolean;
   queryResults: QueryReturn[][];
   timestamp: string;
   updatePin: (result: QueryReturn[][], pinned: boolean) => void;
}): JSX.Element => {
   return (
      <NavItem className={`explore-tab ${active ? 'active' : ''}`}>
         <NavLink
            className="position-relative override-active-pointer"
            eventKey={eventKey}
            role="tab"
         >
            {getShorterDateTimeString(timestamp)}
            <div className="ps-1 text-muted">
               {pinned === true ? (
                  <IconPinned
                     className="active-background-on-hover"
                     onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        updatePin(queryResults, false);
                     }}
                     size={14}
                     title="Remove this pinned tab"
                  />
               ) : (
                  <IconUnpinned
                     className="active-background-on-hover"
                     onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        updatePin(queryResults, true);
                     }}
                     size={14}
                     title="Pin this tab (result set) for comparison"
                  />
               )}
            </div>
         </NavLink>
      </NavItem>
   );
};

const MultipleResultsContainer = ({
   results,
   onRefreshQueryData,
}: {
   onRefreshQueryData?: () => Promise<void>;
   results: QueryReturn[][];
}) => {
   const [activeKey, setActiveKey] = useState<string | undefined>(undefined);
   const { editResultsDataConnections, editResultsSchemaNames } = useCurrentQuery();

   useEffect(() => {
      setActiveKey(`${results.length - 1}-${(results.at(-1)?.length ?? 0) - 1}`);
   }, [results]);

   const numSteps = results.length;

   return (
      <TabContainer
         activeKey={activeKey}
         onSelect={(eventKey) => {
            setActiveKey(eventKey ?? undefined);
         }}
         transition={false}
      >
         <div className="d-flex flex-column h-100">
            <Nav className="fs-10p text-nowrap steps-row" role="tablist">
               {results.flatMap((resultsForStep, stepIdx) => {
                  const numStatements = resultsForStep.length;

                  return resultsForStep.map((_, statementIdx) => {
                     const key = `${stepIdx}-${statementIdx}`;
                     return (
                        <NavItem
                           className={`explore-tab ${key === activeKey ? 'active' : ''}`}
                           key={key}
                        >
                           <NavLink eventKey={key} role="tab">
                              {numSteps > 1
                                 ? `Step ${stepIdx + 1}${
                                      numStatements > 1 ? `-${statementIdx + 1}` : ''
                                   }`
                                 : `Statement ${statementIdx + 1}`}
                           </NavLink>
                        </NavItem>
                     );
                  });
               })}
            </Nav>
            <TabContent className="flex-grow-1 overflow-hidden">
               {results.flatMap((resultsForStep, stepIdx) =>
                  resultsForStep.map((result, statementIdx) => {
                     const key = `${stepIdx}-${statementIdx}`;
                     return (
                        <TabPane className="h-100" eventKey={key} key={key}>
                           <QueryResults
                              dataConnection={editResultsDataConnections[stepIdx]}
                              onRefreshQueryData={onRefreshQueryData}
                              queryReturn={result}
                              schemaName={editResultsSchemaNames[stepIdx]}
                           />
                        </TabPane>
                     );
                  })
               )}
            </TabContent>
         </div>
      </TabContainer>
   );
};

const getTimestamp = (queryResults?: QueryReturn[][]): string | undefined => {
   return queryResults?.at(-1)?.at(-1)?.timestamp.toString();
};

const QueryResultsTabs = memo(
   ({
      onRefreshQueryData,
      queryResults,
      queryVersion,
      readOnly,
      resizePanel,
   }: {
      onRefreshQueryData?: () => Promise<void>;
      queryResults?: QueryReturn[][];
      queryVersion: QueryVersion;
      readOnly?: boolean;
      resizePanel?: () => void;
   }): JSX.Element => {
      const [pinnedResults, setPinnedResults] = useState<QueryReturn[][][]>([]);
      const [activeResultSetTab, setActiveResultSetTab] = useState<string | undefined>(undefined);
      const { editResultsDataConnections, editResultsSchemaNames } = useCurrentQuery();

      const currentTimestamp = getTimestamp(queryResults) ?? '';
      const isCurrentPinned = currentTimestamp === getTimestamp(pinnedResults.at(-1));
      const allResults = [
         ...pinnedResults,
         ...(!isCurrentPinned && queryResults ? [queryResults] : []),
      ];

      const { expanded } = useQueryPanelContext();
      const ExpandCollapseIcon = expanded ? BiCollapseVertical : BiExpandVertical;

      useEffect(() => {
         if (!currentTimestamp) return;
         setActiveResultSetTab(currentTimestamp);
      }, [currentTimestamp, queryResults]);

      const updatePin = (result: QueryReturn[][], pin: boolean): void => {
         if (pin) {
            setPinnedResults((pinnedResults) => [...pinnedResults, result]);
         } else {
            const resultTimestamp = getTimestamp(result);
            setPinnedResults((pinnedResults) =>
               pinnedResults.filter(
                  (pinnedResult) => getTimestamp(pinnedResult) !== resultTimestamp
               )
            );
            setActiveResultSetTab(currentTimestamp);
         }
      };

      if (queryResults === undefined) {
         return (
            <Stack
               className="p-4 h-100 d-flex flex-column justify-content-center align-content-center"
               gap={2}
            >
               <div className="text-muted text-center">
                  <IconResults size={24} />
               </div>
               <div className="fs-12p text-muted text-center">
                  <i>Run this query to see data results.</i>
               </div>
            </Stack>
         );
      }

      if (queryResults.length === 0) {
         return (
            <div className="p-2">
               <Spinner />
            </div>
         );
      }
      return (
         <div className="d-flex flex-column h-100">
            <TabContainer
               activeKey={activeResultSetTab}
               onSelect={(eventKey) => {
                  setActiveResultSetTab(eventKey ?? undefined);
               }}
               transition={false}
            >
               <Stack className="justify-content-between" direction="horizontal">
                  <Nav
                     className="fs-10p text-nowrap bottom-explore-tabs explore-tabs"
                     role="tablist"
                  >
                     {allResults.map((resultsForRun, i) => {
                        const timestamp = getTimestamp(resultsForRun);
                        return (
                           <TabHeader
                              active={activeResultSetTab === timestamp}
                              eventKey={timestamp!}
                              key={timestamp}
                              pinned={i < pinnedResults.length}
                              queryResults={resultsForRun}
                              timestamp={timestamp ?? ''}
                              updatePin={updatePin}
                           />
                        );
                     })}
                     <NavItem
                        className={`explore-tab ${activeResultSetTab === 'chart' ? 'active' : ''}`}
                     >
                        <NavLink
                           className="position-relative override-active-pointer"
                           eventKey="chart"
                           role="tab"
                        >
                           <AiPulse onStep={WalkthroughStep.CHART_TAB}>
                              <Stack direction="horizontal" gap={1}>
                                 <IconChart />
                                 <span>Chart</span>
                              </Stack>
                           </AiPulse>
                        </NavLink>
                     </NavItem>
                  </Nav>
                  <div className="px-1">
                     {resizePanel ? (
                        <Button colorScheme="secondary" onClick={resizePanel} variant="ghost">
                           <span className="visually-hidden">{`${
                              expanded ? 'Collapse' : 'Expand'
                           } Panel`}</span>
                           <ExpandCollapseIcon size="14" />
                        </Button>
                     ) : null}
                  </div>
               </Stack>
               <TabContent className="flex-grow-1 overflow-hidden">
                  {allResults.map((resultsForRun) => {
                     const timestamp = getTimestamp(resultsForRun);
                     return (
                        <TabPane className="h-100" eventKey={timestamp} key={timestamp}>
                           <>
                              {resultsForRun.length === 1 && resultsForRun[0].length === 1 ? (
                                 <QueryResults
                                    dataConnection={editResultsDataConnections[0]}
                                    onRefreshQueryData={onRefreshQueryData}
                                    queryReturn={resultsForRun[0][0]}
                                    schemaName={editResultsSchemaNames[0]}
                                 />
                              ) : (
                                 <MultipleResultsContainer
                                    onRefreshQueryData={onRefreshQueryData}
                                    results={resultsForRun}
                                 />
                              )}
                           </>
                        </TabPane>
                     );
                  })}
                  <TabPane className="h-100" eventKey="chart">
                     <ErrorBoundary
                        className="h-100"
                        title={'An error occurred with chart generation.'}
                     >
                        <QueryChart
                           allowChartGeneration={!readOnly}
                           queryReturn={queryResults?.at(-1)?.at(-1)}
                           queryVersion={queryVersion}
                        />
                     </ErrorBoundary>
                  </TabPane>
               </TabContent>
            </TabContainer>
         </div>
      );
   }
);
export default QueryResultsTabs;
