import { Page, SavedQueries, HistoryLog, Snippets } from '../components';
import { useTitle } from '../utilities';
import { Stack } from 'react-bootstrap';
import { useOrgStats } from '../hooks/accountHooks';
export const QueryListPage = () => {
   useTitle('Saved Queries');

   const stats = useOrgStats();
   const toolbar = (
      <div className="saved-query-stats">
         <span style={{ marginRight: '20px' }}>
            Your Queries: {stats?.mySavedQueries} saved | {stats?.myCertifiedQueries} certified |{' '}
            {stats?.myQueryRunCount} run
         </span>
         <span style={{ marginLeft: '20px' }}>
            Org Queries: {stats?.savedQueries} saved | {stats?.totalCertifiedQueries} certified |{' '}
            {stats?.queryRunCount} run
         </span>
      </div>
   );
   return (
      <Page
         className="home-page"
         noScroll
         tabs={[
            {
               title: 'Saved Queries',
               content: (
                  <Stack className="h-100 px-4">
                     <SavedQueries showDescription="inCard" />
                  </Stack>
               ),
            },
            {
               title: 'History',
               content: (
                  <Stack className="h-100 px-4">
                     <HistoryLog myQueries />
                  </Stack>
               ),
            },
            {
               title: 'Snippets',
               content: (
                  <Stack className="overflow-auto pb-4">
                     <Snippets />
                  </Stack>
               ),
            },
         ]}
         tabsClassName="justify-content-center mt-4"
         toolbar={toolbar}
      />
   );
};

export default QueryListPage;
