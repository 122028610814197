import React, { useState, useEffect, useContext, createContext } from 'react';
import * as Sentry from '@sentry/react';

type AppInfo = {
   platform: string;
   systemDarkMode: boolean;
   version: string;
};

const fallbackAppInfo: AppInfo = { platform: '', version: '', systemDarkMode: false };

const DesktopContext = createContext<
   { appInfo: AppInfo; flags: Record<string, boolean> } | undefined
>(undefined);

export const DesktopProvider = ({ children }: React.PropsWithChildren<{}>) => {
   const [flags, setFlags] = useState<Record<string, boolean> | null>(
      !globalThis.runql ? {} : null
   );
   useEffect(() => {
      if (!globalThis.runql) {
         setFlags({});
         return;
      }

      // Older desktop versions exposed flags directly in the runql interface.
      if (
         (globalThis.runql as typeof globalThis.runql & { flags?: Record<string, boolean> }).flags
      ) {
         setFlags(
            (globalThis.runql as typeof globalThis.runql & { flags: Record<string, boolean> }).flags
         );
         return;
      }

      if (!globalThis.runql.fetchFlags) {
         setFlags({});
         return;
      }

      globalThis.runql
         .fetchFlags()
         .then((flags) => {
            setFlags(flags);
         })
         .catch((err) => {
            Sentry.captureException(err);
            setFlags({});
         });
   }, []);

   const [appInfo, setAppInfo] = useState<AppInfo | null>(
      !globalThis.runql ? fallbackAppInfo : null
   );
   useEffect(() => {
      if (!globalThis.runql) {
         setAppInfo(fallbackAppInfo);
         return;
      }

      // Older desktop versions exposed platform, version, and systemDarkMode in the runql interface.
      const oldRunql = globalThis.runql as typeof globalThis.runql & Partial<AppInfo>;
      if (
         typeof oldRunql.platform === 'string' &&
         typeof oldRunql.version === 'string' &&
         typeof oldRunql.systemDarkMode === 'boolean'
      ) {
         setAppInfo({
            platform: oldRunql.platform,
            version: oldRunql.version,
            systemDarkMode: oldRunql.systemDarkMode,
         });
         return;
      }

      if (!globalThis.runql.fetchAppInfo) {
         setAppInfo(fallbackAppInfo);
         return;
      }

      globalThis.runql
         .fetchAppInfo()
         .then((data) => {
            setAppInfo(data);
         })
         .catch((err) => {
            Sentry.captureException(err);
            setAppInfo(fallbackAppInfo);
         });
   }, []);

   // Loading?
   if (flags === null || appInfo === null) {
      return null;
   }

   return (
      <DesktopContext.Provider
         value={{
            flags,
            appInfo,
         }}
      >
         {children}
      </DesktopContext.Provider>
   );
};

export const useIsDesktopFlagEnabled = (name: string) => {
   const context = useContext(DesktopContext);

   if (context === undefined) {
      throw new Error('useIsDesktopFlagEnabled must be used within a DesktopProvider');
   }

   return context.flags[name] ?? false;
};

export const useDesktopVersionSuffix = () => {
   const context = useContext(DesktopContext);

   if (context === undefined) {
      throw new Error('useDesktopVersionSuffix must be used within a DesktopProvider');
   }

   return `${
      context.appInfo.platform !== ''
         ? `-${context.appInfo.platform}-${
              context.appInfo.version !== '' ? `${context.appInfo.version}` : 'unknown'
           }`
         : ''
   }`;
};

export const useSystemPrefersDarkMode = () => {
   const context = useContext(DesktopContext);

   if (context === undefined) {
      throw new Error('useSystemPrefersDarkMode must be used within a DesktopProvider');
   }

   return context.appInfo.systemDarkMode;
};
