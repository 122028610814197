import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import {
   Nav,
   NavItem,
   NavDropdown,
   NavLink,
   Stack,
   TabContainer,
   TabContent,
   TabPane,
} from 'react-bootstrap';
import classNames from 'classnames';

import {
   AiPulse,
   QueryDocs,
   QueryLogList,
   QuerySuggestions,
   QueryVersionList,
   RunSource,
} from '../components';
import { QueryStep, QueryVersion } from '../entities';
import { WalkthroughStep } from '../entities/Walkthrough';
import { useCurrentQuery, useCurrentStep } from '../hooks';
import { useQueryPanelContext } from '../hooks/QueryPanelContext';
import { QueryReturn } from '../interfaces';
import ErrorBoundary from './ErrorBoundary';
import { QueryComments } from './QueryComments';
import QueryParams from './QueryParams';
import QueryResultsTabs from './QueryResultsTabs';
import RequestsTabContent from './RequestsTabContent';
import { IconSuggestions, IconParameters, IconResults, IconMore, IconComment } from '../utilities';

export const queryPanelTabs = {
   // Tabs inside the menu
   requests: 'Requests',
   docs: 'Documentation',
   versions: 'Versions',
   logs: 'History Logs',
   runSource: 'runSource',

   // Tabs with icons
   suggestions: null,
   params: null,
   results: null,
   comments: null,
};

export type Tab = {
   body: React.ReactNode;
   title: string;
};

export const QueryPanel = forwardRef(
   (
      {
         allowDocGeneration = false,
         extraTabs,
         extraActions,
         hideTabs,
         isGeneratingDocumentation,
         isGeneratingExplanation,
         onChangeDocumentation,
         onClickGenerateDocumentation,
         onClickGenerateExplanation,
         onRefreshQueryData,
         onSetExplanation,
         queryResults,
         queryVersion,
         readOnly,
         setShowUpgradeModal,
         statusMessage,
         resizePanel,
      }: {
         allowDocGeneration?: boolean;
         extraActions?: React.ReactNode;
         extraTabs?: Tab[];
         hideTabs?: Partial<Record<keyof typeof queryPanelTabs, boolean>>;
         isGeneratingDocumentation?: boolean;
         isGeneratingExplanation?: boolean;
         onChangeDocumentation?: (params: {
            description?: string;
            explanation?: string;
            question?: string;
            title?: string;
         }) => void;
         onClickGenerateDocumentation?: React.MouseEventHandler<HTMLButtonElement>;
         onClickGenerateExplanation?: React.MouseEventHandler<HTMLButtonElement>;
         onRefreshQueryData?: () => Promise<void>;
         onSetExplanation?: (explanation: string) => void;
         queryResults?: QueryReturn[][];
         queryVersion: QueryVersion;
         readOnly?: boolean;
         resizePanel?: () => void;
         setShowUpgradeModal?: () => void;
         statusMessage?: string;
      },
      ref
   ): JSX.Element => {
      const activeTabs = {
         ...Object.fromEntries((extraTabs ?? []).map((t) => [t.title, t.title])),
         ...Object.fromEntries(
            Object.entries(queryPanelTabs).filter(
               ([id]) => !(hideTabs?.[id as keyof typeof queryPanelTabs] === true)
            )
         ),
      };

      const [currentStep] = useCurrentStep();
      const { queryTab, setQueryTab } = useQueryPanelContext();

      const { setEditResultsDataConnections, setEditResultsSchemaNames } = useCurrentQuery();

      const onRunSteps = useCallback(
         (steps: QueryStep[]) => {
            setEditResultsDataConnections(steps.map((step) => step.dataConnection || {}));
            setEditResultsSchemaNames(steps.map((step) => step.schemaName || ''));
         },
         [setEditResultsDataConnections, setEditResultsSchemaNames]
      );

      useImperativeHandle(ref, () => ({
         onRunSteps,
      }));

      // if query result changes, focus results tab
      useEffect(() => {
         if (!queryResults) return;
         setQueryTab('results');
      }, [queryResults, setQueryTab]);

      // If we add the second workflow step, focus to params
      const lastStepCount = useRef(queryVersion.steps.length);
      useEffect(() => {
         if (lastStepCount.current === 1 && queryVersion.steps.length === 2) {
            setQueryTab('params');
         }
         lastStepCount.current = queryVersion.steps.length;
      }, [queryVersion.steps.length, setQueryTab]);

      // Set active tab to docs when docs are generated.
      const lastQueryVersion = useRef(queryVersion);
      useEffect(() => {
         if (isGeneratingDocumentation) {
            setQueryTab('docs');
         }
         if (
            (queryVersion.generatedTitle &&
               lastQueryVersion.current.title !== queryVersion.title) ||
            (queryVersion.generatedDescription &&
               lastQueryVersion.current.description !== queryVersion.description) ||
            (queryVersion.generatedQuestion &&
               lastQueryVersion.current.query !== queryVersion.query)
         ) {
            lastQueryVersion.current = queryVersion;
         }
      }, [queryVersion, isGeneratingDocumentation, setQueryTab]);

      if (!queryVersion.query?.id) return <></>;

      return (
         <div className="d-flex flex-column h-100">
            <TabContainer
               activeKey={queryTab}
               onSelect={(t) => t && setQueryTab(t as keyof typeof queryPanelTabs)}
               transition={false}
            >
               <Nav
                  className="bottom-explore-tabs explore-tabs justify-content-between align-items-center"
                  id="exploreTabNavQuery"
                  role="tablist"
                  style={{
                     display: 'grid',
                     gridTemplateColumns: 'repeat(3, 1fr)',
                  }}
               >
                  <Stack direction="horizontal">
                     {hideTabs?.comments ? null : (
                        <NavItem
                           bsPrefix="explore-tab"
                           className={classNames('bottom-explore-tab', {
                              active: queryTab === 'comments',
                           })}
                        >
                           <NavLink
                              className="override-active-pointer plausible-event-name--queryPanelSuggestions"
                              eventKey="comments"
                              role="tab"
                              title="Comments"
                           >
                              <IconComment />
                           </NavLink>
                        </NavItem>
                     )}
                     {hideTabs?.suggestions ? null : (
                        <NavItem
                           bsPrefix="explore-tab"
                           className={classNames('bottom-explore-tab', {
                              active: queryTab === 'suggestions',
                           })}
                        >
                           <NavLink
                              className="override-active-pointer plausible-event-name--queryPanelSuggestions"
                              eventKey="suggestions"
                              role="tab"
                              title="Suggestions"
                           >
                              <IconSuggestions />
                           </NavLink>
                        </NavItem>
                     )}
                     {hideTabs?.params ? null : (
                        <NavItem
                           bsPrefix="explore-tab"
                           className={classNames('bottom-explore-tab', {
                              active: queryTab === 'params',
                           })}
                        >
                           <NavLink
                              className="override-active-pointer plausible-event-name--queryPanelParams"
                              eventKey="params"
                              role="tab"
                              title="Parameters"
                           >
                              <IconParameters />
                           </NavLink>
                        </NavItem>
                     )}
                     {hideTabs?.results ? null : (
                        <NavItem
                           bsPrefix="explore-tab"
                           className={classNames('bottom-explore-tab', {
                              active: queryTab === 'results',
                           })}
                        >
                           <NavLink
                              className="override-active-pointer plausible-event-name--queryPanelResults"
                              eventKey="results"
                              role="tab"
                              title="Results"
                           >
                              <IconResults />
                           </NavLink>
                        </NavItem>
                     )}
                     <AiPulse onStep={WalkthroughStep.RESULTS_THREE_DOT_MENU}>
                        <NavItem bsPrefix="explore-tab" className="bg-transparent border-0">
                           <NavDropdown role="menu" title={<IconMore />}>
                              {Object.entries(activeTabs).map(
                                 ([id, name]) =>
                                    name && (
                                       <AiPulse
                                          key={id}
                                          onStep={
                                             id === 'versions'
                                                ? WalkthroughStep.VERSIONS
                                                : undefined
                                          }
                                       >
                                          <NavDropdown.Item
                                             className={`plausible-event-name--queryPanel${name}`}
                                             eventKey={id}
                                             role="tab"
                                          >
                                             {name}
                                          </NavDropdown.Item>
                                       </AiPulse>
                                    )
                              )}
                           </NavDropdown>
                        </NavItem>
                     </AiPulse>
                  </Stack>
                  <div className="d-flex justify-content-center">
                     {statusMessage ? (
                        <span className="fs-11p text-muted">{statusMessage}</span>
                     ) : null}
                  </div>
                  <div className="d-flex justify-content-end">{extraActions}</div>
               </Nav>
               <TabContent className="flex-grow-1 overflow-hidden bottom-query-panel-tab-content">
                  {(extraTabs ?? []).map((t, i) => (
                     <TabPane
                        className="h-100 overflow-auto p-2"
                        eventKey={t.title}
                        key={i}
                        mountOnEnter={true}
                     >
                        {t.body}
                     </TabPane>
                  ))}
                  <TabPane
                     className="h-100 overflow-auto p-2"
                     eventKey="runSource"
                     mountOnEnter={true}
                  >
                     <ErrorBoundary title={'An error occurred when trying to reach runSource.'}>
                        <RunSource queryVersion={queryVersion} readOnly={readOnly} />
                     </ErrorBoundary>
                  </TabPane>
                  <TabPane
                     className="h-100 overflow-auto p-2"
                     eventKey="versions"
                     mountOnEnter={true}
                  >
                     <ErrorBoundary title={'An error occurred when viewing version history.'}>
                        <QueryVersionList
                           collapse={false}
                           currentVersion={queryVersion}
                           defaultAction={false}
                           enabled={queryTab === 'versions'}
                           forkedFromVersion={queryVersion.query?.forkedFromVersion}
                           pageSize={10}
                           queryId={queryVersion.query?.id}
                           showDescription="inCard"
                        />
                     </ErrorBoundary>
                  </TabPane>{' '}
                  <TabPane className="h-100 overflow-auto p-2" eventKey="logs" mountOnEnter={true}>
                     <ErrorBoundary title={'An error occurred when viewing logs.'}>
                        <QueryLogList
                           collapse={false}
                           currentVersion={queryVersion}
                           defaultAction={false}
                           enabled={queryTab === 'logs'}
                           pageSize={10}
                           queryId={queryVersion.query.id}
                        />
                     </ErrorBoundary>
                  </TabPane>
                  <TabPane
                     className="h-100 overflow-auto p-2"
                     eventKey="params"
                     mountOnEnter={true}
                  >
                     <ErrorBoundary title={'An error occurred when viewing parameters.'}>
                        <QueryParams />
                     </ErrorBoundary>
                  </TabPane>
                  <TabPane
                     className="h-100 overflow-hidden"
                     eventKey="results"
                     key="results"
                     mountOnEnter={true}
                  >
                     <ErrorBoundary title={'An error occurred when retrieving results.'}>
                        <QueryResultsTabs
                           onRefreshQueryData={onRefreshQueryData}
                           queryResults={queryResults}
                           queryVersion={queryVersion}
                           readOnly={readOnly}
                           resizePanel={resizePanel}
                        />
                     </ErrorBoundary>
                  </TabPane>
                  <TabPane
                     className="h-100 overflow-auto p-2"
                     eventKey="comments"
                     mountOnEnter={true}
                  >
                     <ErrorBoundary title={'An error occurred when viewing comments.'}>
                        <QueryComments queryVersion={queryVersion} />
                     </ErrorBoundary>
                  </TabPane>
                  <TabPane className="h-100 overflow-auto p-2" eventKey="docs" mountOnEnter={true}>
                     <ErrorBoundary title={'An error occurred when viewing documentation.'}>
                        <QueryDocs
                           allowDocGeneration={allowDocGeneration}
                           isGenerating={isGeneratingDocumentation}
                           isGeneratingExplanation={isGeneratingExplanation}
                           onChangeDocumentation={onChangeDocumentation}
                           onClickGenerateDocumentation={onClickGenerateDocumentation}
                           onClickGenerateExplanation={onClickGenerateExplanation}
                           onSetExplanation={onSetExplanation}
                           queryVersion={queryVersion}
                           readOnly={readOnly}
                           setShowUpgradeModal={setShowUpgradeModal}
                        />
                     </ErrorBoundary>
                  </TabPane>
                  <TabPane
                     className="p-2 h-100 overflow-auto"
                     eventKey="suggestions"
                     mountOnEnter={true}
                  >
                     <ErrorBoundary title={'An error occurred when viewing suggestions.'}>
                        <QuerySuggestions
                           query={queryVersion}
                           step={
                              currentStep?.queryVersion.id === queryVersion.id
                                 ? currentStep?.queryStep
                                 : queryVersion.steps[0]
                           }
                           workspaceId={queryVersion.query.workspaceId}
                        />
                     </ErrorBoundary>
                  </TabPane>
                  <TabPane
                     className="h-100 overflow-hidden"
                     eventKey="requests"
                     mountOnEnter={true}
                  >
                     <ErrorBoundary title={'Error loading Requests content'}>
                        <RequestsTabContent queryVersion={queryVersion} />
                     </ErrorBoundary>
                  </TabPane>
               </TabContent>
            </TabContainer>
         </div>
      );
   }
);

export default QueryPanel;
