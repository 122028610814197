import { useMemo, useState } from 'react';
import { Dropdown, Stack, Tab } from 'react-bootstrap';
import { BiChevronDown } from 'react-icons/bi';
import HistoryLog from './HistoryLog';
import SavedQueries from './SavedQueries';
import { useListWorkspaceQuery, useExtension } from '../hooks';
import { TopNavRail, TopNavRailButton } from './TopNavRail';
import { Navigator } from './Navigator';
import LoadingSpinner from './UI/LoadingSpinner';
import LoadingError from './UI/LoadingError';
import { getErrorMessage } from '../utilities';
import QuerySnippets from './QuerySnippets';
import {
   IconNavigator,
   IconMenuSavedQueries,
   IconMenuSavedSnippets,
   IconMenuQueryHistory,
} from '../utilities/icons';
import { useWalkthroughStep } from '../hooks/walkthrough';
import { WalkthroughStep } from '../entities/Walkthrough';
import { AiPulse } from './AiSparkles';
import { WorkspaceStatus } from '../entities';

const tabs = {
   navigator: {
      title: 'Navigator',
      icon: IconNavigator,
   },
   saved: {
      title: 'Saved',
      icon: IconMenuSavedQueries,
   },
   logs: {
      title: 'History',
      icon: IconMenuQueryHistory,
   },
   snippets: {
      title: 'Snippets',
      icon: IconMenuSavedSnippets,
   },
};

export const StartingBlock = ({
   workspaceId,
   workspaceSelector,
}: {
   workspaceId?: number;
   workspaceSelector?: (id: number | undefined) => void;
}): JSX.Element => {
   const workspaces = useListWorkspaceQuery({});
   const extension = useExtension();
   const [tab, setTab] = useState<keyof typeof tabs>(extension ? 'saved' : 'navigator');
   const [stepEleven, setStepEleven] = useWalkthroughStep(WalkthroughStep.SAVED_QUERIES);
   const currentWorkspace = useMemo(() => {
      return workspaces.data?.find((w) => w.id === workspaceId);
   }, [workspaces.data, workspaceId]);

   if (workspaces.isLoading) return <LoadingSpinner />;
   if (workspaces.isError) return <LoadingError message={getErrorMessage(workspaces.error)} />;

   return (
      <Tab.Container
         activeKey={tab}
         onSelect={(t) => {
            if (!t) return;
            setTab(t as keyof typeof tabs);
         }}
         transition={false}
      >
         <Stack className="h-100 starting-block overflow-hidden align-items-start flex-grow-1 ">
            <TopNavRail>
               {Object.entries(tabs)
                  .filter(([id]) => !extension || (id !== 'navigator' && id !== 'snippets'))
                  .map(([id, tab]) => (
                     <AiPulse
                        key={id}
                        on={
                           currentWorkspace?.status === WorkspaceStatus.PERSONAL_DEMO &&
                           stepEleven &&
                           tab.title === 'Saved'
                        }
                        onClick={() => {
                           if (
                              currentWorkspace?.status === WorkspaceStatus.PERSONAL_DEMO &&
                              stepEleven &&
                              tab.title === 'Saved'
                           ) {
                              setStepEleven();
                           }
                        }}
                        sparkleAfter
                     >
                        <TopNavRailButton eventKey={id} icon={tab.icon} title={tab.title} />
                     </AiPulse>
                  ))}
            </TopNavRail>
            <Stack className="h-100 starting-block hover-undimmer overflow-hidden">
               {workspaceSelector && (
                  <Dropdown>
                     <Dropdown.Toggle
                        className="btn btn-sm btn-secondary ms-2 fs-12p w-100 border-0 justify-content-start w-auto"
                        style={{ borderRadius: 0, height: '28px' }}
                     >
                        {currentWorkspace ? currentWorkspace?.name ?? '' : 'All Workspaces'}
                        <BiChevronDown size={16} />
                     </Dropdown.Toggle>
                     <Dropdown.Menu className="fs-10p">
                        <Dropdown.Item
                           active={!workspaceId}
                           onClick={() => workspaceSelector!(undefined)}
                        >
                           All Workspaces
                        </Dropdown.Item>
                        {workspaces.data && workspaces.data?.length > 0 && (
                           <>
                              <Dropdown.Divider />
                              {workspaces.data?.map((workspace) => (
                                 <Dropdown.Item
                                    active={workspaceId === workspace.id}
                                    key={workspace.id}
                                    onClick={() => workspaceSelector!(workspace.id)}
                                 >
                                    {workspace.name}
                                 </Dropdown.Item>
                              ))}
                           </>
                        )}
                     </Dropdown.Menu>
                  </Dropdown>
               )}
               <Tab.Content className="h-100 overflow-hidden border-top-line">
                  <Tab.Pane className="h-100 p-2 pb-0" eventKey="navigator">
                     {workspaceId && <Navigator workspaceId={workspaceId} />}
                  </Tab.Pane>
                  <Tab.Pane className="h-100 p-3" eventKey="saved">
                     <SavedQueries workspaceId={workspaceId} />
                  </Tab.Pane>
                  <Tab.Pane className="h-100 p-3" eventKey="logs">
                     <HistoryLog collapse myQueries workspaceId={workspaceId} />
                  </Tab.Pane>
                  <Tab.Pane className="p-2 h-100 overflow-auto" eventKey="snippets">
                     <QuerySnippets />
                  </Tab.Pane>
               </Tab.Content>
            </Stack>
         </Stack>
      </Tab.Container>
   );
};
export default StartingBlock;
