import { inject, injectable } from 'inversify';
import { Person } from '../entities';
import { SERVICE_PLAN } from '@runql/util';
import { TYPES } from '../types';
import { ApiService } from './ApiService';
import { ApiServiceInterface } from './ApiServiceInterface';
import { PersonRole } from '../enums';

const path = '/v1/person';

export interface CurrentExplorerResult {
   created: string;
   csvDownload?: boolean;
   email: string;
   firstName?: string;
   fullName?: string;
   id: number;
   lastName?: string;
   orgId: number;
   role: PersonRole;
}

@injectable()
export class PersonService implements ApiServiceInterface<Person> {
   private apiService: ApiService;

   constructor(@inject(TYPES.apiService) apiService: ApiService) {
      this.apiService = apiService;
   }

   async delete(id: string | number): Promise<null> {
      return await this.apiService.delete(`${path}/${id}`);
   }

   async get(id: string | number, params?: Record<string, string>): Promise<Person | undefined> {
      const result = await this.apiService.get<Person>(`${path}/${id}`, params);
      if (!result) {
         return undefined;
      }

      const data = result as Person;
      this.cast(data);
      return data;
   }

   async list(params?: Record<string, string>): Promise<Person[]> {
      const result = await this.apiService.get<Person[]>(path, params);
      if (!result) {
         return [];
      }

      const data = result as Person[];
      data.forEach((item) => this.cast(item));
      return data;
   }

   async listOptions(val: { includeWorkspaces?: boolean }): Promise<Person[]> {
      const params: Record<string, string> = {};

      if (val.includeWorkspaces) {
         params['includeWorkspaces'] = val.includeWorkspaces.toString();
      }

      return this.list(params);
   }

   async patch(
      id: string | number,
      body: Person,
      params?: Record<string, string>
   ): Promise<Person | undefined> {
      const result = await this.apiService.patch<Person>(`${path}/${id}`, body, params);
      if (!result) {
         return undefined;
      }

      const data = result as Person;
      this.cast(data);
      return data;
   }

   async post(body: Person, params?: Record<string, string>): Promise<Person | undefined> {
      const result = await this.apiService.post<Person>(`${path}`, body, params);
      if (!result) {
         return undefined;
      }

      const data = result as Person;
      this.cast(data);
      return data;
   }

   async put(
      _body: Person,
      _params?: Record<string, string> | undefined
   ): Promise<Person | undefined> {
      throw new Error('Method not implemented.');
   }

   async getCurrentExplorer(): Promise<CurrentExplorerResult | undefined> {
      const result = await this.apiService.get<CurrentExplorerResult>(`${path}/currentExplorer`);
      if (!result) {
         return undefined;
      }

      return result;
   }
   async getPlan(
      id: number | undefined,
      params?: Record<string, string>
   ): Promise<SERVICE_PLAN | undefined> {
      if (!id) {
         return undefined;
      }

      const result = await this.apiService.get<SERVICE_PLAN>(`${path}/getPlan/${id}`, params);
      if (!result) {
         return undefined;
      }

      return result;
   }
   cast(item: Person): void {
      if (!item) {
         return;
      }
   }
}
