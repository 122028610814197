import {
   BiDotsVerticalRounded,
   BiEditAlt,
   BiEdit,
   BiFilter,
   BiInfoCircle,
   BiSave,
   BiSearch,
   BiX,
   BiSolidRightArrow,
   BiSolidDownArrow,
   BiTrash,
   BiSolidEdit,
   BiSend,
   BiCheckbox,
   BiCheckboxChecked,
   BiHide,
   BiGitCompare,
} from 'react-icons/bi';
import { BsHeartFill, BsPin, BsPinFill, BsChatRightDots, BsSave, BsBraces } from 'react-icons/bs';
import { GoPlus, GoGraph, GoPersonAdd, GoDuplicate } from 'react-icons/go';
import { GrRevert } from 'react-icons/gr';
import {
   MdOutlineSave,
   MdSettings,
   MdCable,
   MdOutlineArrowBack,
   MdOutlineArrowForward,
   MdOutlineSettings,
   MdKey,
   MdUndo,
} from 'react-icons/md';
import { RiAlertFill } from 'react-icons/ri';
import { CiViewTable } from 'react-icons/ci';
import {
   VscHome,
   VscCodeOss,
   VscGitPullRequestGoToChanges,
   VscCoverage,
   VscGitStash,
   VscQuestion,
   VscHistory,
   VscSymbolSnippet,
   VscArrowCircleLeft,
   VscServerProcess,
   VscGithubAction,
   VscLayoutMenubar,
} from 'react-icons/vsc';
import {
   FaUsers,
   FaUsersSlash,
   FaLock,
   FaUnlock,
   FaExternalLinkAlt,
   FaLongArrowAltUp,
   FaCircle,
   FaCheck,
   FaEyeSlash,
   FaTable,
   FaLink,
   FaGlobe,
   FaDesktop,
   FaThumbsUp,
   FaThumbsDown,
   FaComment,
} from 'react-icons/fa';
import { PiTreeView, PiStarFour } from 'react-icons/pi';
import { FaRegCircleCheck, FaBoltLightning, FaChartSimple } from 'react-icons/fa6';
import { HiEllipsisHorizontal } from 'react-icons/hi2';
import { TbLayoutSidebarRightCollapse } from 'react-icons/tb';

export type { IconType } from 'react-icons';

export const IconAlert = RiAlertFill;
export const IconBack = MdOutlineArrowBack;
export const IconCircleBack = VscArrowCircleLeft;
export const IconDownArrow = BiSolidDownArrow;
export const IconEdit = BiEdit;
export const IconEditAlt = BiEditAlt;
export const IconFilter = BiFilter;
export const IconForward = MdOutlineArrowForward;
export const IconGraph = GoGraph;
export const IconHeart = BsHeartFill;
export const IconIndex = VscLayoutMenubar;
export const IconInformation = BiInfoCircle;
export const IconLocked = FaLock;
export const IconMenu = BiDotsVerticalRounded;
export const IconMenuAdmin = MdOutlineSettings;
export const IconMenuBizRequests = VscGitStash;
export const IconMenuCertRequests = VscCoverage;
export const IconSingleCertRequest = FaRegCircleCheck;
export const IconCheck = FaCheck;
export const IconMenuDataSources = MdCable;
export const IconMenuDocs = VscQuestion;
export const IconMenuHome = VscHome;
export const IconMenuQueryChanges = VscGitPullRequestGoToChanges;
export const IconMenuQueryHistory = VscHistory;
export const IconMenuSavedQueries = BsSave;
export const IconMenuSavedSnippets = VscSymbolSnippet;
export const IconMenuSuggestions = BsChatRightDots;
export const IconMenuWorkspaces = VscCodeOss;
export const IconNavigator = PiTreeView;
export const IconOpen = FaExternalLinkAlt;
export const IconPinned = BsPinFill;
export const IconPlus = GoPlus;
export const IconPrivate = FaUsersSlash;
export const IconRightArrow = BiSolidRightArrow;
export const IconSave = BiSave;
export const IconSaveAlt = MdOutlineSave;
export const IconSearch = BiSearch;
export const IconSettings = MdSettings;
export const IconShared = FaUsers;
export const IconStoredProc = VscServerProcess;
export const IconTable = CiViewTable;
export const IconTrash = BiTrash;
export const IconTrigger = VscGithubAction;
export const IconUnlocked = FaUnlock;
export const IconUnpinned = BsPin;
export const IconUnsaved = FaCircle;
export const IconUse = FaLongArrowAltUp;
export const IconX = BiX;
export const IconKey = MdKey;
export const IconUndo = MdUndo;
export const IconNewChat = BiSolidEdit;
export const IconSendChat = BiSend;
export const IconCheckBox = BiCheckbox;
export const IconCheckBoxChecked = BiCheckboxChecked;
export const IconInternal = FaEyeSlash;
export const IconAi = PiStarFour;
export const IconSuggestions = FaBoltLightning;
export const IconParameters = BsBraces;
export const IconResults = FaTable;
export const IconChart = FaChartSimple;
export const IconMore = HiEllipsisHorizontal;
export const IconCollapseRight = TbLayoutSidebarRightCollapse;
export const IconLink = FaLink;
export const IconWeb = FaGlobe;
export const IconDesktop = FaDesktop;
export const IconHide = BiHide;
export const IconCompare = BiGitCompare;
export const IconAddPerson = GoPersonAdd;
export const IconDuplicate = GoDuplicate;
export const IconRevert = GrRevert;
export const IconThumbsUp = FaThumbsUp;
export const IconThumbsDown = FaThumbsDown;
export const IconComment = FaComment;
