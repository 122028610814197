import { useCallback } from 'react';
import { useInjection } from 'inversify-react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { WalkthroughService } from '../services';
import { TYPES } from '../types';
import { WalkthroughStep } from '../entities/Walkthrough';
import { WorkspaceStatus } from '../entities';
import { QueryKey } from '../enums';
import { get, handleError } from '../utilities';
import { useExplorer } from '.';
import { useOptionalWorkspace } from '../hooks';

export const useGetWalkthroughs = (callbacks?: {
   errorCallback?: (error: unknown) => void;
   successCallback?: (data: Record<number, boolean>) => void;
}) => {
   const { data = {}, isLoading } = useQuery<Record<number, boolean>>(
      [QueryKey.Walkthrough],
      () => get<Record<number, boolean>>('/walkthrough/'),
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false,
         refetchOnMount: false,
         refetchOnReconnect: false,
         staleTime: Infinity,
         retry: false,
         onSuccess: callbacks?.successCallback,
         onError: callbacks?.errorCallback ?? handleError,
      }
   );
   return { data, isLoading };
};

const useCreateWalkthroughMutator = (callbacks?: {
   onErrorCallback?: (error: unknown, variables: WalkthroughStep, context: unknown) => void;
   onSuccessCallback?: () => void;
}) => {
   const service = useInjection<WalkthroughService>(TYPES.walkthroughService);
   const queryClient = useQueryClient();

   return useMutation<void, unknown, WalkthroughStep, unknown>({
      mutationFn: async (step: WalkthroughStep) => {
         await service.post(step);
         await queryClient.invalidateQueries(QueryKey.Walkthrough);
      },
      async onSuccess(data) {
         return;
      },
      onError: callbacks?.onErrorCallback,
   });
};

const isCurrentStep = (data: Record<number, boolean>, step: WalkthroughStep): boolean => {
   for (const key in WalkthroughStep) {
      const stepNumber = Number(key);
      if (isNaN(stepNumber)) continue;

      if (stepNumber < step && !data[stepNumber]) {
         return false;
      }

      if (stepNumber >= step && data[stepNumber]) {
         return false;
      }
   }
   return true;
};

export const useWalkthroughStep = (
   step?: WalkthroughStep,
   onlyDemo?: boolean
): [boolean, () => Promise<void>] => {
   const person = useExplorer();
   const { data, isLoading } = useGetWalkthroughs();
   const mutator = useCreateWalkthroughMutator();
   const workspace = useOptionalWorkspace();

   const completeStep = useCallback(async () => {
      if (step === undefined) return;
      try {
         await mutator.mutateAsync(step);
      } catch (err) {
         throw err;
      }
   }, [mutator, step]);

   if (isLoading || (person?.created && person.created < '2024-09-16')) {
      return [false, completeStep];
   }

   return [
      step !== undefined &&
         (onlyDemo !== true || workspace?.status === WorkspaceStatus.PERSONAL_DEMO) &&
         isCurrentStep(data, step),
      completeStep,
   ];
};
