import { useContext, useEffect, useState } from 'react';
import { Dropdown, Stack } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import { Link, NavLink } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { PersonRole, QueryKey } from '../enums';
import { useGetAuthorizedExplorerQuery, useOrg } from '../hooks';
import { api } from '../utilities';
import { ThemeContext } from './ThemeContext';
import { IconSettings } from '../utilities';
import { WEB_VERSION } from '../version';
import { useDesktopVersionSuffix } from '../hooks/desktop';
import { useInjection } from 'inversify-react';
import { LocalCredentialService } from '../services';
import { isDesktop } from '../services/DesktopQueryService';
import { TYPES } from '../types';
import { CredentialPersistence } from '@runql/util';

export const GlobalMenu = (): JSX.Element => {
   const themeContext = useContext(ThemeContext);
   const [isDarkMode, setIsDarkMode] = useState(false);

   const authedExplorer = useGetAuthorizedExplorerQuery().data;
   const localCredentialService = useInjection<LocalCredentialService>(
      TYPES.localCredentialService
   );
   const org = useOrg();

   useEffect(() => {
      if (themeContext && themeContext.mode) {
         setIsDarkMode(themeContext.mode === 'dark');
      }
   }, [themeContext]);

   const handleToggle = () => {
      if (themeContext && themeContext.toggleMode) {
         themeContext.toggleMode();
      }
      setIsDarkMode((prevState) => !prevState);
   };

   const queryClient = useQueryClient();

   const signOutClicked = async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      await api('DELETE', '/auth/1');
      queryClient.invalidateQueries([QueryKey.AUTH]);
      localCredentialService.clear(CredentialPersistence.SESSION_STORAGE);
      Sentry.setUser(null);
   };

   const hasPassword = !!authedExplorer?.hasPassword;

   const desktopVersionSuffix = useDesktopVersionSuffix();

   return (
      <Dropdown>
         <Dropdown.Toggle className="nav-link" variant="link">
            <IconSettings size={20} />
         </Dropdown.Toggle>
         <Dropdown.Menu>
            <Dropdown.ItemText className="fs-11p">
               <Stack gap={1}>
                  <div>{`runQL v${WEB_VERSION}${isDesktop() ? desktopVersionSuffix : ''}`}</div>
                  {org && <div>{org.orgName}</div>}
                  {authedExplorer && <div>{authedExplorer.person.email}</div>}
               </Stack>
            </Dropdown.ItemText>
            <Dropdown.Divider />
            {authedExplorer?.person.role === PersonRole.ORG_ADMIN && (
               <>
                  <Dropdown.Item as={NavLink} to={'/admin/accounts'}>
                     Org Accounts
                  </Dropdown.Item>
                  <Dropdown.Item as={NavLink} to={'/admin/settings'}>
                     Org Settings
                  </Dropdown.Item>
                  <Dropdown.Item as={NavLink} to={'/admin/compliance'}>
                     Compliance
                  </Dropdown.Item>
                  <Dropdown.Divider />
               </>
            )}
            <Dropdown.Item as={'button'} onClick={() => handleToggle()}>
               {isDarkMode ? 'Light' : 'Dark'} Mode
            </Dropdown.Item>
            {hasPassword && (
               <Dropdown.Item as={NavLink} to={'/change-password'}>
                  Change Password
               </Dropdown.Item>
            )}
            <Dropdown.Divider />
            <Dropdown.Item as={Link} onClick={signOutClicked} to={'/'}>
               Sign Out
            </Dropdown.Item>
         </Dropdown.Menu>
      </Dropdown>
   );
};

export default GlobalMenu;
