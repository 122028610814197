"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WalkthroughStep = void 0;
var WalkthroughStep;
(function (WalkthroughStep) {
    WalkthroughStep[WalkthroughStep["PERSONAL_DEMO_WORKSPACE"] = 1] = "PERSONAL_DEMO_WORKSPACE";
    WalkthroughStep[WalkthroughStep["IDE_CELL"] = 2] = "IDE_CELL";
    WalkthroughStep[WalkthroughStep["SUGGESTED_QUERY"] = 3] = "SUGGESTED_QUERY";
    WalkthroughStep[WalkthroughStep["FIRST_RUN_BUTTON"] = 4] = "FIRST_RUN_BUTTON";
    WalkthroughStep[WalkthroughStep["CHART_TAB"] = 5] = "CHART_TAB";
    WalkthroughStep[WalkthroughStep["AI_TAB"] = 6] = "AI_TAB";
    WalkthroughStep[WalkthroughStep["GENERATE_INLINE_COMMENTS"] = 7] = "GENERATE_INLINE_COMMENTS";
    WalkthroughStep[WalkthroughStep["SECOND_TAB"] = 8] = "SECOND_TAB";
    WalkthroughStep[WalkthroughStep["PYTHON_RUN_BUTTON"] = 9] = "PYTHON_RUN_BUTTON";
    WalkthroughStep[WalkthroughStep["PYTHON_SAVE"] = 10] = "PYTHON_SAVE";
    WalkthroughStep[WalkthroughStep["RESULTS_THREE_DOT_MENU"] = 11] = "RESULTS_THREE_DOT_MENU";
    WalkthroughStep[WalkthroughStep["VERSIONS"] = 12] = "VERSIONS";
    WalkthroughStep[WalkthroughStep["SAVED_QUERIES"] = 13] = "SAVED_QUERIES";
    WalkthroughStep[WalkthroughStep["DATA_SOURCES"] = 14] = "DATA_SOURCES";
})(WalkthroughStep || (exports.WalkthroughStep = WalkthroughStep = {}));
