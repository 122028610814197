import { Spinner } from 'react-bootstrap';

export const LoadingSpinner = ({
   centered,
   size,
}: {
   centered?: boolean;
   size?: number;
}): JSX.Element => {
   centered ??= true;
   size ??= 15;
   const spinner = (
      <Spinner animation="border" role="status" style={{ height: `${size}px`, width: `${size}px` }}>
         <span className="visually-hidden">Loading...</span>
      </Spinner>
   );
   if (centered) {
      return <div className="text-center">{spinner}</div>;
   }
   return spinner;
};

export default LoadingSpinner;
