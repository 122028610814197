import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import {
   Button as RbButton,
   Nav,
   NavItem,
   NavLink,
   TabContainer,
   TabContent,
   TabPane,
   Stack,
   Spinner,
} from 'react-bootstrap';
import classNames from 'classnames';

import { SERVICE_PLANS } from '@runql/util';
import { QueryVersion, WorkspaceStatus, DataChatThreadState } from '../entities';
import {
   Page,
   Chat,
   ChatHistory,
   AnswerCard,
   AnswersLite,
   QueryGetter,
   QueryWidget,
   QueryChart,
   QueryResults,
   ErrorBoundary,
   queryPanelTabs,
   Button,
   ChatDeletedQueryWidget,
   QueryFeedbackWidget,
} from '../components';
import QueryParams from '../components/QueryParams';
import {
   useOrg,
   usePerson,
   useWorkspaces,
   useRunQueries,
   useListDataChatThreads,
   useGetDataChatThread,
   usePatchDataChatThread,
   useAddDataChatMessage,
} from '../hooks';
import { PersonRole } from '../enums';
import { IconX, IconInformation } from '../utilities/icons';
import { QueryProvider } from '../hooks';
import { QueryPanelProvider } from '../hooks/QueryPanelContext';

const PageContent = ({
   selectedQuery,
   setSelectedQuery,
}: {
   selectedQuery?: QueryVersion;
   setSelectedQuery: React.Dispatch<React.SetStateAction<QueryVersion | undefined>>;
}) => {
   const navigate = useNavigate();
   const params = useParams();
   const threadId = params.threadId !== undefined ? Number(params.threadId) : null;

   const org = useOrg();
   const lite = !(org?.plan && SERVICE_PLANS[org.plan].businessAnswers);
   const person = usePerson();
   const isAnalyst = person.role !== PersonRole.ORG_BUSINESS_USER;
   const workspaces = useWorkspaces()?.filter((w) => w.status === WorkspaceStatus.OPEN);
   const threadsQuery = useListDataChatThreads();
   const threads = threadsQuery.data ?? [];
   const currentThreadQuery = useGetDataChatThread(threadId);
   const currentThread = currentThreadQuery.data;
   const [queryTab, setQueryTab] = useState('');
   const { run, runStatus, modals } = useRunQueries();
   const { isRunning, results } = (selectedQuery && runStatus(selectedQuery)) ?? {
      isRunning: false,
      results: undefined,
   };
   const patchDataChatThread = usePatchDataChatThread();
   const addDataChatMessage = useAddDataChatMessage();
   const includeParametersTab = selectedQuery?.parameters?.length ?? -1 > 0;
   const addMessage = addDataChatMessage.mutate;

   const lastError = results?.at(-1)?.at(-1)?.error;
   let queryError: string | undefined =
      lastError && typeof lastError === 'object' && 'message' in lastError
         ? lastError.message
         : lastError;
   const currentThreadId = currentThread?.id;
   useEffect(() => {
      if (!queryError || !currentThreadId) return;
      addMessage({
         id: currentThreadId,
         message: {
            content: `This error happened when fetching the data below:\n${queryError}`,
            queryVersionId: selectedQuery?.id,
         },
         state: DataChatThreadState.OPEN,
      });
   }, [addMessage, queryError, currentThreadId, selectedQuery]);

   const runQuery = (query: QueryVersion, force: boolean = false) => {
      setQueryTab('results');
      setSelectedQuery(query);

      const status = runStatus(query);

      if (status?.isRunning) {
         return;
      }

      if (!force && status?.results) {
         return;
      }

      run({ queryVersion: query });
   };

   let error;
   if (threadsQuery.isError || currentThreadQuery.isError) {
      error = 'Failed to load conversations';
   } else if (org?.plan === undefined || workspaces === undefined) {
      error = <Spinner />;
   } else if (lite && isAnalyst) {
      error =
         'Upgrade your plan to supercharge your handling of ad hoc data requests from business users.';
   } else if (isAnalyst && threads.length === 0) {
      error = 'No requests yet';
   } else if (!isAnalyst && workspaces?.length === 0) {
      error =
         'You have not been added to any workspaces. Please ask your team to add you to the workspaces related to your business questions.';
   }
   if (error) {
      return (
         <Page>
            <div className="h-100 d-flex justify-content-center align-items-center text-muted">
               {error}
            </div>
         </Page>
      );
   }
   return (
      <Page
         nav={
            lite ? undefined : threads.length ? (
               <ChatHistory
                  currentId={threadId}
                  noNewThread={isAnalyst}
                  onChange={(current) => {
                     navigate(current?.id ? `/answers/${current.id}` : '/answers');
                     setSelectedQuery(undefined);
                  }}
                  threads={threads}
                  title={isAnalyst ? 'Pending Requests' : 'My Answers'}
               />
            ) : undefined
         }
         noPad
         noScroll
      >
         <PanelGroup autoSaveId="answers" className="w-100 h-100" direction="vertical">
            <Panel defaultSize={40} id="chat" minSize={0} order={1}>
               {lite && <AnswersLite onRunQuery={runQuery} />}
               {!lite && (
                  <Chat
                     greeting="What would you like to know?"
                     newChatFooter={
                        <div>Your workspaces: {workspaces?.map((w) => w.name).join(', ')}</div>
                     }
                     noNewThread={isAnalyst}
                     onNewThread={(thread) => {
                        navigate(`/answers/${thread.id}`);
                     }}
                     renderQueries={(queries) =>
                        isAnalyst ? (
                           queries.map((q) =>
                              q.query?.queryVersion ? (
                                 <QueryGetter key={q.id} queryVersion={q.query.queryVersion}>
                                    {(queryVersion) => (
                                       <>
                                          <QueryWidget
                                             collapse
                                             dataChatThreadId={threadId ?? undefined}
                                             onClick={async () => {
                                                patchDataChatThread.mutate({
                                                   id: currentThread!.id!,
                                                   data: { workspaceId: q.query.workspace.id },
                                                });
                                                return true;
                                             }}
                                             queryVersion={queryVersion}
                                             source="answers"
                                          />
                                          {threadId && (
                                             <div className="d-flex justify-content-end">
                                                <QueryFeedbackWidget
                                                   creator={currentThread?.creator}
                                                   dataChatMessageQuery={q}
                                                   dataChatThreadId={threadId}
                                                   personId={person.id!}
                                                />
                                             </div>
                                          )}
                                       </>
                                    )}
                                 </QueryGetter>
                              ) : (
                                 <ChatDeletedQueryWidget key={q.id} />
                              )
                           )
                        ) : (
                           <div className="answers-grid">
                              {threadId &&
                                 queries.map((q) =>
                                    q.query?.queryVersion ? (
                                       <QueryGetter key={q.id} queryVersion={q.query.queryVersion}>
                                          {(queryVersion) => (
                                             <AnswerCard
                                                dataChatMessageQuery={q}
                                                dataChatThreadId={threadId}
                                                onClick={runQuery}
                                                personId={person.id}
                                                queryVersion={queryVersion}
                                                selected={selectedQuery?.id === queryVersion.id}
                                             />
                                          )}
                                       </QueryGetter>
                                    ) : (
                                       <div className="card py-2 px-3 bg-secondary" key={q.id}>
                                          <Stack direction="horizontal" gap={1}>
                                             <IconInformation className="flex-shrink-0" size={14} />
                                             <span className="lh-1">
                                                This data set is no longer available
                                             </span>
                                          </Stack>
                                       </div>
                                    )
                                 )}
                           </div>
                        )
                     }
                     thread={currentThread ?? null}
                  />
               )}
            </Panel>
            {(isRunning || !!results?.length) && (
               <>
                  <PanelResizeHandle className="panelHandle horizontal">
                     <div className="panelHandleLine" />
                  </PanelResizeHandle>
                  <Panel
                     collapsible
                     defaultSize={60}
                     id="results"
                     minSize={20}
                     onCollapse={() => setSelectedQuery(undefined)}
                     order={2}
                  >
                     {isRunning && (
                        <Stack
                           className="h-100 w-100 justify-content-center align-items-center "
                           gap={2}
                        >
                           <Spinner />
                           <div>Loading results...</div>
                        </Stack>
                     )}
                     {!isRunning && !!results?.length && (
                        <TabContainer
                           activeKey={queryTab}
                           onSelect={(t) => {
                              if (t !== null) {
                                 setQueryTab(t as keyof typeof queryPanelTabs);
                              }
                           }}
                           transition={false}
                        >
                           <div className="h-100 d-flex flex-column">
                              <Nav
                                 className="bottom-explore-tabs explore-tabs bg-secondary"
                                 id="exploreTabNavQuery"
                                 role="tablist"
                              >
                                 <NavItem
                                    bsPrefix="explore-tab"
                                    className={classNames('bottom-explore-tab', {
                                       active: queryTab === 'results',
                                    })}
                                 >
                                    <NavLink
                                       className="position-relative override-active-pointer"
                                       eventKey="results"
                                       role="tab"
                                    >
                                       Results
                                    </NavLink>
                                 </NavItem>

                                 {selectedQuery?.chartConfig && (
                                    <NavItem
                                       bsPrefix="explore-tab"
                                       className={classNames('bottom-explore-tab', {
                                          active: queryTab === 'chart',
                                       })}
                                    >
                                       <NavLink
                                          className="position-relative override-active-pointer"
                                          eventKey="chart"
                                          role="tab"
                                       >
                                          Chart
                                       </NavLink>
                                    </NavItem>
                                 )}
                                 {includeParametersTab && (
                                    <NavItem
                                       bsPrefix="explore-tab"
                                       className={classNames('bottom-explore-tab', {
                                          active: queryTab === 'params',
                                       })}
                                    >
                                       <NavLink
                                          className="position-relative override-active-pointer"
                                          eventKey="params"
                                          role="tab"
                                       >
                                          Parameters
                                       </NavLink>
                                    </NavItem>
                                 )}
                                 <div className="flex-grow-1" />
                                 <div className="d-flex align-items-center">
                                    <RbButton
                                       onClick={() => {
                                          setSelectedQuery(undefined);
                                       }}
                                       size="sm"
                                       variant="link"
                                    >
                                       <IconX />
                                    </RbButton>
                                 </div>
                              </Nav>
                              <TabContent className="flex-grow-1 overflow-hidden bottom-query-panel-tab-content">
                                 <TabPane
                                    className="h-100 overflow-hidden"
                                    eventKey="results"
                                    key="results"
                                    mountOnEnter={true}
                                 >
                                    <ErrorBoundary
                                       title={'An error occurred when retrieving results.'}
                                    >
                                       <QueryResults
                                          queryReturn={results.at(-1)?.at(-1)}
                                          readOnly
                                          simplified
                                       />
                                    </ErrorBoundary>
                                 </TabPane>
                                 {selectedQuery?.chartConfig && (
                                    <TabPane
                                       className="h-100 overflow-hidden"
                                       eventKey="chart"
                                       key="chart"
                                       mountOnEnter={true}
                                    >
                                       <ErrorBoundary
                                          className="h-100"
                                          title={'An error occurred with chart generation.'}
                                       >
                                          <QueryChart
                                             queryReturn={results.at(-1)?.at(-1)}
                                             queryVersion={selectedQuery}
                                          />
                                       </ErrorBoundary>
                                    </TabPane>
                                 )}
                                 <TabPane
                                    className="h-100 overflow-hidden"
                                    eventKey="params"
                                    key="params"
                                    mountOnEnter={true}
                                 >
                                    <ErrorBoundary
                                       title={'An error occurred when viewing parameters.'}
                                    >
                                       <div className="p-3">
                                          <div className="d-flex flex-row">
                                             <QueryParams includeInstructions={false} />
                                             <Button
                                                onClick={() => {
                                                   if (selectedQuery) {
                                                      runQuery(selectedQuery, true);
                                                   }
                                                }}
                                             >
                                                Refresh Data
                                             </Button>
                                          </div>
                                       </div>
                                    </ErrorBoundary>
                                 </TabPane>
                              </TabContent>
                           </div>
                        </TabContainer>
                     )}
                  </Panel>
               </>
            )}
         </PanelGroup>
         {modals}
      </Page>
   );
};

export const AnswersPage = () => {
   const [selectedQuery, setSelectedQuery] = useState<QueryVersion>();

   return (
      <QueryProvider queryVersion={selectedQuery}>
         <QueryPanelProvider defaultTab="results">
            <PageContent selectedQuery={selectedQuery} setSelectedQuery={setSelectedQuery} />
         </QueryPanelProvider>
      </QueryProvider>
   );
};

export default AnswersPage;
