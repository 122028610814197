import { SERVICE_PLAN } from '@runql/util';
import { LoadingSpinner } from '../../../components';
import { ExploreTab } from '../../../entities';
import { useWorkspace } from '../../../hooks';
import { QueryPanelProvider } from '../../../hooks/QueryPanelContext';
import { useTitle } from '../../../utilities';
import QueryPage from './QueryPage';

const ExploreTabContent = ({
   active,
   exploreTab,
   plan,
}: {
   active: boolean;
   exploreTab: ExploreTab;
   plan: SERVICE_PLAN;
}): JSX.Element => {
   const queryVersion = exploreTab.queryVersion;
   const workspace = useWorkspace();
   let title = queryVersion?.title;
   if (title) title += ' - ';
   if (workspace?.name) title = (title ?? '') + workspace.name;
   useTitle(title, active);

   if (!queryVersion) return <LoadingSpinner />;
   return (
      <QueryPanelProvider
         defaultTab={
            exploreTab.dataChatThreadId
               ? 'requests'
               : !queryVersion?.query?.latestVersion?.version
               ? 'suggestions'
               : 'docs'
         }
      >
         <QueryPage active={active} exploreTab={exploreTab} plan={plan} />
      </QueryPanelProvider>
   );
};

export default ExploreTabContent;
