import React, { useMemo, useState, useId } from 'react';
import { Form, Stack, Popover, InputGroup, Button } from 'react-bootstrap';
import type { OpenDialogOptions } from 'electron';
import { Controller, useFormContext } from 'react-hook-form';

import { DBMS } from '@runql/util';

import {
   ConnectionAccessType,
   DataConnection,
   SSHAuthenticationMethod,
} from '../../../../entities';
import { isDesktop } from '../../../../services/DesktopQueryService';
import { SelectOption, ToggleTip, TypeSelect } from '../../../../components';
import { useListDataConnectionsQuery } from '../../../../hooks';
import { useIsDesktopFlagEnabled } from '../../../../hooks/desktop';

export type ConnectionEditType = 'connection' | 'credential' | 'read-only';

export const HideDetailsField = React.forwardRef((props: any, ref) => (
   <Form.Group>
      <Form.Label>
         <Stack className="align-items-center" direction="horizontal" gap={1}>
            <span>Hide Connection Details</span>
            <ToggleTip>
               <Popover id="ssh-key-info-popover">
                  <Popover.Body>
                     The explorer role can use this connection, but cannot see its details such as
                     the host and port.
                  </Popover.Body>
               </Popover>
            </ToggleTip>
         </Stack>
      </Form.Label>
      <Form.Check {...props} ref={ref} type="switch" />
   </Form.Group>
));

export const AccessTypeField = React.forwardRef((props: any, ref) => (
   <>
      <hr />
      <Form.Group>
         <Form.Label>Credentials</Form.Label>
         <Form.Select {...props} ref={ref}>
            <option value={ConnectionAccessType.INDIVIDUAL}>
               Individual (stored only on your computer)
            </option>
            <option value={ConnectionAccessType.SHARED}>Shared (stored in the cloud)</option>
         </Form.Select>
         <Form.Text className="d-block">
            Individual connections are always stored encrypted on your computer
         </Form.Text>
         <Form.Text className="d-block">
            Shared credentials are stored encrypted on the runQL servers
         </Form.Text>
      </Form.Group>
   </>
));

export const PasswordField = ({
   fieldPath = 'password',
   label = 'Password',
   placeholder,
   isDisabled = false,
   isRequired = true,
}: {
   fieldPath?: string;
   isDisabled?: boolean;
   isRequired?: boolean;
   label?: string;
   placeholder?: string;
}) => {
   const {
      control,
      setValue,
      formState: { touchedFields },
   } = useFormContext();

   const [passwordCleared, setPasswordCleared] = React.useState(false);
   const handleBlur =
      (checkValue: string = '', setValueOnFocus: string = 'CURRENT') =>
      (e: React.FocusEvent<HTMLInputElement>) => {
         const { name, value } = e.target;
         if (value === checkValue && !(name in touchedFields) && passwordCleared) {
            setValue(name, setValueOnFocus, {
               shouldTouch: false,
               shouldDirty: false,
            });
            setPasswordCleared(false);
            return;
         }
         // Need to ensure the field is registered as touched.
         setValue(name, value, { shouldTouch: true });
      };

   const handleFocus =
      (checkValue: string = 'CURRENT', setValueOnFocus: string = '') =>
      (e: React.FocusEvent<HTMLInputElement>) => {
         const { name, value } = e.target;
         if (value === checkValue) {
            setValue(name, setValueOnFocus, {
               shouldTouch: false,
               shouldDirty: false,
            });
            setPasswordCleared(true);
         }
      };

   return (
      <Controller
         control={control}
         name={fieldPath}
         render={({ field, fieldState }) => (
            <Form.Group>
               <Form.Label>{label}</Form.Label>{' '}
               {isRequired ? <span className="text-danger">*</span> : null}
               <Form.Control
                  {...field}
                  disabled={isDisabled}
                  isInvalid={fieldState.error && fieldState.isTouched}
                  isValid={fieldState.isTouched && !fieldState.error}
                  onBlur={handleBlur('', 'CURRENT')}
                  onFocus={handleFocus('CURRENT', '')}
                  placeholder={placeholder ?? label}
                  required={isRequired}
                  type="password"
               />
               <Form.Control.Feedback type="invalid">
                  {fieldState.error?.message}
               </Form.Control.Feedback>
            </Form.Group>
         )}
      />
   );
};

export const ConnectionCredentialsFields = ({
   autoFocusName,
   readonly,
}: {
   autoFocusName?: boolean;
   readonly?: boolean;
}) => {
   const { control } = useFormContext();

   return (
      <>
         <Controller
            control={control}
            name="accountName"
            render={({ field, fieldState }) => (
               <Form.Group>
                  <Form.Label>User</Form.Label> <span className="text-danger">*</span>
                  <Form.Control
                     {...field}
                     autoFocus={autoFocusName}
                     disabled={readonly}
                     isInvalid={fieldState.error && fieldState.isTouched}
                     isValid={fieldState.isTouched && !fieldState.error}
                     placeholder="User"
                     required={true}
                  />
                  <Form.Control.Feedback type="invalid">
                     {fieldState.error?.message}
                  </Form.Control.Feedback>
               </Form.Group>
            )}
         />
         <PasswordField isDisabled={readonly} />
      </>
   );
};

export const CredentialPersistenceField = () => {
   const id = useId();
   const { control, getValues, setValue } = useFormContext();
   const isSessionCredentialsEnabled = useIsDesktopFlagEnabled('sessionCredentials');

   if (isDesktop() && !isSessionCredentialsEnabled) {
      return null;
   }

   return (
      <Controller
         control={control}
         name="rememberCredential"
         render={({ field: { value, onChange }, fieldState }) => (
            <Form.Group>
               <Form.Check
                  checked={!value}
                  className="d-flex align-items-center gap-2 mb-0"
                  id={id}
                  label="Clear credentials on log out"
                  onChange={(e) => {
                     onChange(!e.target.checked);
                     if (getValues('password') === 'CURRENT') {
                        setValue('password', '', { shouldDirty: true });
                     }
                  }}
                  type="checkbox"
               />
               <Form.Control.Feedback type="invalid">
                  {fieldState.error?.message}
               </Form.Control.Feedback>
            </Form.Group>
         )}
      />
   );
};

export const SSHConnectionFields = ({ useSSH }: { useSSH: boolean }) => {
   const { control } = useFormContext();

   return (
      <>
         <Stack gap={3}>
            <Controller
               control={control}
               name="sshHost"
               render={({ field, fieldState }) => (
                  <Form.Group>
                     <Form.Label>
                        SSH Host <span className="text-danger">*</span>
                     </Form.Label>
                     <Form.Control
                        {...field}
                        isInvalid={fieldState.error && fieldState.isTouched}
                        isValid={fieldState.isTouched && !fieldState.error}
                        placeholder="SSH Host"
                        required={useSSH}
                     />
                     <Form.Control.Feedback type="invalid">
                        {fieldState.error?.message}
                     </Form.Control.Feedback>
                  </Form.Group>
               )}
            />
            <Controller
               control={control}
               name="sshPort"
               render={({ field, fieldState }) => (
                  <Form.Group>
                     <Form.Label>
                        SSH Port <span className="text-danger">*</span>
                     </Form.Label>
                     <Form.Control
                        {...field}
                        isInvalid={fieldState.error && fieldState.isTouched}
                        isValid={fieldState.isTouched && !fieldState.error}
                        onChange={(e) => {
                           const value = parseInt(e.target.value);
                           field.onChange(isNaN(value) ? e.target.value : value);
                        }}
                        placeholder="SSH Port"
                        required={useSSH}
                     />
                     <Form.Control.Feedback type="invalid">
                        {fieldState.error?.message}
                     </Form.Control.Feedback>
                  </Form.Group>
               )}
            />
         </Stack>
      </>
   );
};

export const SSHCredentialFields = ({
   editType,
   isSshKeyFilePermitted,
   useSSH,
}: {
   editType: ConnectionEditType;
   isSshKeyFilePermitted: boolean;
   useSSH: boolean;
}) => {
   const {
      control,
      watch,
      setValue,
      clearErrors,
      formState: { touchedFields },
   } = useFormContext();
   const sshAuthMethod = +watch('sshAuthMethod');

   const handleClickSelectSshKeyFile = async () => {
      if (sshAuthMethod !== SSHAuthenticationMethod.KEY_FILE || !globalThis.runql) {
         return;
      }

      const openDialogOptions: OpenDialogOptions = {
         properties: ['openFile', 'showHiddenFiles', 'dontAddToRecent'],
         title: 'Select SSH Key File',
         message: 'Please select the SSH key file to use with this connection',
      };

      const filePath = await globalThis.runql.openFileDialog(openDialogOptions);

      // No file selected?
      if (filePath === null) {
         return;
      }

      setValue('sshKeyFile', filePath, { shouldDirty: true });
   };

   const handleBlur =
      (checkValue: string = '', setValueOnFocus: string = 'CURRENT') =>
      (e: React.FocusEvent<HTMLInputElement>) => {
         const { name, value } = e.target;
         if (value === checkValue && !(name in touchedFields)) {
            setValue(name, setValueOnFocus, {
               shouldTouch: false,
               shouldDirty: false,
            });
            return;
         }
         // Need to ensure the field is registered as touched.
         setValue(name, e.target.value, { shouldTouch: true });
      };

   const handleFocus =
      (checkValue: string = 'CURRENT', setValueOnFocus: string = '') =>
      (e: React.FocusEvent<HTMLInputElement>) => {
         const { name, value } = e.target;
         if (value === checkValue) {
            setValue(name, setValueOnFocus, {
               shouldTouch: false,
               shouldDirty: false,
            });
         }
      };

   return (
      <>
         <Stack gap={3}>
            <Controller
               control={control}
               name="sshAuthMethod"
               render={({ field, fieldState }) => (
                  <Form.Group>
                     <Form.Label>SSH Authentication Method</Form.Label>
                     <Form.Select
                        disabled={!isSshKeyFilePermitted || editType === 'read-only'}
                        {...field}
                        onChange={(e) => {
                           field.onChange(+e.target.value);
                           setValue('sshPassword', '', { shouldDirty: true });
                           clearErrors('sshPassword');
                           setValue('sshKeyFile', '', { shouldDirty: true });
                        }}
                     >
                        <option value={SSHAuthenticationMethod.PASSWORD}>Password</option>
                        <option value={SSHAuthenticationMethod.KEY_FILE}>SSH Key</option>
                     </Form.Select>
                     {!isDesktop() && (
                        <Form.Text className="d-block">
                           We do not support private keys on the web. Please use the desktop app if
                           required.
                        </Form.Text>
                     )}
                     <Form.Control.Feedback type="invalid">
                        {fieldState.error?.message}
                     </Form.Control.Feedback>
                  </Form.Group>
               )}
            />
            <Controller
               control={control}
               name="sshUsername"
               render={({ field, fieldState }) => (
                  <Form.Group>
                     <Form.Label>
                        SSH Username <span className="text-danger">*</span>
                     </Form.Label>
                     <Form.Control
                        {...field}
                        disabled={editType === 'read-only'}
                        isInvalid={fieldState.error && fieldState.isTouched}
                        isValid={fieldState.isTouched && !fieldState.error}
                        placeholder="SSH Username"
                        required={useSSH}
                     />
                     <Form.Control.Feedback type="invalid">
                        {fieldState.error?.message}
                     </Form.Control.Feedback>
                  </Form.Group>
               )}
            />
            {sshAuthMethod === SSHAuthenticationMethod.KEY_FILE && (
               <Controller
                  control={control}
                  name="sshKeyFile"
                  render={({ field, fieldState }) => (
                     <Form.Group>
                        <Form.Label>
                           <Stack className="align-items-center" direction="horizontal" gap={2}>
                              <span>
                                 SSH Key File <span className="text-danger">*</span>{' '}
                              </span>
                              <ToggleTip>
                                 <Popover id="ssh-key-info-popover">
                                    <Popover.Body>
                                       runQL only stores the path to your SSH key file, and the file
                                       content is never sent to our servers.
                                    </Popover.Body>
                                 </Popover>
                              </ToggleTip>
                           </Stack>
                        </Form.Label>
                        <InputGroup onClick={handleClickSelectSshKeyFile}>
                           <Button
                              className="fs-xs chooseFileButton"
                              disabled={editType === 'read-only'}
                              variant="outline-secondary"
                           >
                              <span className="">Choose File</span>
                           </Button>
                           <Form.Control
                              className="chooseFileInput"
                              {...field}
                              disabled={editType === 'read-only'}
                              placeholder="No file chosen"
                              readOnly={true}
                              required={
                                 useSSH && sshAuthMethod === SSHAuthenticationMethod.KEY_FILE
                              }
                           />
                        </InputGroup>
                        <Form.Control.Feedback type="invalid">
                           {fieldState.error?.message}
                        </Form.Control.Feedback>
                     </Form.Group>
                  )}
               />
            )}
            <Controller
               control={control}
               name="sshPassword"
               render={({ field, fieldState }) => (
                  <Form.Group>
                     <Form.Label>
                        {`SSH ${
                           sshAuthMethod === SSHAuthenticationMethod.PASSWORD
                              ? 'Password'
                              : 'Key Passphrase'
                        }`}{' '}
                        {useSSH && sshAuthMethod === SSHAuthenticationMethod.PASSWORD ? (
                           <span className="text-danger">*</span>
                        ) : null}
                     </Form.Label>
                     <Form.Control
                        {...field}
                        disabled={editType === 'read-only'}
                        isInvalid={fieldState.error && fieldState.isTouched}
                        isValid={fieldState.isTouched && !fieldState.error}
                        onBlur={handleBlur('', 'CURRENT')}
                        onFocus={handleFocus('CURRENT', '')}
                        placeholder={`SSH ${
                           sshAuthMethod === SSHAuthenticationMethod.PASSWORD
                              ? 'Password'
                              : 'Key Passphrase'
                        }`}
                        required={useSSH && sshAuthMethod === SSHAuthenticationMethod.PASSWORD}
                        type="password"
                     />
                     <Form.Control.Feedback type="invalid">
                        {fieldState.error?.message}
                     </Form.Control.Feedback>
                  </Form.Group>
               )}
            />
         </Stack>
      </>
   );
};

export const ConnectionParamSelectField = ({
   connectionField,
   dbms,
   isRequired,
   label,
   name = 'host',
   onExistingSelect,
}: {
   connectionField: keyof DataConnection;
   dbms: DBMS;
   isRequired?: boolean;
   label?: string | React.ReactNode;
   name?: string;
   onExistingSelect?: (value: DataConnection) => void;
}) => {
   // Get the list of data connections.
   const dataConnections = useListDataConnectionsQuery();

   const displayLabel =
      label ||
      (typeof connectionField === 'string'
         ? connectionField.charAt(0).toUpperCase() + connectionField.slice(1)
         : connectionField);

   const baseConnectionList = useMemo(() => {
      return (
         dataConnections.data?.items
            .filter((connection) => connection[connectionField] && connection.dbms === dbms)
            .map((connection) => ({
               key: connection.id,
               value: `${connection.name} - ${connection[connectionField]}`,
            })) ?? []
      );
   }, [dataConnections.data, dbms, connectionField]);

   const [filterText, setFilterText] = useState('');
   const filteredConnections = useMemo(() => {
      if (!filterText) return baseConnectionList;
      const lowerCaseSearch = filterText.toLowerCase();
      return baseConnectionList.filter((option) =>
         option.value.toLowerCase().includes(lowerCaseSearch)
      );
   }, [filterText, baseConnectionList]);

   const { control, setValue } = useFormContext();

   function handleChange(option: SelectOption): void {
      if (option.key && onExistingSelect) {
         const connection = dataConnections.data?.items.find((c) => c.id === option.key);
         if (connection) {
            const fieldValue = connection[connectionField] as unknown as string;
            setValue(name, fieldValue);
            setFilterText(fieldValue ?? '');
            onExistingSelect(connection);
         } else {
            console.warn('Could not find connection with id', option.key);
            setValue(name, option.value);
            setFilterText(option.value);
         }
      } else {
         setValue(name, option.value);
         setFilterText(option.value);
      }
   }

   return (
      <Controller
         control={control}
         name={name}
         render={({ field: { value }, fieldState }) => (
            <Form.Group>
               <Form.Label>
                  {typeof displayLabel === 'string' ? (
                     <>
                        {displayLabel} {isRequired && <span className="text-danger">*</span>}
                     </>
                  ) : (
                     displayLabel
                  )}
               </Form.Label>
               <TypeSelect
                  allowCustom
                  isInvalid={!!fieldState.error && fieldState.isTouched}
                  onChange={handleChange}
                  options={filteredConnections}
                  placeHolder={typeof displayLabel === 'string' ? displayLabel : undefined}
                  value={value}
               />
               <Form.Control.Feedback type="invalid">
                  {fieldState.error?.message}
               </Form.Control.Feedback>
            </Form.Group>
         )}
      />
   );
};
