import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';

import { useTheme } from '../ThemeContext';

interface Props {
   newCode: string;
   newName?: string;
   oldCode: string;
   oldName?: string;
   splitView?: boolean;
}

const defaultStyles = {
   variables: {
      light: {
         diffViewerBackground: 'transparent',
         diffViewerColor: '#0b0f19',
         addedBackground: 'rgba(34,197,94,.12)',
         addedColor: '#0b0f19',
         removedBackground: 'rgba(239, 68, 68, 0.12)',
         removedColor: '#0b0f19',
         wordAddedBackground: 'rgba(34,197,94,.12)',
         wordRemovedBackground: 'rgba(239, 68, 68, 0.12)',
         addedGutterBackground: 'rgba(34,197,94,.12)',
         removedGutterBackground: 'rgba(239, 68, 68, 0.12)',
         gutterBackground: 'transparent',
         gutterBackgroundDark: 'transparent',
         highlightBackground: 'transparent',
         highlightGutterBackground: 'transparent',
         codeFoldGutterBackground: 'transparent',
         codeFoldBackground: 'transparent',
         emptyLineBackground: 'transparent',
         gutterColor: '#9397ad',
         addedGutterColor: '#9397ad',
         removedGutterColor: '#9397ad',
         codeFoldContentColor: '#9397ad',
         diffViewerTitleBackground: 'transparent',
         diffViewerTitleColor: '#9397ad',
         diffViewerTitleBorderColor: 'transparent',
      },
      dark: {
         diffViewerBackground: 'transparent',
         diffViewerColor: '#e2e5f1',
         addedBackground: 'rgba(34,197,94,.12)',
         addedColor: '#e2e5f1',
         removedBackground: 'rgba(239, 68, 68, 0.12)',
         removedColor: '#e2e5f1',
         wordAddedBackground: 'rgba(34,197,94,.12)',
         wordRemovedBackground: 'rgba(239, 68, 68, 0.12)',
         addedGutterBackground: 'rgba(34,197,94,.12)',
         removedGutterBackground: 'rgba(239, 68, 68, 0.12)',
         gutterBackground: 'transparent',
         gutterBackgroundDark: 'transparent',
         highlightBackground: 'transparent',
         highlightGutterBackground: 'transparent',
         codeFoldGutterBackground: 'transparent',
         codeFoldBackground: 'transparent',
         emptyLineBackground: 'transparent',
         gutterColor: '#9397ad',
         addedGutterColor: '#9397ad',
         removedGutterColor: '#9397ad',
         codeFoldContentColor: '#9397ad',
         diffViewerTitleBackground: 'transparent',
         diffViewerTitleColor: '#9397ad',
         diffViewerTitleBorderColor: 'transparent',
      },
   },
   diffContainer: {}, // style object
   diffRemoved: {}, // style object
   diffAdded: {}, // style object
   marker: { paddingLeft: '1px', paddingRight: '0px' }, // style object
   emptyGutter: {}, // style object
   highlightedLine: {}, // style object
   lineNumber: { width: '24px', padding: 0 }, // style object
   highlightedGutter: {}, // style object
   contentText: { paddingLeft: '4px !important' }, // style object
   gutter: {
      minWidth: '32px',
      maxWidth: '34px',
      padding: 0,
      borderRight: '1px solid rgba(147, 151, 173, .5) !important',
   }, // style object
   line: {}, // style object
   wordDiff: {}, // style object
   wordAdded: {}, // style object
   wordRemoved: {}, // style object
   codeFoldGutter: {}, // style object
   codeFold: {}, // style object
   emptyLine: {}, // style object
   content: {}, // style object
   titleBlock: {
      border: 'none !important',
      borderBottom: 'none',
   }, // style object
   splitView: {}, // style object
};

const DiffViewer = (props: Props): JSX.Element => {
   const mode = useTheme();
   let darkTheme = false;
   if (mode === 'dark') {
      darkTheme = true;
   }

   return (
      <div className="diff-viewer">
         <ReactDiffViewer
            compareMethod={DiffMethod.WORDS}
            leftTitle={props.oldName}
            newValue={props.newCode}
            oldValue={props.oldCode}
            rightTitle={props.newName}
            showDiffOnly={false}
            splitView={props.splitView !== undefined ? props.splitView : true}
            styles={defaultStyles}
            useDarkTheme={darkTheme}
         />
      </div>
   );
};

export default DiffViewer;
