import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Stack } from 'react-bootstrap';

import { DownloadLinks } from '../components';
import { IconDesktop, IconWeb } from '../utilities';

const AppSelect: React.FC = () => {
   const location = useLocation();
   const [remember, setRemember] = useState(false);
   const [showDownload, setShowDownload] = useState(location.state?.client === 'desktop');
   const navigate = useNavigate();
   const [isSignupSuccess] = useState(location.state?.fromSignupSuccess);
   const [targetPath] = useState(location.state?.targetPath || '/');
   const [targetQueryString] = useState(location.state?.targetQueryString || '');

   const openDesktopApp = () => {
      if (isSignupSuccess) {
         window.location.href = `runql://`;
      } else {
         window.location.href = `runql://${targetPath.replace(/^\//, '')}${targetQueryString}`;
      }
   };

   useEffect(() => {
      if (location.state?.client === 'desktop') {
         openDesktopApp();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const handleSelection = (clientType: 'web' | 'desktop') => {
      if (remember) {
         localStorage.setItem('runql-preferred-client', clientType);
      } else if (clientType === 'web') {
         sessionStorage.setItem('runql-preferred-client', clientType);
      }

      if (clientType === 'desktop') {
         openDesktopApp();
         setTimeout(() => {
            setShowDownload(true);
         }, 1000);
         return;
      }

      navigate(`${targetPath}${targetQueryString}`, { replace: true });
      return;
   };

   if (showDownload) {
      return (
         <Stack className="align-items-center" style={{ marginTop: '50px' }}>
            <div className="mb-3">
               <h4 className="text-center mb-0 fw-500">
                  {isSignupSuccess ? 'Account Created Successfully' : 'Welcome Back'}
               </h4>
            </div>
            <div className="mb-3">
               If the desktop app did not open, please download and install it.
            </div>
            <div className="col-sm card p-4 mx-auto" style={{ maxWidth: '500px' }}>
               <DownloadLinks />
            </div>
         </Stack>
      );
   }

   return (
      <Stack className="align-items-center" style={{ marginTop: '50px' }}>
         <div className="mb-3">
            <h4 className="text-center mb-0 fw-500">
               {isSignupSuccess ? 'Account Created Successfully' : 'Welcome Back'}
            </h4>
         </div>
         <div className="mb-4">
            <h1 className="mb-0">Choose Your IDE</h1>
         </div>
         <div className="mb-4">
            <button className="btn btn-primary me-3" onClick={() => handleSelection('web')}>
               <Stack direction="horizontal" gap={2}>
                  <IconWeb size={30} />
                  Cloud IDE
               </Stack>
            </button>
            <button className="btn btn-primary" onClick={() => handleSelection('desktop')}>
               <Stack direction="horizontal" gap={2}>
                  <IconDesktop size={30} />
                  Desktop IDE
               </Stack>
            </button>
         </div>
         <Stack className="justify-content-center" direction="horizontal" gap={2}>
            <input
               checked={remember}
               id="remember"
               name="remember"
               onChange={(e) => setRemember(e.target.checked)}
               type="checkbox"
            />
            <label htmlFor="remember">Remember my selection</label>
         </Stack>
      </Stack>
   );
};

export default AppSelect;
